<template>
  <div class="box">

    <h4 v-if="record.id" class="title is-4">Edit Preadvice</h4>
    <h4 v-else class="title is-4">New Preadvice</h4>

    <form @submit.prevent="validateAndSave()">

      <div class="columns is-multiline">

        <div class="column is-half" v-if="!record.transfer">
          <depot-select v-model="record.depot" :disabled="!!user_depot" />
        </div>

        <div class="column is-half">
          <order-status-select v-model="record.status" :order-type="record.order_type" :disable="depot_only_user ? [ 'arrived', 'closed_out' ] : null" />
        </div>

        <template v-if="!record.transfer">
          <div class="column is-full">
            <div class="columns">
              <div class="column is-half">
                <b-field label="Start Date">
                  <date-time-picker v-model="record.expected_at" :time="false" />
                </b-field>
              </div>
              <div class="column is-half">
                <b-field label="End Date">
                  <date-time-picker v-model="record.expires_at" :time="false" />
                </b-field>
              </div>
            </div>
          </div>
        </template>

        <div class="column is-one-third">
          <b-field label="Preadvice Number">
            <b-input type="text" v-model="record.reference" :disabled="record.transfer" v-validate="'required'" data-vv-name="preadvice number"></b-input>
          </b-field>
        </div>

        <div class="column is-one-third">
          <b-field label="Supplier">
            <b-input type="text" v-model="record.data.supplier" :disabled="record.transfer"></b-input>
          </b-field>
        </div>

        <div class="column is-one-third">
          <b-field label="Unit Count">
            <b-input type="number" v-model="record.unit_count"></b-input>
          </b-field>
        </div>

        <div class="column is-full">
          <b-field label="Notes">
            <b-input type="textarea" v-model="record.notes"></b-input>
          </b-field>
        </div>

        <div class="column is-full">
          <div class="field is-grouped">
            <button class="button is-primary is-fullwidth">Save</button>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
  import moment from 'moment'
  import userHelper from 'shared/mixins/user-helper'
  import hasDateFields from 'shared/mixins/has-date-fields'
  import DepotSelect from '../../shared/components/depot-select'
  import OrderStatusSelect from '../../shared/components/order-status-select'
  export default {
    mixins: [
      userHelper,
      hasDateFields
    ],
    components: {
      DepotSelect,
      OrderStatusSelect
    },
    props: {
      record: {
        type: Object,
        default () {
          return {
            depot: null,
            order_type: 'incoming',
            status: 'awaiting_status',
            expected_at: moment(new Date()).endOf('day').toDate(),
            expires_at: moment(new Date()).endOf('day').add(1, 'year').toDate(),
            data: {}
          }
        }
      },
      onSave: {
        type: Function
      }
    },
    created () {
      if (this.user_depot) {
        this.record.depot = this.user_depot
      }
      this.initDateFields([ 'expected_at', 'expires_at' ])
    },
    methods: {
      validateAndSave () {
        this.$validator.validateAll().then(result => {
          if (result) {
            return this.save()
          }
          this.$store.commit('error.validation', this.errors)
        })
      },
      save () {
        this.$store.dispatch('saveRecord', [ 'order', this.record, { include: 'created_by_user,depot,order_units,order_units.unit' }]).then(record => {
          this.$buefy.toast.open({
            message: 'Order Saved',
            type: 'is-success'
          })
          this.$parent.close()
          if (this.onSave) this.onSave(record)
        })
      }
    }
  }
</script>
