<template>
  <b-field label="Fleet Status">
    <b-select v-model="localValue" data-vv-name="fleet status" name="fleet-status" v-validate="'required'" @input="update"  icon="flag-variant-outline" expanded>
      <option></option>
      <option value="unassigned">Unassigned</option>
      <option value="rental">Rental</option>
    </b-select>
  </b-field>
</template>

<script>
  export default {
    inject: [ '$validator' ],
    props: {
      value: {
        required: true
      }
    },
    data () {
      return {
        localValue: this.value
      }
    },
    methods: {
      update () {
        this.$emit('input', this.localValue)
      }
    }
  }
</script>
