<template>
  <div>

    <template v-if="records.length === 0">
      <article class="message is-warning">
        <div class="message-body">
          No movements.
        </div>
      </article>
    </template>

    <b-table v-else
      detailed
      :data="records">

      <template slot-scope="props">

        <b-table-column field="movement_type" label="Movement Type">
          {{ props.row.movement_type | humanize }}
        </b-table-column>

        <b-table-column field="product_code" label="Product Code">
          {{ props.row.product_code }}
        </b-table-column>

        <b-table-column field="product" label="Unit">
          <span v-if="props.row.unit" :title="props.row.unit.number">
            <router-link :to="{ name: 'unit', params: { id: props.row.unit.id }}">
              {{ props.row.unit.number }}
            </router-link>
          </span>
          <span v-else>
            Unassigned
          </span>
        </b-table-column>

        <b-table-column field="driver" label="Driver">
          <span v-if="props.row.driver">
            {{ props.row.driver.first_name }}
            {{ props.row.driver.last_name }}
          </span>
        </b-table-column>

        <b-table-column label="Scheduled">
          {{ props.row.scheduled_at | formattedDateTime }}
        </b-table-column>

        <!-- <b-table-column label="Door Dir">
          {{ props.row.door_direction }}
        </b-table-column>

        <b-table-column label="Big Truck">
          {{ props.row.data.big_truck_ok | yesOrNo }}
        </b-table-column>

        <b-table-column label="Site Ready">
          {{ props.row.data.site_ready | yesOrNo }}
        </b-table-column> -->

        <b-table-column field="destination" label="Destination">
          {{ locationString(props.row.destination) }}
        </b-table-column>

        <b-table-column field="status" label="Status">
          <status-select :record="props.row" record_type="movement" />
        </b-table-column>

        <b-table-column field="actions">

          <div class="field has-addons is-marginless is-pulled-right">
            <div class="control">
              <button @click="$emit('edit', props.row)" class="button">
                <span class="icon icon-medium"><i class="mdi mdi-file-document-edit-outline mdi-18px"></i></span>
                <span>Edit</span>
              </button>
            </div>
            <div class="control">
              <button @click="$emit('delete', props.row)" class="button">
                <span class="icon icon-medium"><i class="mdi mdi-delete-forever-outline mdi-18px"></i></span>
                <span>Remove</span>
              </button>
            </div>
          </div>

        </b-table-column>

      </template>

      <template slot="detail" slot-scope="props">
        <movement-detail :movement="props.row" />
      </template>

    </b-table>

  </div>
</template>

<script>
  import filters from 'shared/filters'
  import UserHelper from 'shared/mixins/user-helper'
  import { addressToString } from 'shared/services/address-helper'
  import StatusSelect from './status'
  import MovementDetail from './movement-detail'
  export default {
    filters,
    components: {
      StatusSelect,
      MovementDetail
    },
    mixins: [ UserHelper ],
    props: {
      records: {
        type: Array,
        required: true
      }
    },
    methods: {
      locationString (location) {
        if (!location) return

        switch (location.type) {
          case 'depots':
            return location.name
          case 'addresses':
            return addressToString(location)
        }
      }
    }
  }
</script>

<style>
  .order-detail .table-wrapper {
    overflow: visible;
  }
</style>
