<template>
  <status-select :options="options" :record="record" record_type="order" />
</template>

<script>
  import { rentalOrderStatuses } from 'shared/statuses'
  import StatusSelect from '../../shared/components/status-select'
  export default {
    props: [ 'record' ],
    data () {
      return { options: rentalOrderStatuses }
    },
    components: {
      StatusSelect
    }
  }
</script>
