<template>
  <section class="section products">

    <div class="level is-mobile">
      <div class="level-item level-left">
        <h1 class="title">Products</h1>
      </div>
      <div v-if="is_admin" class="level-item level-right">
        <button @click="newRecord" class="button is-outlined is-primary">
          <span class="icon icon-medium"><i class="mdi mdi-square-edit-outline mdi-18px"></i></span>
          <span>New Product</span>
        </button>
      </div>
    </div>

    <product-filters />
    <product-table ref="table" @edit="editRecord" @delete="deleteRecord" />

  </section>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import userHelper from 'shared/mixins/user-helper'
  import ProductFilters from './filters'
  import ProductTable from './table'
  import ProductForm from './form'
  export default {
    mixins: [ userHelper ],
    components: {
      ProductFilters,
      ProductTable
    },
    methods: {
      reload () {
        this.$refs.table.loadAsyncData()
      },
      newRecord () {
        this.$buefy.modal.open({
          parent: this,
          component: ProductForm,
          props: {
            onSave: record => {
              this.reload()
            }
          }
        })
      },
      editRecord (record) {
        this.$buefy.modal.open({
          parent: this,
          component: ProductForm,
          props: {
            record: { ...record },
            onSave: record => {
              this.reload()
            }
          }
        })
      },
      deleteRecord (record) {
        this.$buefy.dialog.confirm({
          title: 'Deleting Product',
          message: 'Are you sure you want to <b>delete</b> this product?',
          confirmText: 'Delete Product',
          type: 'is-danger',
          onConfirm: () => {
            this.$store.dispatch('deleteRecord', [ 'product', record ]).then(() => {
              this.reload()
            })
          }
        })
      }
    }
  }
</script>
