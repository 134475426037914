<template>
  <div class="google-map"></div>
</template>

<script>
  try {
    const directionsService = new google.maps.DirectionsService()
  } catch (e) {
    console.log('Could not load Google Map:', e.message)
  }
  export default  {
    props: {
      center: {
        type: Object,
        required: true
      },
      zoom: {
        type: Number,
        required: false,
        default: 7
      }
    },
    data () {
      return { map: null }
    },
    mounted () {
      if (typeof(google) !== 'undefined')
      this.map = new google.maps.Map(this.$el, {
        center: this.center,
        zoom: this.zoom
      })
    },
    methods: {
      addMarker (marker) {
        new google.maps.Marker({
          position: marker.position,
          map: this.map,
          label: marker.label
        });
      },
      addMarkers (markers, options) {
        markers.forEach(marker => this.addMarker(marker))
        if (options.fit) {
          let bounds = new google.maps.LatLngBounds()
          bounds.extend(this.center)
          markers.forEach(marker => bounds.extend(marker.position))
          this.map.fitBounds(bounds)
        }
      },

      showDirections (start, end) {
        this.directionsService = this.directionsService || new google.maps.DirectionsService()
        let directionsDisplay = new google.maps.DirectionsRenderer;
        directionsDisplay.setMap(this.map)
        return new Promise((resolve, reject) => {
          this.directionsService.route({
            origin: start,
            destination: end,
            travelMode: 'DRIVING'
          }, (response, status) => {
            console.log(response, status)
            if (status === 'OK') {
              directionsDisplay.setDirections(response)
              resolve(response)
            } else {
              this.$buefy.toast.open({
                message: `Directions request failed due to: ${status}`,
                type: 'is-warning'
              })
              reject(status)
            }
          })
        })
      }

    }
  }
</script>

<style>
  .google-map {
    box-sizing: border-box;
    width: 100%;
    height: 500px;
    padding: 1px;
    border: 1px solid #eee;
  }
</style>
