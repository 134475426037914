<template>
  <div class="box">
    <h4 class="title is-4">Gate Out ({{ order.reference }})</h4>
    <form @submit.prevent="validateAndSave()">

      <b-field label="Gate Out Date">
        <date-time-picker v-model="record.released_at" />
      </b-field>

      <b-field v-if="record.unit_id && unit" label="Unit">
        <b-input type="text" :value="unit.number" disabled></b-input>
      </b-field>

      <div v-else class="box">

        <b-field>
          <b-radio-button v-model="inventory_type" native-value="inventoried">
            <span>Inventoried Unit</span>
          </b-radio-button>
          <b-radio-button v-model="inventory_type" native-value="other">
            <span>Other Unit</span>
          </b-radio-button>
        </b-field>

        <template v-if="inventory_type === 'inventoried'" >
          <b-field :label="unitSearchLabel">
            <unit-autocomplete
              v-model="unit"
              :depot="order.depot"
              :product_code="order.product_code"
              :fleet_status="order.order_type === 'sales' ? 'unassigned' : null"
              status="available" />
          </b-field>
        </template>

        <template v-if="inventory_type === 'other'">

          <depot-select v-model="unit.depot" />

          <b-field v-if="order.product_code" label="Product Code">
            <b-input type="text" :value="unit.product_code" :placeholder="order.product_code" disabled></b-input>
          </b-field>

          <product-select v-else v-model="unit.product" />

          <b-field label="Serial Number">
            <b-input v-model.trim="unit.number" v-validate="{ required: true, containerNumber: true }" data-vv-name="serial number" type="text"></b-input>
          </b-field>

          <unit-color-select v-model="unit.color" />

        </template>

      </div>

      <div class="field">
        <div class="columns is-multiline">

          <div class="column is-one-third">
            <b-field label="Trucking Company">
              <b-input type="text" v-model="trace_data.trucking_company"></b-input>
            </b-field>
          </div>

          <div class="column is-one-third">
            <b-field label="License Plate">
              <b-input type="text" v-model="trace_data.license_plate"></b-input>
            </b-field>
          </div>

          <div class="column is-one-third">
            <b-field label="Driver Name">
              <b-input type="text" v-model="trace_data.driver_name"></b-input>
            </b-field>
          </div>

          <div class="column is-one-third">
            <b-field label="License #">
              <b-input type="text" v-model="trace_data.driver_license"></b-input>
            </b-field>
          </div>

          <div class="column is-one-third">
            <b-field label="Phone">
              <b-input type="text" v-model="trace_data.driver_phone"></b-input>
            </b-field>
          </div>

        </div>
      </div>

      <b-field label="Notes">
        <b-input v-if="unit" type="textarea" v-model.trim="unit.notes"></b-input>
        <b-input v-else type="textarea" disabled></b-input>
      </b-field>

      <div class="column is-full">
        <div class="columns">
          <div class="column">
            <button @click="print = false" class="button is-primary is-fullwidth">Save</button>
          </div>
          <div class="column">
            <button @click="print = true" class="button is-success is-fullwidth">Save &amp; Print</button>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
  import userHelper from 'shared/mixins/user-helper'
  import UnitAutocomplete from '../../shared/components/unit-autocomplete'
  import DepotSelect from '../../shared/components/depot-select'
  import ProductSelect from '../../shared/components/product-select'
  import UnitStatusSelect from '../../shared/components/unit-status-select'
  import UnitColorSelect from '../../shared/components/unit-color-select'
  export default {
    mixins: [ userHelper ],
    components: {
      UnitAutocomplete,
      DepotSelect,
      ProductSelect,
      UnitStatusSelect,
      UnitColorSelect
    },
    props: {
      record: {
        type: Object,
        required: true
      },
      order: {
        type: Object,
        required: true
      },
      onSave: {
        type: Function
      }
    },
    data () {
      return {
        print: false,
        inventory_type: 'inventoried',
        unit: this.record.unit || {},
        trace_data: {},
        config: {
          altInput: true,
          altFormat: 'F j, Y at h:i K',
          dateFormat: 'Z',
          enableTime: true
        }
      }
    },
    computed: {
      unitSearchLabel () {
        let label = 'Unit Search'
        if (this.order.product_code) {
          label += ` (${this.order.product_code})`
        }
        return label
      }
    },
    created () {
      this.record.released_at = new Date()
      if (!this.unit.id && this.order.product) {
        this.unit.product = this.order.product
      }
      this.record.trace_data = this.trace_data
    },
    methods: {
      validateAndSave () {
        this.$validator.validateAll().then(result => {
          if (result) {
            return this.save()
          }
          this.$store.commit('error.validation', this.errors)
        })
      },
      save () {
        let unitToSave = this.unit.id ? {
          id: this.unit.id,
          notes: this.unit.notes
        } : this.unit
        this.record.order = this.order
        this.record.released_by_user = this.current_user
        this.$store.dispatch('saveRecord', [ 'unit', unitToSave ]).then(unit => {
          this.record.unit = unit
          this.$store.dispatch('saveRecord', [ 'order_unit', this.record, { include: 'order,unit' }]).then(record => {
            this.$buefy.toast.open({
              message: 'Unit Gated Out',
              type: 'is-success'
            })
            this.$parent.close()
            if (this.print) {
              let url = `${document.location.origin}/print/order-units/${record.id}`
              window.open(url, 'Print View')
            }
            if (this.onSave) this.onSave(record)
          })
        })
      }
    }
  }
</script>
