<template>
  <div class="box">
    <h5 class="title is-5">Summary</h5>
    <div class="b-table">
      <div class="table-wrapper">
        <table class="table is-striped is-narrow has-mobile-cards">
          <thead>
            <tr>
              <th>Incoming</th>
              <th>Releases</th>
              <th>Units</th>
              <th>Depots</th>
              <th>Products</th>
              <th>Users</th>
            </tr>

          </thead>
          <tbody>
            <tr>
              <td data-label="Incoming">
                <router-link to="/incoming">
                  {{ data.incoming_order_count }} orders for {{ data.incoming_unit_count }} units
                </router-link>
              </td>
              <td data-label="Outgoing">
                <router-link to="/releases">
                  {{ data.outgoing_order_count }} orders for {{ data.outgoing_unit_count }} units
                </router-link>
              </td>
              <td data-label="Inventory"><router-link to="/inventory">{{ data.unit_count }}</router-link></td>
              <td data-label="Depots"><router-link to="/depots">{{ data.depot_count }}</router-link></td>
              <td data-label="Products"><router-link to="/products">{{ data.product_count }}</router-link></td>
              <td data-label="Users"><router-link to="/users">{{ data.user_count }}</router-link></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style>
  .is-summary-table .level-right .field,
  .is-summary-table .level-right .button {
    margin: 0 0 0 5px;
  }
  .is-summary-table .level-right label.label {
    display: none;
  }
  @media (max-width: 580px) {
    .is-summary-table .level,
    .is-summary-table .level-item {
      display: block;
      margin: 10px 0;
    }
    .is-summary-table .level.is-mobile .level-item.level-left {
      margin-bottom: 10px;
    }
  }
</style>
