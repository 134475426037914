<template>
  <div class="box">

    <h4 v-if="record.id" class="title is-4">Edit Release</h4>
    <h4 v-else class="title is-4">New Release</h4>

    <form @submit.prevent="validateAndSave()">

      <div class="columns is-multiline">

        <template v-if="!(depot_only_user || record.transfer)">
          <div class="column">
            <depot-select v-model="record.depot" :disabled="!!user_depot" />
          </div>
          <div class="column">
            <order-product-select v-model="record" />
          </div>
        </template>

        <div class="column">
          <order-status-select v-model="record.status" order-type="outgoing" :disable="depot_only_user ? [ 'picked_up', 'closed_out' ] : null" />
        </div>


        <template v-if="!(depot_only_user || record.transfer)">
          <div class="column is-full">
            <div class="columns">
              <div class="column is-half">
                <b-field label="Creation Date">
                  <date-time-picker v-model="record.expected_at" :time="false" />
                </b-field>
              </div>
              <div class="column is-half">
                <b-field label="Expected Date">
                  <date-time-picker v-model="record.expires_at" :time="false" />
                </b-field>
              </div>
            </div>
          </div>
        </template>


        <div class="column is-full">
          <div class="columns">

            <div class="column">
              <b-field label="Release Number">
                <b-input type="text" v-model="record.reference" :disabled="depot_only_user || record.transfer" v-validate="'required'" data-vv-name="release number"></b-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Order Number">
                <b-input type="text" v-model="record.data.purchase_order" :disabled="depot_only_user || record.transfer"></b-input>
              </b-field>
            </div>

            <div class="column">
              <unit-color-select v-model="record.color" />
            </div>

            <div class="column">
              <b-field label="Unit Count">
                <b-input type="number" v-model="record.unit_count" :disabled="depot_only_user" v-validate="'required|min_value:1'" data-vv-name="unit count"></b-input>
              </b-field>
            </div>
          </div>
        </div>

        <div class="column is-full">
          <b-field label="Notes">
            <b-input type="textarea" v-model="record.notes"></b-input>
          </b-field>
        </div>

        <div class="column is-full">
          <div class="field is-grouped">
            <button class="button is-primary is-fullwidth">Save</button>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
  import moment from 'moment'
  import userHelper from 'shared/mixins/user-helper'
  import hasDateFields from 'shared/mixins/has-date-fields'
  import DepotSelect from '../../shared/components/depot-select'
  import UnitColorSelect from '../../shared/components/unit-color-select'
  import OrderProductSelect from '../../shared/components/order-product-select'
  import OrderStatusSelect from '../../shared/components/order-status-select'
  export default {
    mixins: [
      userHelper,
      hasDateFields
    ],
    components: {
      DepotSelect,
      UnitColorSelect,
      OrderProductSelect,
      OrderStatusSelect
    },
    props: {
      record: {
        type: Object,
        default () {
          return {
            order_type: 'outgoing',
            status: 'awaiting_status',
            expected_at: moment(new Date()).endOf('day').toDate(),
            expires_at: moment(new Date()).endOf('day').add(1, 'year').toDate(),
            data: {}
          }
        }
      },
      onSave: {
        type: Function
      }
    },
    created () {
      if (this.user_depot) {
        this.record.depot = this.user_depot
      }
      this.initDateFields([ 'expected_at', 'expires_at' ])
    },
    methods: {
      validateAndSave () {
        this.$validator.validateAll().then(result => {
          if (result) {
            return this.save()
          }
          this.$store.commit('error.validation', this.errors)
        })
      },
      save () {
        this.$store.dispatch('saveRecord', [ 'order', this.record, { include: 'created_by_user,depot,order_units,order_units.unit' }]).then(record => {
          this.$buefy.toast.open({
            message: 'Order Saved',
            type: 'is-success'
          })
          this.$parent.close()
          if (this.onSave) this.onSave(record)
        })
      }
    }
  }
</script>
