<template>
  <div>

    <template v-if="records.length === 0">
      <article class="message is-warning">
        <div class="message-body">
          No Incoming Units
        </div>
      </article>
    </template>

    <b-table v-else :data="records">

      <template slot-scope="props">

        <b-table-column field="reference_id" label="Serial Number" width="220">
          <h6 class="title is-6">
            <router-link :to="{ name: 'unit', params: { id: props.row.unit.id }}">{{ props.row.unit.number }}</router-link>
          </h6>
          <!-- <h6 v-if="props.row.unit.notes" class="subtitle is-size-7 has-text-grey">{{ props.row.unit.notes }}</h6>
          <h6 v-if="props.row.arrived_at" class="subtitle is-size-7 has-text-grey">Arrived {{ props.row.arrived_at | formattedDateTime }}</h6> -->

        </b-table-column>

        <b-table-column field="product" label="Product">
          <span v-if="props.row.unit.product" :title="props.row.unit.product.name">
            {{ props.row.unit.product.code }}
          </span>
        </b-table-column>

        <b-table-column field="color" label="Color">
          <div v-if="props.row.unit.color" class="color-swatch-wrapper">
            <span class="color-swatch" :class="props.row.unit.color | colorClassName"></span>
            <span class="color" data-attribute="color">{{ props.row.unit.color }}</span>
          </div>
        </b-table-column>

        <b-table-column field="status" label="Status">
          <span :class="props.row.unit.status" class="tag">
            {{ props.row.unit.status | titleize }}
          </span>
        </b-table-column>

        <b-table-column field="actions">

          <router-link v-if="transfer && props.row.arrived_at" :to="{ name: 'transfer', params: { id: transfer.id }}" data-attribute="transfer.reference">
            <span class="icon icon-medium"><i class="mdi mdi-clipboard-flow mdi-18px"></i></span>
            <span>View Transfer</span>
          </router-link>

          <div v-else>

            <div v-if="props.row.arrived_at" class="field has-addons is-marginless is-pulled-right">
              <div class="control">
                <a class="button" :href="`/print/order-units/${props.row.id}`" target="_blank">
                  <span class="icon icon-medium"><i class="mdi mdi-file-pdf mdi-18px"></i></span>
                  <span>Print <span class="is-hidden-mobile">Receipt</span></span>
                </a>
              </div>
              <div class="control">
                <router-link class="button" :to="{ name: 'unit', params: { id: props.row.unit.id }}">
                  <span class="icon icon-medium"><i class="mdi mdi-file-eye-outline mdi-18px"></i></span>
                  <span>View</span>
                </router-link>
              </div>
            </div>

            <div v-else class="field has-addons is-marginless is-pulled-right">
              <div class="control">
                <a @click="$emit('gateIn', props.row)" aria-role="button" class="button">
                  <span class="icon icon-medium"><i class="mdi mdi-clipboard-arrow-down-outline mdi-18px"></i></span>
                  <span>Gate In</span>
                </a>
              </div>
              <div class="control">
                <b-dropdown hoverable position="is-bottom-left" aria-role="menu">
                  <a class="button" slot="trigger" role="button">
                    <span class="icon"><i class="mdi mdi-chevron-down mdi-18px"></i></span>
                    <span>Edit</span>
                  </a>

                  <b-dropdown-item v-if="!props.row.arrived_at" aria-role="listitem" @click="$emit('edit', props.row)">
                    <span class="icon icon-medium"><i class="mdi mdi-file-replace-outline mdi-18px"></i></span>
                    <span>Edit Assignment</span>
                  </b-dropdown-item>

                  <b-dropdown-item v-if="is_admin" @click="$emit('delete', props.row)">
                    <span class="icon icon-medium"><i class="mdi mdi-delete-forever-outline mdi-18px"></i></span>
                    <span>Remove</span>
                  </b-dropdown-item>

                </b-dropdown>
              </div>

            </div>
          </div>

        </b-table-column>

      </template>
    </b-table>

  </div>
</template>

<script>
  import filters from 'shared/filters'
  import UserHelper from 'shared/mixins/user-helper'
  export default {
    filters,
    mixins: [ UserHelper ],
    props: {
      records: {
        type: Array,
        required: true
      },
      transfer: {
        type: Object,
        required: false
      },
      isRental: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
