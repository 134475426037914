<template>
  <section class="section users">

    <div class="level is-mobile">
      <div class="level-item level-left">
        <h1 class='title'>Users</h1>
      </div>
      <div v-if="is_admin" class="level-item level-right">
        <button class="button is-outlined is-primary" @click="newRecord">
          <span class="icon icon-medium"><i class="mdi mdi-account-plus mdi-18px"></i></span>
          <span>New</span>
        </button>
      </div>
    </div>

    <user-table :records="users" :loading="loading" v-on:edit="editRecord" v-on:delete="deleteRecord" />

  </section>
</template>

<script>
  import Index from '../../mixins/collection-index'
  import UserTable from './table'
  import UserForm from './form'
  export default {
    mixins: [ Index ],
    components: {
      UserTable
    },
    data () {
      return {
        users: []
      }
    },
    created () {
      this.fetchRecords()
    },
    methods: {
      fetchRecords () {
        this.loading = true
        this.$store.dispatch('fetchRecords', [ 'user', { include: 'depot' }]).then(records => {
          this.users = records
          this.loading = false
        })
      },
      newRecord () {
        this.$buefy.modal.open({
          parent: this,
          component: UserForm,
          props: {
            onSave: record => {
              this.users.push(record)
            }
          }
        })
      },
      editRecord (record) {
        this.$buefy.modal.open({
          parent: this,
          component: UserForm,
          props: {
            record: { ...record },
            onSave: record => {
              this.fetchRecords()
            }
          }
        })
      },
      deleteRecord (record) {
        this.$buefy.dialog.confirm({
          title: 'Deleting User',
          message: 'Are you sure you want to <b>delete</b> this user?',
          confirmText: 'Delete User',
          type: 'is-danger',
          onConfirm: () => {
            this.$store.dispatch('deleteRecord', [ 'user', record ]).then(() => {
              this.fetchRecords()
              this.$buefy.toast.open({
                message: 'User Deleted',
                type: 'is-success'
              })
            })
          }
        })
      }
    }
  }
</script>
