<script>
  import TraceMixin from 'shared/mixins/trace'
  import Base from './base'
  export default {
    mixins: [ TraceMixin ],
    extends: Base,
    data () {
      return {
        action: 'updated'
      }
    }
  }
</script>
