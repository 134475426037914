<template>
  <div class="box">
    <h4 v-if="record.id" class="title is-4">Edit User</h4>
    <h4 v-else class="title is-4">New User</h4>
    <form @submit.prevent="validateAndSave" novalidate>

      <div class="columns is-multiline">

        <template v-if="is_admin">
          <div class="column is-half">
            <b-field label="Role" label-for="role">
              <b-select v-model="record.role" id="role" expanded>
                <option value="admin">Admin</option>
                <option value="office">Office</option>
                <option value="depot">Depot</option>
                <option value="driver">Driver</option>
              </b-select>
            </b-field>
          </div>

          <div class="column is-half">
            <depot-select v-model="record.depot" :disabled="!is_admin" />
          </div>
        </template>

        <div class="column is-half">
          <b-field label="First Name" label-for="field-first-name">
            <b-input type="text" v-model="record.first_name" v-validate="'required'" data-vv-name="first name" id="field-first-name"></b-input>
          </b-field>
        </div>

        <div class="column is-half">
          <b-field label="Last Name" label-for="field-last-name">
            <b-input type="text" v-model="record.last_name" v-validate="'required'" data-vv-name="last name" id="field-last-name"></b-input>
          </b-field>
        </div>

        <div class="column is-half">
          <b-field label="Email" label-for="field-email">
            <b-input type="email" v-model="record.email" v-validate="'required|email'" data-vv-name="email" id="field-email"></b-input>
          </b-field>
        </div>

        <div class="column is-half">
          <b-field label="Password" label-for="field-password">
            <b-input type="password" v-model="record.password" v-validate="{ required: !record.id }" data-vv-name="password" id="field-password"></b-input>
          </b-field>
        </div>

        <div class="column is-full">
          <b-field label="Phone" label-for="field-phone">
            <b-input type="tel" v-model="record.phone" id="field-phone"></b-input>
          </b-field>
        </div>

      </div>

      <div class="field is-grouped">
        <button class="button is-primary is-fullwidth">Save</button>
      </div>

    </form>
  </div>
</template>

<script>
  import UserHelper from 'shared/mixins/user-helper'
  import DepotSelect from '../../shared/components/depot-select'
  export default {
    mixins: [ UserHelper ],
    components: {
      DepotSelect
    },
    props: {
      record: {
        type: Object,
        default () {
          return {}
        }
      },
      onSave: {
        type: Function
      }
    },
    methods: {
      validateAndSave () {
        this.$validator.validateAll().then(result => {
          if (result) {
            return this.save()
          }
          this.$store.commit('error.validation', this.errors)
        })
      },
      save () {
        this.$store.dispatch('saveRecord', [ 'user', this.record, { include: 'depot' }]).then(record => {
          this.$buefy.toast.open({
            message: 'User Saved',
            type: 'is-success'
          })
          this.$parent.close()
          if (this.onSave) this.onSave(record)
        })
      }
    }
  }
</script>
