<template>
  <div>
    <loader v-if="!data" />

    <section class="section" v-else>

      <overview :data="data" />

      <div class="box">
        <h5 class="title is-5">Today's incoming units ({{ data.incoming_order_units.length }})</h5>
        <order-unit-report-table direction="incoming" :records="data.incoming_order_units"/>
      </div>

      <div class="box">
        <h5 class="title is-5">Today's incoming rental units ({{ data.incoming_rental_order_units.length }})</h5>
        <order-unit-report-table direction="incoming" :records="data.incoming_rental_order_units"/>
      </div>

      <div class="box">
        <h5 class="title is-5">Today's released units ({{ data.outgoing_order_units.length }})</h5>
        <order-unit-report-table direction="outgoing" :records="data.outgoing_order_units"/>
      </div>

      <unit-summary />

      <release-summary />

    </section>

  </div>
</template>

<script>
  import api from 'shared/json-api'
  import filters from 'shared/filters'
  import Loader from 'shared/components/loader'
  import Overview from './overview'
  import UnitSummary from './unit-summary'
  import ReleaseSummary from './release-summary'
  import OrderUnitReportTable from '../../shared/components/order-unit-report-table'
  export default {
    filters,
    components: {
      Loader,
      Overview,
      UnitSummary,
      ReleaseSummary,
      OrderUnitReportTable
    },
    data () {
      return {
        data: null
      }
    },
    created () {
      let id = new Date().getTime() / 1000
      api.find('dashboard', id, {
        include: [
          'incoming_order_units.order',
          'incoming_order_units.accepted_by_user',
          'incoming_order_units.unit.product',
          'incoming_order_units.unit.depot',
          'outgoing_order_units.order',
          'outgoing_order_units.released_by_user',
          'outgoing_order_units.unit.product',
          'outgoing_order_units.unit.depot',
          'incoming_rental_order_units.order',
          'incoming_rental_order_units.released_by_user',
          'incoming_rental_order_units.unit.product',
          'incoming_rental_order_units.unit.depot'
        ].join(',')
      }).then(response => {
        this.data = response.data
      })
    }
  }
</script>
