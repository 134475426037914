<template>
  <div class="columns is-tablet">
    <div class="column">
      <b-field label="Search">
        <b-input v-model="filter.query" @input="debounceQuery" placeholder="Reference" icon="magnify"/>
      </b-field>
    </div>
    <div class="column">
      <b-field label="Status">
        <b-select v-model="filter.status" @input="applyRouteFilter" placeholder="All" icon="flag-variant-outline" expanded>
          <option value="default">Default</option>
          <option value="all">All</option>
          <option
            v-for="(label, option) in statusOptions"
            :value="option">
            {{ label }}
          </option>
        </b-select>
      </b-field>
    </div>
    <div class="column is-1">
      <div class="field">
        <label class="label">&nbsp;</label>
        <button @click="resetFilter" class="button is-fullwidth">Reset</button>
      </div>
    </div>
  </div>
</template>

<script>
  import routeFilter from '../../mixins/route-filter'
  import { transferStatuses } from 'shared/statuses'
  const DEFAULTS = { status: 'default' }
  const ALLOWED = [ 'depot_id', 'query', 'status' ]
  export default {
    mixins: [ routeFilter ],
    data () {
      return {
        statusOptions: transferStatuses
      }
    },
    created () {
      this.injectRouteFilter(DEFAULTS, ALLOWED)
    },
    methods: {
      debounceQuery () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.applyRouteFilter()
        }, 250)
      }
    }
  }
</script>
