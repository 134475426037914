<template>
  <section class="section deliveries">

    <div class="level is-mobile">
      <div class="level-item level-left">
        <h1 class="title is-size-4-mobile">Dispatches</h1>
      </div>
      <div v-if="!user_is_role('depot')" class="level-item level-right">
        <button @click="newRecord()" class="button is-primary is-outlined">
          <span class="icon icon-medium"><i class="mdi mdi-clipboard-arrow-up-outline mdi-18px"></i></span>
          <span>New <span class="is-hidden-mobile">Dispatch</span></span>
        </button>
      </div>
    </div>

    <filters />

    <delivery-table />

  </section>
</template>

<script>
  import userHelper from 'shared/mixins/user-helper'
  import { deliveryStatuses } from 'shared/statuses'
  import DeliveryTable from './table'
  import DeliveryForm from './form'
  import Filters from './filters'
  export default {
    mixins: [ userHelper ],
    components: {
      DeliveryTable,
      Filters
    },
    data () {
      return {
        deliveryStatuses
      }
    },
    methods: {
      newRecord () {
        this.$buefy.modal.open({
          parent: this,
          component: DeliveryForm,
          props: {
            onSave: record => {
              this.$router.push({ name: 'dispatch', params: { id: record.id }})
            }
          }
        })
      }
    }
  }
</script>
