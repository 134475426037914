<template>
  <div class="search-bar">
    <b-input type="search" v-model.trim="query" placeholder="Search" @input="search" icon="magnify" size="is-small" expanded/>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        query: ''
      }
    },
    methods: {
      search () {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(this.routeSearch, 333)
      },
      routeSearch () {
        if (this.query.length) {
          this.$router.replace({ name: 'search', params: { query: this.query }})
        } else {
          this.$router.replace({ name: 'dashboard' })
        }
      }
    }
  }
</script>

<style lang="scss">
  // For Consistent inputs on ipad
  @media (min-width: 1025px) {
    .search-bar input {
      max-width: 140px;
    }
  }
</style>
