<template>
  <b-datetimepicker
    v-if="time"
    v-model="localValue"
    :timepicker="{ hourFormat: '12' }"
    :mobile-native="false"
    placeholder="Select a date"
    icon="calendar-today"
    ref="picker"
  >
    <template slot="left">
      <div class="buttons">
        <button
          class="button is-primary"
          @click.prevent="localValue = new Date()"
        >
          <b-icon icon="clock"></b-icon>
        </button>
        <button
          class="button is-danger"
          @click.prevent="localValue = null"
        >
          <b-icon icon="close"></b-icon>
        </button>
      </div>
    </template>
    <template slot="right">
      <button
        class="button is-success"
        @click.prevent="hideDateTimePicker()"
      >
        <b-icon icon="check"></b-icon>
      </button>
    </template>
  </b-datetimepicker>

  <b-datepicker
    v-else
    v-model="localValue"
    :mobile-native="false"
    placeholder="Select a date"
    icon="calendar-today"
    ref="picker"
  >
    <button
      class="button is-primary"
      @click.prevent="localValue = new Date()"
    >
      <b-icon icon="calendar-today"></b-icon>
      <span>Today</span>
    </button>

    <button
      class="button is-danger"
      @click.prevent="localValue = null"
    >
      <b-icon icon="close"></b-icon>
      <span>Clear</span>
    </button>
  </b-datepicker>

</template>

<script>
export default {
  props: {
    value: [ String, Date ],
    time: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      localValue: this.value
    }
  },
  created () {
    try {
      this.localValue = this.localValue ? new Date(this.localValue) : null
    } catch (e) {
      console.warn('DateTimePicker could not parse date/time.', e.message)
    }
  },
  watch: {
    localValue () {
      this.update()
    }
  },
  methods: {
    hideDateTimePicker () {
      this.$refs.picker.toggle()
    },
    update () {
      this.$emit('input', this.localValue)
    }
  }
}
</script>
