<template>
  <section class="section depots">

    <div class="level">
      <div class="level-item level-left">
        <h1 class="title">Depots</h1>
      </div>
      <div v-if="is_admin" class="level-item level-right">
        <button v-if="is_admin" @click="newRecord" class="button is-outlined is-primary">
          <span class="icon icon-medium"><i class="mdi mdi-square-edit-outline mdi-18px"></i></span>
          <span>New Depot</span>
        </button>
      </div>

    </div>

    <depot-table :records="depots" :loading="loading" v-on:edit="editRecord" />

  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import Index from '../../mixins/collection-index'
  import DepotTable from '../../components/depots/table'
  import DepotForm from '../../components/depots/form'
  export default {
    mixins: [ Index ],
    components: {
      DepotTable
    },
    data () {
      return {
        depots: []
      }
    },
    created () {
      this.fetchRecords()
    },
    methods: {
      fetchRecords () {
        this.loading = true
        this.$store.dispatch('fetchRecords', [ 'depot', {
          include: 'address'
        }]).then(records => {
          this.depots = records
          this.loading = false
        })
      },
      newRecord () {
        this.$buefy.modal.open({
          parent: this,
          component: DepotForm,
          props: {
            record: { internal: true },
            onSave: record => {
              this.depots.push(record)
            }
          }
        })
      }
    }
  }
</script>
