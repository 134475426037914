<template>
  <trace-helper :trace="trace">
    <span class="tag"><b>imported</b></span> unit <span class="tag"><b>{{ record.number }}</b></span>
  </trace-helper>
</template>

<script>
  import TraceMixin from 'shared/mixins/trace'
  export default {
    mixins: [ TraceMixin ]
  }
</script>
