/* eslint no-console: 0 */

import Vue from 'shared/init'

import Buefy from 'buefy'
import '../../interchange/styles.scss'

Vue.use(Buefy)

import VeeValidate from 'vee-validate'
import 'shared/validators/container-number'
Vue.use(VeeValidate)

import DateTimePicker from 'shared/plugins/date-time-picker'
Vue.use(DateTimePicker)

import App from '../../interchange/app.vue'

import store from 'shared/store'
import router from '../../interchange/router'

import EXIF from 'exif-js'
window.EXIF = EXIF

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    store,
    router,
    render: h => h(App)
  }).$mount('#app')
})
