<template>
  <section class="section incoming-orders">

    <div class="level is-mobile">
      <div class="level-item level-left">
        <h1 class="title is-size-4-mobile">Incoming</h1>
      </div>
      <div class="level-item level-right">
        <div class="field is-grouped">
          <div class="control">
            <button @click="gateInUnit()" class="button is-primary is-outlined">
              <!-- mdi-truck-delivery -->
              <span class="icon icon-medium"><i class="mdi mdi-clipboard-arrow-down-outline mdi-18px"></i></span>
              <span>Gate In</span>
            </button>
          </div>
          <div class="control">
            <button @click="newRecord()" class="button is-primary is-outlined">
              <span class="icon icon-medium"><i class="mdi mdi-clipboard-text-outline mdi-18px"></i></span>
              <span>New <span class="is-hidden-mobile">Preadvice</span></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <order-filters :statusOptions="statuses" />
    <order-table />

  </section>
</template>

<script>
  import userHelper from 'shared/mixins/user-helper'
  import OrderFilters from '../../shared/components/order-filter'
  import OrderTable from './table'
  import OrderForm from './form'
  import GateInUnitForm from './gate-in-unit-form'
  import { incomingOrderStatuses } from 'shared/statuses'
  export default {
    mixins: [ userHelper ],
    components: {
      OrderFilters,
      OrderTable
    },
    data () {
      return {
        statuses: incomingOrderStatuses,
      }
    },
    methods: {
      newRecord () {
        this.$buefy.modal.open({
          parent: this,
          component: OrderForm,
          props: {
            onSave: record => {
              this.$router.push({ name: 'incoming-order', params: { id: record.id }})
            }
          }
        })
      },
      gateInUnit () {
        this.$buefy.modal.open({
          parent: this,
          component: GateInUnitForm,
          props: {
            record: { data: {} }
          }
        })
      }
    }
  }
</script>
