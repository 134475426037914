<template>
  <section class="section search-results">

    <h1 class="title is-size-4-mobile">
      Results for: "{{ result.query }}"
    </h1>

    <div class="content incoming-orders">
      <h4 class="title is-4 is-size-5-mobile">Incoming</h4>
      <simple-order-table :records="incoming_orders" />
    </div>

    <div class="content outgoing-orders">
      <h4 class="title is-4 is-size-5-mobile">Releases</h4>
      <simple-order-table :records="outgoing_orders" />
    </div>

    <div class="content rental-orders">
      <h4 class="title is-4 is-size-5-mobile">Rentals</h4>
      <simple-order-table :records="rental_orders" />
    </div>

    <div class="content units">
      <h4 class="title is-4 is-size-5-mobile">Units</h4>
      <simple-unit-table :records="result.units || []" />
    </div>

  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import api from 'shared/json-api'
  import SimpleOrderTable from './simple-order-table'
  import SimpleUnitTable from './simple-unit-table'
  export default {
    components: {
      SimpleOrderTable,
      SimpleUnitTable
    },
    data () {
      return {
        result: {}
      }
    },
    computed: {
      ...mapState([ 'depot' ]),
      incoming_orders () {
        return (this.result.orders || []).filter(i => i.order_type === 'incoming')
      },
      outgoing_orders () {
        return (this.result.orders || []).filter(i => i.order_type === 'outgoing')
      },
      rental_orders () {
        return (this.result.orders || []).filter(i => i.order_type === 'rental')
      }
    },
    mounted () {
      this.search()
    },
    watch: {
      '$route.params.query' (to) {
        this.search()
      }
    },
    methods: {
      search () {
        api.find('search', this.$route.params.query, {
          depot_id: this.depot ? this.depot.id : null,
          include: 'orders.depot,orders.product,units.depot,units.product'
        }).then(response => {
          this.result = response.data
        }).catch(e => {
          console.warn(e)
          this.result = {}
        })
      }
    }
  }
</script>
