import api from 'shared/json-api'

export async function addressFromLocation(location) {
  if (location.type === 'depots') {
    const response = await api.findAll('address', {
      filter: {
        addressable_id: location.id,
        addressable_type: 'Depot'
      }
    })
    return this.addressToString(response.data[0])
  } else if (location.type === 'addresses') {
    return this.addressToString(location)
  }
}

export function addressToString (address) {
  let parts = [ address.line1 ]
  if (address.line2) parts = parts.concat(address.line2)
  parts = parts.concat(address.locality, address.region, address.postal_code)
  return parts.join(', ')
}

export default {
  addressFromLocation,
  addressToString
}
