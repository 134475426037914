<template>
  <div class="trace">
    <component v-for="trace in traces" :key="trace.id" :is="toComponent(trace.action)" :trace="trace" :record="record" />
  </div>
</template>

<script>
  import { dasherize } from 'inflected'

  import UnitImported from 'shared/components/traces/unit/imported'
  import UnitAssigned from 'shared/components/traces/unit/assigned'
  import UnitArrived from 'shared/components/traces/unit/arrived'
  import UnitReleased from 'shared/components/traces/unit/released'

  import ModificationCreated from 'shared/components/traces/modification/created'
  import ModificationUpdated from 'shared/components/traces/modification/updated'
  import ModificationDestroyed from 'shared/components/traces/modification/destroyed'

  export default {
    components: {
      UnitImported,
      UnitAssigned,
      UnitArrived,
      UnitReleased,
      ModificationCreated,
      ModificationUpdated,
      ModificationDestroyed
    },
    props: {
      record: {
        type: Object,
        required: true
      },
      traces: {
        type: Array,
        required: true
      }
    },
    methods: {
      toComponent (action) {
        return dasherize(action)
      }
    }
  }
</script>
<style lang="scss">
.trace {
  padding: 8px 4px;
  border-bottom: 1px solid #ddd;
  &:last-child {
    border: 0;
  }
}

</style>
