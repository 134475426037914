export default {
  regexps: {
    format: /\+?N?[\.\ \-]?\(?NNN\)?[\.\ \-]?NNN[\.\ \-]?NNNN/
  },
  format (value, formatString) {

    function normalize(phoneNumber) {
      return phoneNumber.toString().replace(
        /^[\+\d{1,3}\-\s]*\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "$1$2$3"
      )
    }

    function format(phoneNumber, formatString) {
      phoneNumber = normalize(phoneNumber)
      for (var i = 0, l = phoneNumber.length; i < l; i++) {
        formatString = formatString.replace("N", phoneNumber[i])
      }
      return formatString
    }

    return format(value, formatString)
  }
}
