<template>
  <div class="columns is-tablet">
    <div class="column">
      <b-field label="Search by name">
        <b-input v-model="filter.query" @input="debounceQuery" placeholder="Order Name, Code..." icon="magnify"/>
      </b-field>
    </div>
    <div class="column">
      <b-field v-if="statusOptions" label="Status">
        <b-select v-model="filter.status" @input="applyRouteFilter" placeholder="All" icon="flag-variant-outline" expanded>
          <option value="">All</option>
          <option
            v-for="(label, option) in statusOptions"
            :value="option">
            {{ label }}
          </option>
          <option value="deleted">Deleted</option>
        </b-select>
      </b-field>
      <order-status-select v-else v-model="filter.status" @input="applyRouteFilter" ref="statusSelect" />
    </div>
    <div v-if="showTimingFilter" class="column">
      <b-field label="Timing" expanded>
        <b-select v-model="filter.timing" @input="applyRouteFilter" placeholder="Default" icon="update" expanded>
          <option value="">Default</option>
          <option value="all">All</option>
          <option value="current">Current</option>
          <option value="expired">Expired</option>
        </b-select>
      </b-field>
    </div>
    <div v-if="showTransferFilter" class="column">
      <b-field label="Show Transfers" expanded>
        <b-select v-model="filter.transfers" @input="applyRouteFilter" placeholder="Yes" icon="update" expanded>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </b-select>
      </b-field>
    </div>
    <!-- <div class="column">
      <b-field label="Sort Order" expanded>
        <b-select v-model="filter.sortKey" placeholder="Default" expanded>
          <option value="expected_at">Created</option>
          <option value="expires_at">Expected Pickup</option>
          <option value="reference">Order Number</option>
          <option value="status">Status</option>
        </b-select>
      </b-field>
    </div> -->

    <slot
      v-bind:filter="filter"
      v-bind:action="applyRouteFilter">
    </slot>

    <div class="column is-1">
      <div class="field">
        <label class="label">&nbsp;</label>
        <button @click="resetFilter" class="button is-fullwidth">Reset</button>
      </div>
    </div>
  </div>
</template>

<script>
  import routeFilter from '../../mixins/route-filter'
  import OrderStatusSelect from './order-status-select'

  const DEFAULTS = {}
  const ALLOWED = [
    'depot_id',
    'order_type',
    'query',
    'status',
    'timing',
    'transfers',
    'sales_rep',
    'service_type',
    'deposit_needed',
    'inventory_needed'
  ]

  export default {
    mixins: [ routeFilter ],
    components: {
      OrderStatusSelect
    },
    props: {
      statusOptions: {
        type: Object,
        required: false
      },
      showTimingFilter: {
        type: Boolean,
        default: true
      },
      showTransferFilter: {
        type: Boolean,
        default: true
      }
    },
    created () {
      this.injectRouteFilter(DEFAULTS, ALLOWED)
      // This is a weird one.. the two custom components aren't resetting their localValue
      // when their v-model changes..
      this.$on('filter.reset', () => {
        if (this.$refs.statusSelect) {
          this.$refs.statusSelect.localValue = null
        }
      })
    },
    methods: {
      debounceQuery () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.applyRouteFilter()
        }, 250)
      }
    }
  }
</script>
