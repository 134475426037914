<template>
  <div>
    <b-table
      :data="records"
      :loading="loading"
      :current-page="meta.page"
      :total="meta.record_count"
      :per-page="50"
      :striped="true"
      :narrowed="true"
      detailed
      paginated
      backend-pagination
      backend-sorting
      default-sort="reference"
      default-sort-direction="asc"
      @sort="onSort"
      @page-change="onPageChange">

      <template slot-scope="props" slot="header">
        <b-tooltip v-if="props.column.meta" :active="!!props.column.meta" :label="props.column.meta" dashed>
          {{ props.column.label }}
        </b-tooltip>
        <template v-else>
          {{ props.column.label }}
        </template>
      </template>

      <template slot-scope="props">

        <b-table-column field="reference" label="Order #" sortable>
          <h6 class="title is-6 is-whitespacenowrap">
            <router-link :to="{ name: 'support-ticket', params: { id: props.row.id }}">{{ props.row.reference }}</router-link>
          </h6>
          <!-- <h6 v-if="props.row.notes" class="subtitle is-size-7 has-text-grey">{{ props.row.notes }}</h6> -->
        </b-table-column>

        <!-- <b-table-column label="Release Number">
          <p class="is-whitespacenowrap" data-attribute="data.order-number">
            {{ props.row.data ? props.row.data.release_number : '' }}
          </p>
        </b-table-column> -->

        <b-table-column field="status" label="Status" sortable>
          <order-status :record="props.row" />
        </b-table-column>

        <b-table-column field="priority" label="Priority" sortable>
          <span :class="props.row.priority" class="tag priority" data-attribute="priority">
            {{ props.row.priority | titleize }}
          </span>
        </b-table-column>

        <b-table-column field="data.service-type" label="Service Type">
          {{ props.row.data.service_type }}
        </b-table-column>

        <b-table-column label="Client Name">
          <div v-if="props.row.data.client_name"  class="truncate">
            <span data-attribute="data.client-name">
              {{ props.row.data.client_name }}
            </span>
          </div>
        </b-table-column>

        <b-table-column field="created_at" label="Created" sortable>
          <p class="is-whitespacenowrap" data-attribute="created-at">
            {{ props.row.created_at | formattedDate }}
          </p>
        </b-table-column>

        <b-table-column field="approved_at" label="Approved" sortable>
          <p class="is-whitespacenowrap" data-attribute="approved-at">
            {{ props.row.approved_at | formattedDate }}
          </p>
        </b-table-column>

        <b-table-column field="expected_at" label="Due" sortable>
          <p class="is-whitespacenowrap" data-attribute="expected-at">
            {{ props.row.expected_at | formattedDate }}
          </p>
        </b-table-column>

        <b-table-column field="expires_at" label="Est Completion" sortable>
          <p class="is-whitespacenowrap" data-attribute="expires-at">
            {{ props.row.expires_at | formattedDate }}
          </p>
        </b-table-column>

        <b-table-column field="delivered_at" label="Delivery" sortable>
          <p class="is-whitespacenowrap" data-attribute="delivered-at">
            {{ props.row.delivered_at | formattedDate }}
          </p>
        </b-table-column>

        <b-table-column label="Site?">
          <span v-if="props.row.data.site_ready !== undefined">
            {{ props.row.data.site_ready | yesOrNo }}
          </span>
        </b-table-column>

        <!-- <b-table-column label="Deposit Needed">
          <span data-attribute="data.deposit-needed">{{ props.row.data.deposit_needed | yesOrNo }}</span>
        </b-table-column> -->

        <!-- <b-table-column field="unit_count" label="Unit Count" sortable>
          <span class="count" data-attribute="total-count">{{ props.row.unit_count }}</span>
        </b-table-column> -->

      </template>

      <template slot="detail" slot-scope="props">

        <div class="table-wrapper">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th><span class="is-whitespacenowrap">Support Assignment</span></th>
                <th><span class="is-whitespacenowrap">Delivery City</span></th>
                <th><span class="is-whitespacenowrap">Sales Rep</span></th>
                <th><span class="is-whitespacenowrap">Client Phone</span></th>
                <th><span class="is-whitespacenowrap">Client Email</span></th>
                <th><span class="is-whitespacenowrap">Notes</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-attribute="data.support_assignment">
                  <span class="is-whitespacenowrap">{{ props.row.data.support_assignment }}</span>
                </td>
                <td data-attribute="data.delivery-city">
                  <span class="is-whitespacenowrap">{{ props.row.data.delivery_city }}</span>
                </td>
                <td data-attribute="data.sales-rep">
                  {{ props.row.data.sales_rep }}
                </td>
                <td data-attribute="data.client-phone">
                  <span class="is-whitespacenowrap">{{ props.row.data.client_phone | phoneNumber }}</span>
                </td>
                <td data-attribute="data.client-email">
                  <span class="is-whitespacenowrap">
                    <a v-if="props.row.data.client_email" :href="`mailto:${props.row.data.client_email}`">{{ props.row.data.client_email }}</a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="props.row.notes" class="content">
          <div class="pre-wrapper">
            <pre data-attribute="notes">{{ props.row.notes }}</pre>
          </div>
        </div>

      </template>

    </b-table>
  </div>
</template>

<script>
  import asyncTable from '../../mixins/async-table'
  import OrderStatus from './status'
  import api from 'shared/json-api'
  export default {
    mixins: [ asyncTable ],
    components: {
      OrderStatus
    },
    data () {
      return {
        defaultFilter: {
          order_type: 'support_ticket',
          timing: 'all'
        },
        sort: {
          field: 'reference',
          order: 'asc'
        },
      }
    },
    methods: {
      deliveredCount (record) {
        return record.order_units.filter(i => i.arrived_at).length
      },
      remainingCount (record) {
        return parseInt(record.unit_count) - this.deliveredCount(record)
      },
      loadAsyncData () {
        this.loading = true
        api.findAll('order', {
          sort: this.sortParam,
          page: {
            number: this.meta.page
          },
          filter: this.applyRouteFilter()
        }).then(response => {
          this.handleResponse(response)
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
</script>

<style scoped>
@media (min-width: 567px) {
  .truncate {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
