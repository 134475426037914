<template>
  <div v-if="record" class="order-detail">

    <section class="section">

      <div v-if="record.deleted_at" class="notification is-danger">
        This order has been deleted.
      </div>

      <!-- ACTIONS -->
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <router-link to="/modifications" class="navbar-item">
              <span class="mdi mdi-chevron-left"></span>&nbsp; All Modifications
            </router-link>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="field has-addons">
              <div class="control">
                <button class="button" @click="addModification()">
                  <span class="icon icon-medium"><i class="mdi mdi-note-plus-outline mdi-18px"></i></span>
                  <span>Add Spec</span>
                </button>
              </div>
              <div class="control">
                <button class="button" @click="editRecord()">
                  <span class="icon icon-medium"><i class="mdi mdi-file-document-edit-outline mdi-18px"></i></span>
                  <span>Edit</span>
                </button>
              </div>
              <div v-if="is_admin && !record.transfer" class="control">
                <button v-if="record.deleted_at" class="button" @click="recoverRecord">
                  <span class="icon icon-medium"><i class="mdi mdi-delete-sweep-outline mdi-18px"></i></span>
                  <span>Recover</span>
                </button>
                <button v-else class="button" @click="deleteRecord">
                  <span class="icon icon-medium"><i class="mdi mdi-delete-forever-outline mdi-18px"></i></span>
                  <span>Delete</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box is-relative">
        <order-status :record="record" />
        <div class="columns">

          <div class="column is-narrow">
            <p class="heading">Order Number</p>
            <h4 class="title is-4">{{ record.reference }}</h4>
          </div>
          <template v-if="record.data">
            <div v-if="record.data.release_number" class="column is-narrow">
              <p class="heading">Release Number</p>
              <h5 class="title is-4" data-attribute="data.order-number">{{ record.data.release_number }}</h5>
            </div>
            <div class="column is-narrow">
              <p class="heading">Sales Rep</p>
              <h5 class="title is-4" data-attribute="data.sales-rep">
                <span v-if="record.data.sales_rep">{{ record.data.sales_rep }}</span>
                <span v-else>Not Assigned</span>
              </h5>
            </div>
          </template>
        </div>
        <div class="b-table">
          <div class="table-wrapper">
            <table class="table is-striped is-narrow has-mobile-cards is-inbox">
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                   Priority:
                </th>
                <td data-label="Priority:">
                  <span :class="record.priority" class="tag priority" data-attribute="priority">
                    {{ record.priority | titleize }}
                  </span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                   Depot:
                </th>
                <td data-label="Depot:">
                  <span v-if="record.depot" class="tag" data-attribute="depot.name">
                    {{ record.depot.name }}
                  </span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Unit Count:
                </th>
                <td data-label="Unit Count:">
                  <span data-attribute="unit-count">{{ record.unit_count }}</span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Created Date:
                </th>
                <td data-label="Created:">
                  <span v-if="record.created_at" data-attribute="created-at">{{ record.created_at | formattedDate }}</span>
                  <span v-else>N/A</span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Customer Due Date:
                </th>
                <td data-label="Due:">
                  <span v-if="record.expected_at" data-attribute="expected-at">{{ record.expected_at | formattedDate }}</span>
                  <span v-else>N/A</span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Estimated Completion Date:
                </th>
                <td data-label="Completion:">
                  <span v-if="record.expires_at" data-attribute="expires-at">{{ record.expires_at | formattedDate }}</span>
                  <span v-else>N/A</span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Approval Date:
                </th>
                <td data-label="Approval Date:">
                  <span v-if="record.approved_at" data-attribute="expires-at">{{ record.approved_at | formattedDate }}</span>
                  <span v-else>N/A</span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Delivery Date:
                </th>
                <td data-label="Delivery Date:">
                  <span v-if="record.delivered_at" data-attribute="expires-at">{{ record.delivered_at | formattedDate }}</span>
                  <span v-else>N/A</span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Client Name:
                </th>
                <td data-label="Name:">
                  <span data-attribute="data.client-name">{{ record.data.client_name }}</span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Client Phone:
                </th>
                <td data-label="Phone:">
                  <span data-attribute="data.client-phone">{{ record.data.client_phone | phoneNumber }}</span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Client Email:
                </th>
                <td data-label="Email:">
                  <span data-attribute="data.client-email">
                    <a v-if="record.data.client_email" :href="`mailto:${record.data.client_email}`">{{ record.data.client_email }}</a>
                  </span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Delivery City:
                </th>
                <td data-label="Delivery City:">
                  <span data-attribute="data.delivery-city">{{ record.data.delivery_city }}</span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Deposit Needed:
                </th>
                <td data-label="Deposit Needed:">
                  <span data-attribute="data.deposit-needed">{{ record.data.deposit_needed | yesOrNo }}</span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Inventory Needed:
                </th>
                <td data-label="Inventory Needed:">
                  <span data-attribute="data.inventory-needed">{{ record.data.inventory_needed | yesOrNo }}</span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Site Ready:
                </th>
                <td data-label="Site Ready:">
                  <span v-show="record.data.site_ready !== undefined" data-attribute="data.site-ready">{{ record.data.site_ready | yesOrNo }}</span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 150px;">
                  Created By:
                </th>
                <td data-label="Expired:">
                  <span v-if="record.created_by_user" data-attribute="created-by-user">
                    {{ record.created_by_user.first_name }}
                    {{ record.created_by_user.last_name }}
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="box">
        <h6 class="title is-6">Notes</h6>
        <div class="pre-wrapper">
          <pre data-attribute="notes">{{ record.notes }}</pre>
        </div>
      </div>

      <div class="box">
        <modification-table
          :records="record.modifications"
          @edit="editModification"
          @delete="deleteModification" />
      </div>

      <div class="box">
        <h5 class="title is-5">Order History</h5>
        <template v-if="record.traces.length > 0">
          <traces :traces="record.traces" :record="record" />
        </template>
        <template v-else>
          <article class="message is-warning">
            <div class="message-body">
              No History
            </div>
          </article>
        </template>
      </div>
    </section>

  </div>

  <loader v-else />

</template>

<script>
  import { mapState } from 'vuex'
  import filters from 'shared/filters'

  import UserHelper from 'shared/mixins/user-helper'
  import AsyncRecord from 'shared/mixins/async-record'
  import OrderUnitActions from '../../mixins/order-unit-actions'

  import Loader from 'shared/components/loader'
  import OrderStatus from './status'
  import OrderForm from './form'
  import ModificationForm from './modification-form'
  import ModificationTable from './modification-table'
  import Traces from 'shared/components/traces'

  export default {
    filters,
    mixins: [
      UserHelper,
      AsyncRecord,
      OrderUnitActions
    ],
    components: {
      Loader,
      OrderStatus,
      ModificationTable,
      Traces
    },
    data () {
      return {
        asyncRecordOptions: {
          type: 'order',
          options: {
            order_type: 'modification',
            include: 'created_by_user,depot,modifications.unit,modifications.product,traces.user,traces.order,traces.unit'
          }
        }
      }
    },
    methods: {
      addModification () {
        this.$buefy.modal.open({
          parent: this,
          component: ModificationForm,
          props: {
            record: {
              order: this.record,
              product: null
            },
            onSave: record => {
              this.fetchRecord()
            }
          }
        })
      },
      editModification (record) {
        this.$buefy.modal.open({
          parent: this,
          component: ModificationForm,
          props: {
            record: {
              ...record,
              order: this.record
            },
            onSave: record => {
              this.fetchRecord()
            }
          }
        })
      },
      deleteModification (record) {
        this.$buefy.dialog.confirm({
          title: 'Remove Modification Spec',
          message: 'Are you sure you want to <b>remove</b> this modification spec?',
          confirmText: 'Remove Modification',
          type: 'is-danger',
          onConfirm: () => {
            this.$store.dispatch('deleteRecord', [ 'modification', record ]).then(() => {
              this.fetchRecord()
            })
          }
        })
      },
      editRecord () {
        this.$buefy.modal.open({
          parent: this,
          component: OrderForm,
          props: {
            record: { ...this.record },
            onSave: record => {
              this.fetchRecord()
            }
          }
        })
      },
      deleteRecord () {
        this.$buefy.dialog.confirm({
          title: 'Deleting modification Order',
          message: 'Are you sure you want to <b>delete</b> this modification order?',
          confirmText: 'Delete modification Order',
          type: 'is-danger',
          onConfirm: () => {
            this.$store.dispatch('deleteRecord', [ 'order', this.record ]).then(() => {
              this.$router.push('/modifications')
            })
          }
        })
      }
    }
  }
</script>
