<template>
  <div>

    <template v-if="!loading && records.length === 0">
      <article class="message is-warning">
        <div class="message-body">
          No Depots
        </div>
      </article>
    </template>

    <b-table v-else
      :data="records"
      :loading="loading">

      <template slot-scope="props">

        <b-table-column field="name" label="Name" sortable>

          <router-link :to="{ name: 'depot', params: { id: props.row.id }}">
            <b data-attribute="name">{{ props.row.name }}</b>
          </router-link>

          <br />

          <template v-if="props.row.address">
            <formatted-address :record="props.row.address" />
          </template>

        </b-table-column>

      </template>
    </b-table>

  </div>
</template>

<script>
  import FormattedAddress from 'shared/components/formatted-address'
  import CollectionTable from 'shared/mixins/collection-table'
  export default {
    mixins: [ CollectionTable ],
    components: {
      FormattedAddress
    }
  }
</script>
