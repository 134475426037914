<template>
  <status-select :options="options" :record="record" :record_type="record_type" />
</template>

<script>
  import { deliveryStatuses } from 'shared/statuses'
  import StatusSelect from '../../shared/components/status-select'
  export default {
    props: {
      record: Object,
      record_type: String
    },
    data () {
      return { options: deliveryStatuses }
    },
    components: {
      StatusSelect
    }
  }
</script>
