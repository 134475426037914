import http from 'shared/http'

export default class S3 {

  static presign({ filename, content_type }) {
    return new Promise((resolve, reject) => {
      http.post('/s3/presign', {
        filename,
        content_type
      }).then(response => {
        resolve(response.data.url)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  }

}
