<template>
  <b-field label="Product Select">
    <b-select v-if="value.order_type !== 'rental'" v-model="value.product" data-label="product-select" icon="texture" expanded>
      <option :value="null"></option>
      <option v-if="products.length === 0" disabled>loading...</option>
      <option v-else v-for="product in products" :value="product">
        {{ product.code }}
      </option>
    </b-select>

    <b-select v-else v-model="value.product_code" data-label="product-select" icon="texture" expanded>
      <option :value="null"></option>
      <option v-if="products.length === 0" disabled>loading...</option>
      <option v-else v-for="code in rentalProductCodes" :value="code">
        {{ code }}
      </option>
    </b-select>

  </b-field>
</template>

<script>
  export default {
    props: {
      value: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        products: []
      }
    },
    computed: {
      rentalProductCodes () {
        let codes = []
        this.products.map(i => {
          if (typeof(i.rental_code) === 'string' && i.rental_code.length && !codes.includes(i.rental_code)) {
            codes.push(i.rental_code)
          }
        })
        return codes
      }
    },
    created () {
      this.$store.dispatch('fetchRecords', [ 'product', {
        sort: 'code',
        filter: {
          product_class: 'CONTAINERS'
        },
        page: {
          number: 1,
          size: 999
        }
      }]).then(products => {
        this.products = products
      })
    }
  }
</script>
