import store from 'shared/store'
import JsonApi from 'devour-client'

const csrfMeta = document.querySelector('meta[name="csrf-token"]')

const jsonApi = new JsonApi({
  apiUrl: '/api'
})

const removeLinksMiddleware = {
  name: 'remove-links-middleware',
  req: (payload) => {
    try {
      if (payload.req.method !== 'GET') {
        delete payload.req.data.data.links
      }
    } catch (e) {
      console.warn('unable to delete links from payload')
    }
    return payload
  }
}

const csrfMiddleware = {
  name: 'csrf-middleware',
  req: (payload) => {
    if (payload.req.method !== 'GET') {
      payload.req.data = payload.req.data || {}
      payload.req.data.authenticity_token = csrfMeta ? csrfMeta.getAttribute('content') : null
    }
    return payload
  }
}

const authenticationMiddleware = {
  name: 'authentication',
  req: (payload) => {
    payload.req.headers['Authorization'] = `Bearer ${store.getters.authToken || 'unauthorized'}`
    return payload
  }
}

const customErrorMiddleware = {
  name: 'custom-errors',
  error (error) {
    const req = error.request
    console.log(req)
    if (req && req.status === 422 && req.responseText.includes('number - has already been taken')) {
      console.log('ignore request error as we`ll handle downstream')
    } else {
      return error
    }
  }
}

const storeErrorMiddleware = {
  name: 'store-errors',
  error (error) {
    store.commit('error.request', error)
    return error
  }
}

jsonApi.insertMiddlewareBefore('axios-request', removeLinksMiddleware)
jsonApi.insertMiddlewareBefore('axios-request', csrfMiddleware)
jsonApi.insertMiddlewareBefore('axios-request', authenticationMiddleware)
jsonApi.insertMiddlewareBefore('errors', customErrorMiddleware)
jsonApi.insertMiddlewareAfter('errors', storeErrorMiddleware)

jsonApi.cleanFilters = (obj) => {
  obj = { ...obj }
  Object.keys(obj).forEach((key) => (obj[key] == null || obj[key] == '') && delete obj[key])
  return obj
}

jsonApi.save = (record) => {
  if (record && record.id) {
    return jsonApi.update(record)
  } else {
    return jsonApi.create(record)
  }
}

jsonApi.define('session', {
  email: '',
  password: '',
  data: {},
  created_at: '',
  user: {
    jsonApi: 'hasOne',
    type: 'user'
  }
}, {
  collectionPath: 'session'
})


jsonApi.define('user', {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  role: '',
  password: '',
  auth_token: '',
  time_zone: '',
  created_at: '',
  updated_at: '',
  depot: {
    jsonApi: 'hasOne',
    type: 'depots'
  },
  movements: {
    jsonApi: 'hasMany',
    type: 'movements'
  },
  traces: {
    jsonApi: 'hasMany',
    type: 'traces'
  }
}, {
  readOnly: [ 'auth_token', 'created_at', 'updated_at' ]
})

jsonApi.define('depot', {
  name: '',
  location: '',
  contact: '',
  phone: '',
  internal: '',
  active: '',
  notes: '',
  created_at: '',
  updated_at: '',
  address: {
    jsonApi: 'hasOne',
    type: 'addresses'
  }
}, {
  readonly: [ 'created_at', 'updated_at' ]
})

jsonApi.define('address', {
  addressable_id: '',
  addressable_type: '',
  line1: '',
  line2: '',
  locality: '',
  region: '',
  postal_code: '',
  country_code: '',
  lat: '',
  lng: '',
  created_at: '',
  updated_at: '',
  addressable: {
    jsonApi: 'hasOne'
  }
}, {
  readonly: [ 'created_at', 'updated_at' ]
})

jsonApi.define('unit', {
  depot_id: null,
  product_id: null,
  number: '',
  color: '',
  cost: '',
  status: '',
  fleet_status: '',
  color: '',
  year_of_manufacture: null,
  open_side: null,
  notes: '',
  photos: '',
  has_lockbox: false,
  data: '',
  arrived_at: '',
  released_at: '',
  confirmed_at: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
  acquired_at: '',
  depot: {
    jsonApi: 'hasOne',
    type: 'depots'
  },
  product: {
    jsonApi: 'hasOne',
    type: 'products'
  },
  order_units: {
    jsonApi: 'hasMany',
    type: 'order_units'
  },
  traces: {
    jsonApi: 'hasMany',
    type: 'traces'
  }
}, {
  readonly: [ 'created_at', 'updated_at' ]
})

jsonApi.define('product', {
  lightspeed_id: null,
  code: '',
  rental_code: '',
  name: '',
  family: '',
  size: '',
  condition: '',
  product_class: '',
  classification: '',
  created_at: '',
  updated_at: ''
}, {
  readonly: [ 'created_at', 'updated_at' ]
})

jsonApi.define('order', {
  order_type: '',
  created_by_user_id: null,
  depot_id: null,
  product_id: null,
  classification: null,
  product_code: null,
  status: '',
  priority: '',
  reference: '',
  color: '',
  unit_count: '',
  data: {},
  notes: '',
  expected_at: '',
  expires_at: '',
  approved_at: '',
  delivered_at: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
  created_by_user: {
    jsonApi: 'hasOne',
    type: 'users'
  },
  transfer: {
    jsonApi: 'hasOne',
    type: 'transfers'
  },
  depot: {
    jsonApi: 'hasOne',
    type: 'depots'
  },
  product: {
    jsonApi: 'hasOne',
    type: 'products'
  },
  order_units: {
    jsonApi: 'hasMany',
    type: 'order_units'
  },
  modifications: {
    jsonApi: 'hasMany',
    type: 'modifications'
  },
  traces: {
    jsonApi: 'hasMany',
    type: 'traces'
  }
}, {
  readonly: [ 'created_at', 'updated_at', 'created_by_user' ]
})

jsonApi.define('order_unit', {
  unit_id: '',
  accepted_by_user_id: '',
  released_by_user_id: '',
  trace_data: '',
  arrived_at: '',
  released_at: '',
  created_at: '',
  updated_at: '',
  order: {
    jsonApi: 'hasOne',
    type: 'orders'
  },
  unit: {
    jsonApi: 'hasOne',
    type: 'units'
  },
  accepted_by_user: {
    jsonApi: 'hasOne',
    type: 'users'
  },
  released_by_user: {
    jsonApi: 'hasOne',
    type: 'users'
  }
}, {
  collectionPath: 'order-units',
  readonly: [ 'created_at', 'updated_at' ]
})

jsonApi.define('modification', {
  status: '',
  uploads: '',
  notes: '',
  trace_data: '',
  created_at: '',
  updated_at: '',
  order: {
    jsonApi: 'hasOne',
    type: 'orders'
  },
  product: {
    jsonApi: 'hasOne',
    type: 'products'
  },
  unit: {
    jsonApi: 'hasOne',
    type: 'units'
  }
}, {
  readonly: [ 'created_at', 'updated_at' ]
})

jsonApi.define('customer', {
  company_name: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  notes: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
  deliveries: {
    jsonApi: 'hasMany',
    type: 'deliveries'
  },
  orders: {
    jsonApi: 'hasMany',
    type: 'orders'
  }
}, {
  readonly: [ 'created_at', 'updated_at', 'deleted_at' ]
})

jsonApi.define('delivery', {
  reference: '',
  status: '',
  data: '',
  notes: '',
  trace_data: '',
  created_at: '',
  updated_at: '',
  expected_at: '',
  completed_at: '',
  deleted_at: '',
  customer: {
    jsonApi: 'hasOne',
    type: 'customers'
  },
  order: {
    jsonApi: 'hasOne',
    type: 'orders'
  },
  movements: {
    jsonApi: 'hasMany',
    type: 'movements'
  }
}, {
  readonly: [ 'created_at', 'updated_at', 'deleted_at' ]
})

jsonApi.define('movement', {
  product_code: '',
  movement_type: '',
  status: '',
  door_direction: '',
  photos: '',
  data: '',
  notes: '',
  created_at: '',
  updated_at: '',
  scheduled_at: '',
  started_at: '',
  completed_at: '',
  deleted_at: '',
  delivery: {
    jsonApi: 'hasOne',
    type: 'deliveries'
  },
  driver: {
    jsonApi: 'hasOne',
    type: 'users'
  },
  unit: {
    jsonApi: 'hasOne',
    type: 'units'
  },
  source: {
    jsonApi: 'hasOne'
  },
  destination: {
    jsonApi: 'hasOne'
  }
}, {
  readonly: [ 'created_at', 'updated_at', 'deleted_at' ]
})

jsonApi.define('transfer', {
  created_by_user_id: null,
  reference: '',
  status: '',
  unit_count: null,
  notes: '',
  created_at: '',
  updated_at: '',
  created_by_user: {
    jsonApi: 'hasOne',
    type: 'users'
  },
  product: {
    jsonApi: 'hasOne',
    type: 'products'
  },
  source_depot: {
    jsonApi: 'hasOne',
    type: 'depots'
  },
  destination_depot: {
    jsonApi: 'hasOne',
    type: 'depots'
  },
  outgoing_order: {
    jsonApi: 'hasOne',
    type: 'orders'
  },
  incoming_order: {
    jsonApi: 'hasOne',
    type: 'orders'
  },
  outgoing_order_units: {
    jsonApi: 'hasMany',
    type: 'order_units'
  },
  incoming_order_units: {
    jsonApi: 'hasMany',
    type: 'order_units'
  }
}, {
  readOnly: [ 'reference', 'created_at', 'updated_at' ]
})

jsonApi.define('dashboard', {
  report_date: '',
  incoming_order_count: 0,
  incoming_unit_count: 0,
  outgoing_order_count: 0,
  outgoing_unit_count: 0,
  unit_count: 0,
  depot_count: 0,
  product_count: 0,
  user_count: 0,
  incoming_order_units: {
    jsonApi: 'hasMany',
    type: 'order_units'
  },
  incoming_rental_order_units: {
    jsonApi: 'hasMany',
    type: 'order_units'
  },
  outgoing_order_units: {
    jsonApi: 'hasMany',
    type: 'order_units'
  }
}, {
  readonly: true,
  collectionPath: 'dashboard'
})

jsonApi.define('unit_summary', {
  units_by_product_code: ''
}, {
  readonly: true,
  collectionPath: 'units/summary'
})

jsonApi.define('unit_summary', {
  units_by_product_code: ''
}, {
  readonly: true,
  collectionPath: 'units/summary'
})

jsonApi.define('release_summary', {
  units_by_product_code: ''
}, {
  readonly: true,
  collectionPath: 'releases/summary'
})

jsonApi.define('search', {
  query: '',
  orders: {
    jsonApi: 'hasMany',
    type: 'orders'
  },
  units: {
    jsonApi: 'hasMany',
    type: 'units'
  }
}, {
  readonly: true,
  collectionPath: 'search'
})

jsonApi.define('trace', {
  action: '',
  data: '',
  timestamp: '',
  user: {
    jsonApi: 'hasOne',
    type: 'users'
  },
  order: {
    jsonApi: 'hasOne',
    type: 'orders'
  },
  unit: {
    jsonApi: 'hasOne',
    type: 'units'
  }
}, {
  readonly: [ 'created_at', 'updated_at' ]
})

export default jsonApi
