<template>
  <section class="section">
    <div class="columns">
      <div class="column is-half">
        <h6 class="title is-6">Source</h6>
        <!-- {{ source.id }} - {{ source.type }} -->
        {{ start }}
      </div>
      <div class="column is-half">
        <h6 class="title is-6">Destination</h6>
        <!-- {{ destination.id }} - {{ destination.type }} -->
        {{ end }}
      </div>
    </div>
    <div class="block">
      <google-map ref="map" :center="center" />
    </div>
  </section>
</template>

<script>
  import addressHelper from 'shared/services/address-helper'
  import GoogleMap from 'shared/components/google-map'
  export default {
    components: {
      GoogleMap
    },
    props: {
      movement: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        start: null,
        end: null,
        center: {
          lat: 36.7783,
          lng: -119.4179
        }
      }
    },
    computed: {
      source () {
        return this.movement.source
      },
      destination () {
        return this.movement.destination
      },
    },
    async mounted () {
      this.start = await addressHelper.addressFromLocation(this.source)
      this.end = await addressHelper.addressFromLocation(this.destination)
      if (this.start && this.end) {
        this.$refs.map.showDirections(this.start, this.end)
      }
    }
  }
</script>
