<template>
  <div class="box">
    <form @submit.prevent="validateAndSave">
      <h1 class="title is-size-4-mobile">New Transfer</h1>
      <product-select v-model="record.product" />
      <div class="columns is-multiline">
        <div class="column is-half">
          <depot-select v-model="record.source_depot" :disable="record.destination_depot" v-validate="'required'" data-vv-name="source depot" :label="'Source Depot'" />
        </div>
        <div class="column is-half">
          <depot-select v-model="record.destination_depot" :disable="record.source_depot" v-validate="'required'" data-vv-name="destination depot" :label="'Destination Depot'" />
        </div>
        <div class="column is-half">
          <b-field label="Status">
            <b-select v-model="record.status" icon="flag-variant-outline" expanded>
              <option></option>
              <option value="not_ready">Not Ready</option>
              <option value="ready">Ready</option>
              <option value="partial_ready">Partial Ready</option>
              <option value="closed_out">Closed Out</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Unit Count">
            <b-input type="number" v-model="record.unit_count" v-validate="'required|min_value:1'" data-vv-name="unit count"></b-input>
          </b-field>
        </div>
        <div class="column is-full">
          <b-field label="Notes">
            <b-input type="textarea" v-model="record.notes"></b-input>
          </b-field>
        </div>
        <div class="column  is-full">
          <div class="field is-grouped">
            <button class="button is-primary is-fullwidth">Save</button>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import UserHelper from 'shared/mixins/user-helper'
  import ProductSelect from '../../shared/components/product-select'
  import DepotSelect from '../../shared/components/depot-select'
  export default {
    mixins: [ UserHelper ],
    components: {
      ProductSelect,
      DepotSelect
    },
    props: {
      record: {
        type: Object,
        default () {
          return {}
        }
      },
      onSave: {
        type: Function
      }
    },
    computed: mapState({
      depots: state => state.data.depot || []
    }),
    methods: {
      // assumeDestination () {
      //   if (this.record.source_depot) {
      //     let options = this.depots.filter(i => i.id !== this.record.source_depot.id)
      //     if (options.length === 1) {
      //       this.record.destination_depot = options[0]
      //     }
      //   }
      // },
      validateAndSave () {
        this.$validator.validateAll().then(result => {
          if (result) {
            return this.save()
          }
          this.$store.commit('error.validation', this.errors)
        })
      },
      save () {
        delete this.record.outgoing_order
        delete this.record.incoming_order
        delete this.record.outgoing_order_units
        delete this.record.incoming_order_units
        this.$store.dispatch('saveRecord', [
          'transfer',
          this.record
        ]).then(record => {
          this.$buefy.toast.open({
            message: 'Transfer Saved',
            type: 'is-success'
          })
          this.$parent.close()
          if (this.onSave) this.onSave(record)
        })
      }
    }
  }
</script>
