import { mapState } from 'vuex'
import filters from 'shared/filters'
import userHelper from 'shared/mixins/user-helper'

export default {
  filters,
  mixins: [ userHelper ],
  data () {
    return {
      loading: true,
      records: [],
      defaultFilter: {},
      sort: {
        field: 'updated_at',
        order: 'desc'
      },
      meta: {
        page: 1,
        record_count: 0,
        page_count: 0
      }
    }
  },
  computed: {
    ...mapState([ 'depot' ]),
    sortParam () {
      if (this.sort.order === 'desc') {
        return `-${this.sort.field}`
      } else {
        return this.sort.field
      }
    }
  },
  created () {
    this.setMeta()
    this.loadAsyncData()
  },
  watch: {
    depot () {
      this.setMeta()
      this.loadAsyncData()
    },
    '$route.query' () {
      this.setMeta()
      this.loadAsyncData()
    }
  },
  methods: {
    applyRouteFilter () {
      let filter = {
        ...this.$route.meta.computedRouteFilter(),
        ...this.defaultFilter
      }
      if (this.depot) filter.depot_id = this.depot.id
      return filter
    },
    setMeta () {
      this.meta.page = parseInt(this.$route.query.page || 1)
      if (this.$route.query.sort) {
        let sort = this.$route.query.sort.split('.')
        this.sort = {
          field: sort[0],
          order: sort[1] || 'asc'
        }
      }
    },
    cleanFilters (obj, allowed) {
      obj = { ...obj }
      Object.keys(obj).forEach((key) => (obj[key] == null || obj[key] == '') && delete obj[key])
      if (allowed) {
        let allowedObj = {}
        allowed.forEach(k => allowedObj[k] = obj[k])
        return allowedObj
      }
      return obj
    },
    onSort (field, order) {
      // this.sort = { field, order }
      // this.loadAsyncData()
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          sort: `${field}.${order}`
          // 'sort[field]': field,
          // 'sort[order]': order //,{ field, order }
        }
      })
    },
    onPageChange (page) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page
        }
      })
      window.scrollTo(0, 0)
    },
    handleResponse (response) {
      this.meta = {
        page: this.meta.page,
        ...response.meta
      }
      this.records = response.data
      this.loading = false
    },
    loadAsyncData () {
      // overwrite me
    }
  }
}
