<template>
  <b-field label="Status">
    <b-select v-model="localValue" @input="update" icon="flag-variant-outline" expanded>
      <option value="default" v-if="includeDefault">Default</option>
      <option value="" v-if="allowBlank">All</option>
      <option
        v-for="(label, status) in unitStatuses"
        :value="status"
        :key="status">
        {{ label }}
      </option>
      <option v-if="includeDeleted" value="deleted">
        Deleted
      </option>
    </b-select>
  </b-field>
</template>

<script>
  import { unitStatuses } from 'shared/statuses'
  export default {
    props: {
      value: String,
      default: String,
      includeDefault: Boolean,
      includeDeleted: Boolean,
      allowBlank: Boolean
    },
    data () {
      return {
        unitStatuses,
        localValue: this.value || this.default
      }
    },
    methods: {
      update () {
        this.$emit('input', this.localValue)
      }
    }
  }
</script>
