<template>
  <div v-if="record" class="order-detail">
    <section class="section">
      <!-- ACTIONS -->
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <router-link to="/rentals" class="navbar-item">
              <span class="mdi mdi-chevron-left"></span>&nbsp; Rentals
            </router-link>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="field has-addons">
              <div class="control">
                <button class="button" @click="assignUnit()">
                  <span class="icon icon-medium"><i class="mdi mdi-file-replace-outline mdi-18px"></i></span>
                  <span>Assign</span>
                </button>
              </div>
              <div class="control">
                <button class="button" @click="gateOut({ product: record.product })">
                  <span class="icon icon-medium"><i class="mdi mdi-clipboard-arrow-up-outline mdi-18px"></i></span>
                  <span>Gate Out</span>
                </button>
              </div>
              <template v-if="record.transfer">
                <div class="control">
                  <router-link :to="{ name: 'transfer', params: { id: record.transfer.id }}" data-attribute="transfer.reference">
                    <button class="button">
                      <span class="icon icon-medium"><i class="mdi mdi-clipboard-flow mdi-18px"></i></span>
                      <span>View Transfer</span>
                    </button>
                  </router-link>
                </div>
              </template>
              <div class="control">
                <button class="button" v-if="is_admin" @click="editRecord()">
                  <span class="icon icon-medium"><i class="mdi mdi-file-document-edit-outline mdi-18px"></i></span>
                  <span>Edit</span>
                </button>
              </div>
              <div class="control">
                <button class="button" v-if="is_admin && !record.transfer" @click="deleteRecord()">
                  <span class="icon icon-medium"><i class="mdi mdi-delete-forever-outline mdi-18px"></i></span>
                  <span>Delete</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box is-relative">
        <order-status :record="record" />
        <div class="columns">
          <div class="column is-narrow">
            <p class="heading">Rental ID</p>
            <h4 class="title is-4">{{ record.reference }}</h4>
          </div>
          <div class="column is-narrow">
            <div class="level is-mobile">
              <div class="level-item">
                <div class="has-text-centered">
                  <p class="heading">Total</p>
                  <h5 class="title is-5"><span data-attribute="total-count" >{{ record.unit_count }}</span></h5>
                </div>
              </div>

              <div class="level-item">
                <div class="has-text-centered">
                  <p class="heading">Assigned</p>
                  <h5 class="title is-5"><span data-attribute="assigned-count">{{ record.order_units.length }}</span></h5>
                </div>
              </div>

              <div class="level-item">
                <div class="has-text-centered">
                  <p class="heading">Delivered</p>
                  <h5 class="title is-5"><span data-attribute="delivered-count">{{ deliveredCount }}</span></h5>
                </div>
              </div>

              <div class="level-item">
                <div class="has-text-centered">
                 <p class="heading">Remaining</p>
                 <h5 class="title is-5"><span data-attribute="remaining-count">{{ remainingCount }}</span></h5>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="b-table">
          <div class="table-wrapper">
            <table class="table is-striped is-narrow has-mobile-cards is-inbox">

              <tr>
                <th class="is-hidden-mobile" style="width: 150px;">
                  Depot:
                </th>
                <td data-label="Depot:">
                  <span class="tag" data-attribute="depot.name">
                    {{ record.depot.name }}
                  </span>
                </td>
              </tr>

              <tr>
                <th class="is-hidden-mobile" style="width: 150px;">
                  Supplier:
                </th>
                <td data-label="Supplier:">
                  <span v-if="record.data && record.data.supplier" class="tag" data-attribute="data.supplier">
                    {{ record.data.supplier }}
                  </span>
                  <span v-else>N/A</span>
                </td>
              </tr>

              <tr v-if="record.product_code">
                <th class="is-hidden-mobile" style="width: 150px;">
                  Product Code:
                </th>
                <td data-label="Product Code:">
                  <span data-attribute="product.code">
                    {{ record.product_code }}
                  </span>
                </td>
              </tr>

              <tr v-if="record.color">
                <th class="is-hidden-mobile" style="width: 150px;">
                  Color:
                </th>
                <td data-label="Color:">
                  <span class="color-swatch-wrapper">
                    <span class="color-swatch" :class="record.color | colorClassName"></span>
                    <span data-attribute="color">{{ record.color }}</span>
                  </span>
                </td>
              </tr>

              <tr>
                <th class="is-hidden-mobile" style="width: 150px;">
                  Expected:
                </th>
                <td data-label="Expected:">
                  <span data-attribute="expected-at">{{ record.expected_at | formattedDate }}</span>
                </td>
              </tr>

              <tr>
                <th class="is-hidden-mobile" style="width: 150px;">
                  Expired:
                </th>
                <td data-label="Expired:">
                  <span v-if="record.expires_at" data-attribute="expires-at">{{ record.expires_at | formattedDate }}</span><span v-else>N/A</span>
                </td>
              </tr>

              <tr>
                <th class="is-hidden-mobile" style="width: 150px;">
                  Created By:
                </th>
                <td data-label="Expired:">
                  <span v-if="record.created_by_user" data-attribute="created-by-user">
                    {{ record.created_by_user.first_name }}
                    {{ record.created_by_user.last_name }}
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="box">
        <h6 class="title is-6">Notes</h6>
        <div class="pre-wrapper">
          <pre data-attribute="notes">{{ record.notes }}</pre>
        </div>
      </div>

      <!-- BODY -->
      <div class="box">
        <h5 class="title is-5">Assigned Units</h5>
        <assigned-order-unit-table :records="record.order_units" type="rental" @gateOut="gateOut" @delete="deleteOrderUnit" />
      </div>

      <div class="box">
        <h5 class="title is-5">On Rent Units</h5>
        <on-rent-order-unit-table :records="onRentUnits" :is-rental="true" @gateIn="gateIn" />
      </div>

      <div class="box">
        <h5 class="title is-5">Returned Units</h5>
        <returned-unit-table :records="returnedUnits" :loading="false" />
      </div>

    </section>

  </div>

  <loader v-else />

</template>

<script>
  import { mapState } from 'vuex'
  import filters from 'shared/filters'

  import UserHelper from 'shared/mixins/user-helper'
  import AsyncRecord from 'shared/mixins/async-record'
  import OrderUnitActions from '../../mixins/order-unit-actions'

  import Loader from 'shared/components/loader'
  import OrderStatus from './status'
  import OrderForm from './form'
  import AssignedOrderUnitTable from '../outgoing-order-units/table'
  import OnRentOrderUnitTable from '../incoming-order-units/table'
  import ReturnedUnitTable from './returned-unit-table'
  import OrderUnitForm from '../outgoing-order-units/form'

  export default {
    filters,
    mixins: [ UserHelper, AsyncRecord, OrderUnitActions ],
    components: {
      Loader,
      OrderStatus,
      AssignedOrderUnitTable,
      OnRentOrderUnitTable,
      ReturnedUnitTable
    },
    data () {
      return {
        orderUnitForm: OrderUnitForm,
        asyncRecordOptions: {
          type: 'order',
          options: {
            order_type: 'rental',
            include: 'created_by_user,depot,product,order_units,order_units.unit,order_units.unit.product'
          }
        }
      }
    },
    computed: {
      deliveredCount () {
        if (!this.record) return 0
        return this.record.order_units.filter(i => i.released_at).length
      },
      remainingCount () {
        if (!this.record) return 0
        return parseInt(this.record.unit_count) - this.deliveredCount
      },
      assignedUnits () {
        return this.record.order_units.filter(i => !i.released_at && !i.arrived_at)
      },
      onRentUnits () {
        // TODO improve this filter
        return this.record.order_units.filter(i => i.released_at && !i.arrived_at && i.unit.status == 'on_rent')
      },
      returnedUnits () {
        return this.record.order_units.filter(i => i.released_at && i.arrived_at)
      }
    },
    methods: {
      isUnitReturned (unit) {
        return this.returnedUnits.find(i => i.id == unit.id) !== null
      },
      returnUnit (order_unit) {
        this.gate_in_unit = {
          unit: {
            ...order_unit.unit,
            status: 'available'
          },
          unit_id: order_unit.unit.id,
          order: this.record,
          arrived_at: new Date()
        }
      },
      editRecord () {
        this.$buefy.modal.open({
          parent: this,
          component: OrderForm,
          props: {
            record: { ...this.record },
            onSave: record => {
              this.fetchRecord()
            }
          }
        })
      },
      deleteRecord () {
        this.$buefy.dialog.confirm({
          title: 'Deleting Rental Order',
          message: 'Are you sure you want to <b>delete</b> this rental order?',
          confirmText: 'Delete Rental Order',
          type: 'is-danger',
          onConfirm: () => {
            this.$store.dispatch('deleteRecord', [ 'order', this.record ]).then(() => {
              this.$router.push('/rentals')
            })
          }
        })
      }
    }
  }
</script>
