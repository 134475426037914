<template>
  <div class="box">

    <h4 v-if="record.id" class="title is-4">Edit Modification Order</h4>
    <h4 v-else class="title is-4">New Modification Order</h4>

    <form @submit.prevent="validateAndSave()">

      <div class="columns is-multiline">

        <template v-if="!depot_only_user">
          <div class="column">
            <input v-if="user_depot" type="hidden" v-model="record.depot" />
            <depot-select
              v-else
              v-model="record.depot"
              default-value="Unassigned"
            />
          </div>
        </template>

        <div class="column">
          <order-status-select v-model="record.status" :order-type="record.order_type" :disable="depot_only_user ? [ 'picked_up', 'closed_out' ] : null" />
        </div>

        <div class="column">
          <order-priority-select v-model="record.priority" />
        </div>

        <div class="column is-full">
          <div class="columns">

            <div class="column">
              <b-field label="Order Number">
                <b-input type="text" v-model="record.reference" :disabled="depot_only_user" v-validate="'required'" data-vv-name="order number"></b-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Release Number">
                <b-input type="text" v-model="record.data.release_number" :disabled="depot_only_user"></b-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Unit Count">
                <b-input type="number" v-model="record.unit_count" :disabled="depot_only_user" v-validate="'required|min_value:1'" data-vv-name="unit count"></b-input>
              </b-field>
            </div>
          </div>
        </div>

        <div class="column is-full">
          <div class="columns">

            <div class="column">
              <b-field label="Client Name">
                <b-input type="text" v-model="record.data.client_name"></b-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Client Phone">
                <b-input type="tel" v-model="record.data.client_phone"></b-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Client Email">
                <b-input type="email" v-model="record.data.client_email"></b-input>
              </b-field>
            </div>

          </div>
        </div>

        <div class="column is-full">
          <div class="columns">

            <div class="column">
              <b-field label="Delivery City">
                <b-input type="text" v-model="record.data.delivery_city" :disabled="record.transfer"></b-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Sales Rep">
                <b-select
                  v-model="record.data.sales_rep"
                  data-vv-name="year made"
                  expanded>
                  <option></option>
                  <option v-for="rep in salesReps">{{ rep }}</option>
                </b-select>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Deposit Needed">
                <b-checkbox v-model="record.data.deposit_needed" class="inline-label">
                  Yes
                </b-checkbox>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Inventory Needed">
                <b-checkbox v-model="record.data.inventory_needed" class="inline-label">
                  Yes
                </b-checkbox>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Site Ready">
                <b-checkbox v-model="record.data.site_ready" class="inline-label">
                  Yes
                </b-checkbox>
              </b-field>
            </div>

          </div>
        </div>

        <div class="column is-full">
          <div class="columns">

            <div class="column">
              <b-field label="Customer Due Date">
                <date-time-picker v-model="record.expected_at" :time="false"></date-time-picker>
              </b-field>
            </div>

            <div class="column">
              <b-field>
                <template slot="label">
                  Estimated Completion
                  <span class="is-hidden-touch">Date</span>
                </template>
                <date-time-picker v-model="record.expires_at" :time="false"></date-time-picker>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Approval Date">
                <date-time-picker v-model="record.approved_at" :time="false"></date-time-picker>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Delivery Date" class="date-picker-right">
                <date-time-picker v-model="record.delivered_at" :time="false"></date-time-picker>
              </b-field>
            </div>

          </div>
        </div>

        <div class="column is-full">
          <b-field label="Notes">
            <b-input type="textarea" v-model="record.notes"></b-input>
          </b-field>
        </div>

        <div class="column is-full">
          <div class="field is-grouped">
            <button class="button is-primary is-fullwidth">Save</button>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
  import { phoneNumber } from 'shared/filters'
  import moment from 'moment'
  import userHelper from 'shared/mixins/user-helper'
  import hasDateFields from 'shared/mixins/has-date-fields'
  import DepotSelect from '../../shared/components/depot-select'
  import UnitColorSelect from '../../shared/components/unit-color-select'
  import OrderProductSelect from '../../shared/components/order-product-select'
  import OrderStatusSelect from '../../shared/components/order-status-select'
  import OrderPrioritySelect from '../../shared/components/order-priority-select'
  import salesReps from 'shared/sales-reps'
  export default {
    mixins: [
      userHelper,
      hasDateFields
    ],
    components: {
      DepotSelect,
      UnitColorSelect,
      OrderProductSelect,
      OrderStatusSelect,
      OrderPrioritySelect
    },
    props: {
      record: {
        type: Object,
        default () {
          return {
            classification: 'sales',
            order_type: 'modification',
            status: 'awaiting_approval',
            expected_at: null,
            expires_at: null,
            data: {}
          }
        }
      },
      onSave: {
        type: Function
      }
    },
    data () {
      return {
        salesReps
      }
    },
    created () {
      if (this.user_depot) {
        this.record.depot = this.user_depot
      }
      this.initDateFields([ 'expected_at', 'expires_at', 'approved_at', 'delivered_at' ])
      if (typeof(this.record.data.client_phone) === 'string') {
        this.record.data.client_phone = phoneNumber(this.record.data.client_phone)
      }
    },
    methods: {
      validateAndSave () {
        this.$validator.validateAll().then(result => {
          if (result) {
            return this.save()
          }
          this.$store.commit('error.validation', this.errors)
        })
      },
      save () {
        this.$store.dispatch('saveRecord', [ 'order', this.record, { include: 'created_by_user,depot,order_units,order_units.unit' }]).then(record => {
          this.$buefy.toast.open({
            message: 'Order Saved',
            type: 'is-success'
          })
          this.$parent.close()
          if (this.onSave) this.onSave(record)
        })
      }
    }
  }
</script>
