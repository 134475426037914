<template>
  <b-field label="Status" expanded>
    <b-select v-model="localValue" @input="update" placeholder="All" icon="flag-variant-outline" expanded>
      <option
        v-for="(label, option) in options"
        :key="option"
        :value="option"
        :disabled="isDisabled(option)">
        {{ label }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
  import { orderStatuses } from 'shared/statuses'
  export default {
    props: {
      value: {
        type: String,
        required: true
      },
      orderType: {
        type: String,
        required: true
      },
      disable: {
        type: Array,
        default: []
      }
    },
    data () {
      return {
        localValue: this.value,
        options: orderStatuses[this.orderType]
      }
    },
    methods: {
      isDisabled (option) {
        return Array.isArray(this.disable) && this.disable.includes(option)
      },
      update () {
        this.$emit('input', this.localValue)
      }
    }
  }
</script>
