import { mapState } from 'vuex'

import UserHelper from 'shared/mixins/user-helper'
import Loader from 'shared/components/loader'
import Modal from 'shared/components/modal'

export default {
  mixins: [ UserHelper ],
  components: {
    Loader,
    Modal
  },
  methods: {
    editRecord (record) {
      console.log('overwrite me')
    }
  }
}
