<template>

    <article v-if="records.length === 0" class="message is-warning">
      <div class="message-body">
        No incoming units to report
      </div>
    </article>

    <b-table v-else
      :data="records"
      :striped="true"
      :narrowed="true">

      <template slot-scope="props">

        <b-table-column field="depot" label="Depot" width="250">
          <h6 class="title is-6">{{ props.row.unit.depot.name }}</h6>
        </b-table-column>

        <b-table-column field="unit_number" label="Unit Number">
          <router-link :to="{ name: 'unit', params: { id: props.row.unit.id }}">
            {{ props.row.unit.number }}
          </router-link>
        </b-table-column>

        <b-table-column field="product_code" label="Product Code">
          {{ props.row.unit.product.code }}
        </b-table-column>

        <b-table-column field="order_id" label="Order">
          <router-link :to="{ name: `${direction}-order`, params: { id: props.row.order.id }}">
            {{ props.row.order.reference }}
          </router-link>
        </b-table-column>

        <template v-if="direction === 'incoming'">

          <b-table-column field="supplier" label="Supplier">
            {{ (props.row.order.data || {}).supplier | upcase }}
          </b-table-column>

          <b-table-column field="expected_at" label="Expected">
            {{ props.row.order.expected_at | formattedDateTime }}
          </b-table-column>

          <b-table-column field="arrived_at" label="Arrived">
            {{ props.row.arrived_at | formattedDateTime }}
          </b-table-column>

          <b-table-column v-if="props.row.accepted_by_user" field="accepted_by_user" label="Accepted By">
            {{ props.row.accepted_by_user.first_name | firstLetter }}
            {{ props.row.accepted_by_user.last_name }}
          </b-table-column>

        </template>

        <template v-else>

          <b-table-column field="purchase_order" label="Purchase Order">
            {{ (props.row.order.data || {}).purchase_order }}
          </b-table-column>

          <b-table-column field="released_at" label="Released">
            {{ props.row.released_at | formattedDateTime }}
          </b-table-column>

          <b-table-column v-if="props.row.released_by_user" field="released_by_user" label="Released By">
            {{ props.row.released_by_user.first_name | firstLetter }}
            {{ props.row.released_by_user.last_name }}
          </b-table-column>

        </template>

      </template>
    </b-table>

</template>

<script>
  import filters from 'shared/filters'
  export default {
    filters: {
      ...filters,
      firstLetter (string) {
        return string.toString()[0]
      }
    },
    props: {
      records: {
        type: Array,
        required: true
      },
      direction: {
        type: String,
        default: 'incoming'
      }
    },
  }
</script>
