<template>
  <section class="section units">

    <div class="level is-mobile">
      <div class="level-item level-left">
        <h1 class="title is-size-4-mobile">Inventory</h1>
      </div>
      <div v-if="is_admin" class="level-item level-right">
        <button @click="newRecord" class="button is-outlined is-primary">
          <span class="icon icon-medium"><i class="mdi mdi-square-edit-outline mdi-18px"></i></span>
          <span>New Unit</span>
        </button>
      </div>
    </div>

    <div class="unit-index">
      <unit-filters />
      <unit-table />
    </div>

  </section>
</template>

<script>
  import Vue from 'vue'
  import userHelper from 'shared/mixins/user-helper'
  import depotFilter from 'shared/mixins/depot-filter'
  import UnitTable from './table'
  import UnitForm from './form'
  import UnitFilters from './filters'
  export default {
    mixins: [ userHelper, depotFilter ],
    components: {
      UnitTable,
      UnitFilters
    },
    methods: {
      newRecord () {
        this.$buefy.modal.open({
          parent: this,
          component: UnitForm,
          props: {
            onSave: record => {
              this.$router.push({ name: 'unit', params: { id: record.id }})
            }
          }
        })
      }
    }
  }
</script>
