import api from 'shared/json-api'

export default {

  fetchRecords ({ commit }, [ type, options ]) {
    return new Promise((resolve, reject) => {
      api.findAll(type, options).then(response => {
        resolve(response.data)
      }).catch(reject)
    })
  },

  fetchRecord ({ commit }, [ type, id, options ]) {
    return new Promise((resolve, reject) => {
      api.find(type, id, options).then(response => {
        resolve(response.data)
      }).catch(reject)
    })
  },

  saveRecord ({ commit }, [ type, record, options ]) {
    delete record.created_at
    delete record.updated_at
    return new Promise((resolve, reject) => {
      api[record.id ? 'update' : 'create'](type, record, options).then(response => {
        resolve(response.data)
      }).catch(reject)
    })
  },

  deleteRecord ({ commit }, [ type, record ]) {
    return new Promise((resolve, reject) => {
      api.destroy(type, record.id).then(response => {
        resolve(record)
      }).catch(reject)
    })
  }

}
