import moment from 'moment'
import numeral from 'numeral'
import { titleize, humanize, capitalize } from 'inflected'
import phoneFormatter from './formatters/phone-number'

numeral.register('format', 'phone', phoneFormatter)

export const phoneNumber = (number) => {
  if (typeof(number) === 'string' && number.length) {
    return numeral(number).format('(NNN) NNN-NNNN')
  }
}

export const currency = (number) => {
  return numeral(number).format('$0,0.00')
}

export default {
  titleize,
  humanize,
  capitalize,
  currency,
  phoneNumber,
  upcase (string) {
    if (!string) return
    return string.toUpperCase()
  },
  colorClassName (name) {
    name = name.split(' - ')
    return (name[1] || name[0]).toLowerCase().replace(/\s/g, '-')
  },
  formattedDate (date) {
    if (date) {
      return moment(new Date(date)).format('MMM D, YY')
    }
  },
  formattedDateTime(date) {
    if (date) {
      return moment(new Date(date)).format('MMM Do YYYY, h:mm a')
    }
  },
  simpleDateTime(date) {
    if (date) {
      return moment(new Date(date)).format('M/D/YY h:mm a')
    }
  },
  yesOrNo (boolean) {
    return boolean ? 'Yes' : 'No'
  },
  pretty (value) {
    return JSON.stringify(value, null, 2)
  },
  basename (string) {
    return string.split('/').pop()
  }
}
