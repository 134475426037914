<template>
  <div>
    <b-table
      :data="records"
      :loading="loading"
      :striped="true"
      :narrowed="true"
      default-sort="first_name">

      <template slot-scope="props">
        <b-table-column field="first_name" label="First Name" sortable data-attribute="user.first_name">
          {{ props.row.first_name }}
        </b-table-column>

        <b-table-column field="last_name" label="Last Name" sortable data-attribute="user.last_name">
          {{ props.row.last_name }}
        </b-table-column>

        <b-table-column field="email" label="Email" sortable data-attribute="user.email">
          {{ props.row.email }}
        </b-table-column>

        <b-table-column field="phone" label="Phone" sortable data-attribute="user.phone">
          {{ props.row.phone }}
        </b-table-column>

        <b-table-column field="user_role" label="Role" data-attribute="user.role">
          {{ props.row.role | titleize }}
        </b-table-column>

        <b-table-column field="depot" label="Depot" sortable data-attribute="user.depot.name">
          <span v-if="props.row.depot" class="tag">{{ props.row.depot.name }}</span>
        </b-table-column>

        <b-table-column v-if="is_admin" field="actions">
          <p class="is-whitespacenowrap">
            <a href="#" @click.prevent="$emit('edit', props.row)" data-action="edit">
              <span class="icon icon-medium">
                <i class="mdi mdi-account-edit mdi-24px"></i>
              </span>
            </a>
            <a href="#" @click.prevent="$emit('delete', props.row)" data-action="delete">
              <span class="icon icon-medium">
                <i class="mdi mdi-delete-forever-outline mdi-24px"></i>
              </span>
            </a>
          </p>
        </b-table-column>
      </template>
    </b-table>

  </div>

</template>

<script>
  import CollectionTable from 'shared/mixins/collection-table'
  export default {
    mixins: [ CollectionTable ]
  }
</script>
