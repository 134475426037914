<template>
  <section class="section delivery-detail">
    <h5 class="title is-5">Movements</h5>

    <template v-if="movements">
      <div v-if="movements.length === 0">
        <p>No movements</p>
      </div>
      <b-table v-else :data="movements">
        <template slot-scope="props">
          <b-table-column field="movement_type" label="Movement Type">
            {{ props.row.movement_type | humanize }}
          </b-table-column>

          <b-table-column field="product_code" label="Product Code">
            {{ props.row.product_code }}
          </b-table-column>

          <b-table-column field="product" label="Unit">
            <span v-if="props.row.unit" :title="props.row.unit.number">
              <router-link :to="{ name: 'unit', params: { id: props.row.unit.id }}">
                {{ props.row.unit.number }}
              </router-link>
            </span>
            <span v-else>
              Unassigned
            </span>
          </b-table-column>

          <b-table-column field="driver" label="Driver">
            <span v-if="props.row.driver">
              {{ props.row.driver.first_name }}
              {{ props.row.driver.last_name }}
            </span>
          </b-table-column>

          <b-table-column label="Scheduled">
            {{ props.row.scheduled_at | formattedDateTime }}
          </b-table-column>

          <!-- <b-table-column label="Door Dir">
            {{ props.row.door_direction }}
          </b-table-column>

          <b-table-column label="Big Truck">
            {{ props.row.data.big_truck_ok | yesOrNo }}
          </b-table-column>

          <b-table-column label="Site Ready">
            {{ props.row.data.site_ready | yesOrNo }}
          </b-table-column> -->

          <b-table-column field="destination" label="Destination">
            {{ locationString(props.row.destination) }}
          </b-table-column>

          <b-table-column field="status" label="Status">
            <status-select :record="props.row" record_type="movement" />
          </b-table-column>

          <b-table-column label="Actions">
            <button @click="editMovement(props.row)" class="button is-small">
              <span>Edit</span>
            </button>
          </b-table-column>

        </template>
      </b-table>
    </template>
  </section>
</template>

<script>
  import api from 'shared/json-api'
  import filters from 'shared/filters'
  import { addressToString } from 'shared/services/address-helper'
  import StatusSelect from './status'
  import MovementForm from './movement-form'
  export default {
    filters,
    components: {
      StatusSelect
    },
    props: {
      delivery: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        movements: null
      }
    },
    mounted () {
      if (!this.movements) {
        this.fetchMovements()
      }
    },
    methods: {
      async fetchMovements () {
        const response = await api.findAll('movement', {
          filter: {
            delivery_id: this.delivery.id
          },
          sort: 'scheduled_at,created_at',
          include: 'driver,unit,source,destination'
        })
        this.movements = response.data
      },
      locationString (location) {
        if (!location) return

        switch (location.type) {
          case 'depots':
            return location.name
          case 'addresses':
            return addressToString(location)
        }
      },
      editMovement (record) {
        this.$buefy.modal.open({
          parent: this,
          component: MovementForm,
          props: {
            movement: {
              ...record,
              delivery: this.record
            },
            onSave: record => {
              const index = this.movements.findIndex(i => i.id === record.id)
              this.movements.splice(index, 1, record)
            }
          }
        })
      }
    }
  }
</script>

<style>
  .delivery-detail .table-wrapper {
    overflow: visible;
  }
</style>
