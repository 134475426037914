<template>
  <div class="box">

    <h4 class="title is-4">Assign</h4>

    <form @submit.prevent="validateAndSave">

      <div class="columns is-multiline">
        <div class="column is-full">
          <product-select v-model="unit.product" data-vv-name="product" v-validate="'required'" />
        </div>

        <!-- Add logic for edit/create -->
        <div class="column is-full">
          <b-field class="field" label="Serial Number">
            <b-input type="textarea" v-model="numbers" data-vv-name="serial number" v-validate="{ required: true }"  @blur="formatNumbers()"></b-input>
          </b-field>
          <i class="help" v-if="formattedNumbers.length > 1">Found {{ formattedNumbers.length }} unique serial numbers</i>
        </div>

        <div class="column">
          <unit-color-select v-model="unit.color" />
        </div>

        <div class="column">
          <year-select v-model="unit.year_of_manufacture" />
        </div>

        <div class="column">
          <b-field class="field" label="Cost">
            <b-input type="text" v-model="unit.cost"></b-input>
          </b-field>
        </div>


        <div class="column is-full">
          <b-field label="Notes">
            <b-input type="textarea" v-model="unit.notes"></b-input>
          </b-field>
        </div>

        <div class="column is-full">
          <div class="field is-grouped">
            <button :class="{ 'is-loading': loading }" class="button is-primary is-fullwidth">Save</button>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
  import ProductSelect from '../../shared/components/product-select'
  import UnitColorSelect from '../../shared/components/unit-color-select'
  import YearSelect from '../../shared/components/year-select'
  export default {
    components: {
      ProductSelect,
      UnitColorSelect,
      YearSelect
    },
    props: {
      order: {
        type: Object,
        required: true
      },
      order_unit: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        record: {
          order: this.order
        },
        unit: {
          depot: this.order.depot,
          status: 'expected'
        },
        numbers: '',
        loading: false
      }
    },
    computed: {
      formattedNumbers () {
        return [
          ...new Set(this.numbers.split(/[\n\s,]/).map(i => i.trim()).filter(i => i.length > 0).map(i => {
            return i.replace(/-?(\d)$/, '-$1')
          }))
        ]
      }
    },
    created () {
      this.record = this.order_unit
      this.record.order = this.order
      if (this.record.unit) this.unit = Object.assign({}, this.record.unit)
      this.unit.depot = this.order.depot
    },
    methods: {
      formatNumbers () {
        this.numbers = this.formattedNumbers.join('\n')
      },
      validateAndSave () {
        this.$validator.validateAll().then(result => {
          if (result) {
            return this.save()
          }
          this.$store.commit('error.validation', this.errors)
        })
      },
      save () {
        delete this.unit.order_units
        this.loading = true
        let failures = []
        let promises = this.formattedNumbers.map(number => {
          return new Promise((resolve, reject) => {
            let orderUnit = { ...this.record }
            let unit = {
              ...this.unit,
              number
            }
            this.$store.dispatch('saveRecord', [ 'unit', unit ]).then(record => {
              unit.id = record.id
              this.record.unit = record
              this.$store.dispatch('saveRecord', [
                'order_unit',
                this.record,
                { include: 'order,unit,unit.product' }
              ]).then(resolve)
            }).catch(e => {
              failures.push(unit)
              reject(unit)
            })
          }).catch(e => {
            return e
          })
        })
        Promise.all(promises).then(() => {
          if (failures.length) {
            this.showFailures(failures)
            this.loading = false
          } else {
            this.close()
          }
        })
      },
      showFailures (failures) {
        const totalCount = this.formattedNumbers.length
        const successCount = totalCount - failures.length
        this.numbers = failures.map(i => i.number).join('\n')
        this.$parent.$parent.fetchRecord()
        this.$buefy.dialog.confirm({
          type: 'is-warning',
          canCancel: false,
          message: `${successCount}/${totalCount} units where assigned.<br/>Failed Units are listed in the Serial Number field.`
        })
      },
      close () {
        this.$buefy.toast.open({
          message: `${this.formattedNumbers.length > 1 ? 'Units' : 'Unit'} assigned`,
          type: 'is-success'
        })
        this.$parent.close()
        this.$parent.$parent.fetchRecord()
      }
    }
  }
</script>

<style scoped>
  .textarea-tall {
    height: 200px;
  }
</style>
