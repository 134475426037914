import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Dashboard from './components/dashboard/show'
import Search from './components/search/index'

import IncomingOrders from './components/incoming-orders/index'
import IncomingOrder from './components/incoming-orders/show'

import OutgoingOrders from './components/outgoing-orders/index'
import OutgoingOrder from './components/outgoing-orders/show'

import Rentals from './components/rentals/index'
import Rental from './components/rentals/show'

import Modifications from './components/modifications/index'
import Modification from './components/modifications/show'

import SupportTickets from './components/support-tickets/index'
import SupportTicket from './components/support-tickets/show'

import Deliveries from './components/deliveries/index'
import Delivery from './components/deliveries/show'

import Transfers from './components/transfers/index'
import Transfer from './components/transfers/show'

import Units from './components/units/index'
import Unit from './components/units/show'

import Depots from './components/depots/index'
import Depot from './components/depots/show'

import Products from './components/products/index'
import Users from './components/users/index'

const routes = [
  { path: '/', component: Dashboard, name: 'dashboard', meta: { title: 'Summary' }},
  { path: '/search/:query', component: Search, name: 'search', meta: { title: 'Search' }},

  { path: '/incoming', component: IncomingOrders, name: 'incoming', meta: { title: 'Incoming' }},
  { path: '/incoming/:id', component: IncomingOrder, name: 'incoming-order', meta: { title: 'Incoming' }},

  { path: '/releases', component: OutgoingOrders, name: 'outgoing', meta: { title: 'Releases' }},
  { path: '/releases/:id', component: OutgoingOrder, name: 'outgoing-order', meta: { title: 'Releases' }},

  { path: '/rentals', component: Rentals, name: 'rentals', meta: { title: 'Rentals' }},
  { path: '/rentals/:id', component: Rental, name: 'rental-order', meta: { title: 'Rentals' }},

  { path: '/modifications', component: Modifications, name: 'modifications', meta: { title: 'Modifications' }},
  { path: '/modifications/:id', component: Modification, name: 'modification-order', meta: { title: 'Modifications' }},

  { path: '/support-tickets', component: SupportTickets, name: 'support-tickets', meta: { title: 'Support Tickets' } },
  { path: '/support-tickets/:id', component: SupportTicket, name: 'support-ticket', meta: { title: 'Support Tickets' } },

  { path: '/dispatches', component: Deliveries, name: 'dispatches', meta: { title: 'Dispatches' }},
  { path: '/dispatch/:id', component: Delivery, name: 'dispatch', meta: { title: 'Dispatches' }},

  { path: '/transfers', component: Transfers, name: 'transfers', meta: { title: 'Transfers' }},
  { path: '/transfers/:id', component: Transfer, name: 'transfer', meta: { title: 'Transfers' }},

  { path: '/inventory', component: Units, name: 'units', meta: { title: 'Inventory' }},
  { path: '/inventory/:id', component: Unit, name: 'unit', meta: { title: 'Inventory' }},

  { path: '/depots', component: Depots, name: 'depots', meta: { title: 'Depots' }},
  { path: '/depots/:id', component: Depot, name: 'depot', meta: { title: 'Depots' }},

  { path: '/products', component: Products, meta: { title: 'Products' }},
  { path: '/users', component: Users, meta: { title: 'Users' }}
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.afterEach((to, from) => {
  if (to.meta) {
    window.document.title = `${to.meta.title} - Interchange - Container Alliance`
  } else {
    window.document.title = 'Interchange - Container Alliance'
  }
})

export default router
