export default {
  data () {
    return {
      record: null,
      asyncRecordOptions: {}
    }
  },
  created () {
    this.fetchRecord()
  },
  methods: {
    onRecordLoaded () {
      // callback
    },
    fetchRecord () {
      this.$store.dispatch('fetchRecord', [
        this.asyncRecordOptions.type,
        this.$route.params.id,
        this.asyncRecordOptions.options
      ]).then(record => {

        // Route enforcement for orders.. don't let a user view an incoming order on a release etc
        if (record.order_type) {
          const routeName = record.order_type.replace(/_/g, '-')
          if (!new RegExp(routeName).test(this.$route.name)) {
            this.$router.replace({ name: `${routeName}-order`, params: { id: record.id }})
            return
          }
        }
        this.record = record
        this.onRecordLoaded()
      })
    },
    recoverRecord () {
      this.$buefy.toast.open({
        message: 'Record recovery is currently in development...',
        type: 'is-warning'
      })
      //
      // this.$store.dispatch('saveRecord', [
      //   this.asyncRecordOptions.type, {
      //     id: this.record.id,
      //     deleted_at: null
      //   }
      // ]).then(record => {
      //   this.$buefy.toast.open({
      //     message: 'Recovered!',
      //     type: 'is-success'
      //   })
      //   this.fetchRecord()
      // })
    }
  }
}
