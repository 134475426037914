import TraceHelper from 'shared/components/traces/helper'
import filters from 'shared/filters'

export default {
  filters,
  components: {
    TraceHelper
  },
  props: {
    record: {
      type: Object,
      default: true
    },
    trace: {
      type: Object,
      required: true
    }
  }
}
