<template>
  <b-field label="Priority" expanded>
    <b-select v-model="localValue" @input="update" icon="priority-high" expanded>
      <option
        v-for="(label, option) in options"
        :value="option"
        :disabled="isDisabled(option)">
        {{ label }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        default: 'regular'
      },
      disable: {
        type: Array,
        default () {
          return []
        }
      }
    },
    data () {
      return {
        localValue: this.value,
        options: {
          regular: 'Regular',
          low: 'Low',
          high: 'High'
        }
      }
    },
    methods: {
      isDisabled (option) {
        return Array.isArray(this.disable) && this.disable.includes(option)
      },
      update () {
        this.$emit('input', this.localValue)
      }
    }
  }
</script>
