<template>
  <section class="section rentals">

    <div class="level is-mobile">
      <div class="level-item level-left">
        <h1 class="title is-size-4-mobile">Rental Orders</h1>
      </div>
      <div v-if="!user_is_role('depot')" class="level-item level-right">
        <button @click="newRecord()" class="button is-primary is-outlined">
          <span class="icon icon-medium"><i class="mdi mdi-clipboard-arrow-up-outline mdi-18px"></i></span>
          <span>New <span class="is-hidden-mobile">Rental Order</span></span>
        </button>
      </div>
    </div>

    <order-filters :statusOptions="{ ready: 'Ready', closed_out: 'Closed Out' }" :show-transfer-filter="false" />
    <order-table />

  </section>
</template>

<script>
  import userHelper from 'shared/mixins/user-helper'
  import OrderFilters from '../../shared/components/order-filter'
  import OrderTable from './table'
  import OrderForm from './form'
  export default {
    mixins: [ userHelper ],
    components: {
      OrderTable,
      OrderFilters
    },
    methods: {
      newRecord () {
        this.$buefy.modal.open({
          parent: this,
          component: OrderForm,
          props: {
            onSave: record => {
              this.$router.push({ name: 'rental-order', params: { id: record.id }})
            }
          }
        })
      }
    }
  }
</script>
