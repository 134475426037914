<template>
  <div class="depot-select">
    <select v-if="user_depot" class="select is-small is-fullwidth" disabled>
      <option>{{ user_depot.name }}</option>
    </select>
    <b-select v-else v-model="depot" icon="map-marker-multiple" size="is-small" expanded>
      <option :value="null">All</option>
      <option v-for="depot in depots" :value="depot">
        {{ depot.name }}
      </option>
    </b-select>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import UserHelper from 'shared/mixins/user-helper'
  export default {
    mixins: [ UserHelper ],
    computed: {
      ...mapState([ 'depots' ]),
      depot: {
        get () {
          return this.$store.state.depot
        },
        set (value) {
          this.$store.commit('depot.select', value)
        }
      }
    },
    created () {
      // if (this.user_depot) return;
      this.$store.dispatch('fetchRecords', [
        'depot'
      ]).then(depots => {
        this.$store.commit('depots.loaded', depots)
      })
    }
  }
</script>
