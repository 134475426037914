import GateInForm from '../components/incoming-order-units/gate-in-form'
import GateOutForm from '../components/outgoing-order-units/gate-out-form'

export default {
  methods: {
    assignUnit () {
      this.$buefy.modal.open({
        parent: this,
        component: this.orderUnitForm,
        props: {
          order: this.record,
          order_unit: {},
          onSave: record => {
            this.fetchRecord()
          }
        }
      })
    },
    gateOut (record) {
      this.$buefy.modal.open({
        parent: this,
        component: GateOutForm,
        props: {
          order: this.record,
          record: { ...record },
          onSave: record => {
            this.fetchRecord()
          }
        }
      })
    },
    gateIn (record) {
      this.$buefy.modal.open({
        parent: this,
        component: GateInForm,
        props: {
          order: this.record,
          order_unit: {
            id: record.id,
            unit: {
              ...record.unit,
              status: 'pending_inspection'
            },
            order: this.record,
            arrived_at: new Date()
          },
          onSave: (record, options) => {
            this.fetchRecord()
            if (options.print) {
              let url = `${document.location.origin}/print/order-units/${record.id}`
              this.$buefy.dialog.alert({
                message: `<a href="${url}" target="_blank" class="button is-success is-large">Tap here to open Print View</a>`,
                confirmText: 'Done'
              })
            }
          }
        }
      })
    },
    editOrderUnit (unit) {
      this.$buefy.modal.open({
        parent: this,
        component: this.orderUnitForm,
        props: {
          order: this.record,
          order_unit: Object.assign({}, unit),
          onSave: record => {
            this.fetchRecord()
          }
        }
      })
    },
    deleteOrderUnit (unit) {
      this.$buefy.dialog.confirm({
        title: 'Removing assigned unit',
        message: 'Are you sure you want to <b>remove</b> this unit?',
        confirmText: 'Remove Unit',
        type: 'is-danger',
        onConfirm: () => {
          this.$store.dispatch('deleteRecord', [ 'order_unit', unit ]).then(() => {
            this.fetchRecord()
          })
        }
      })
    }
  }
}
