<template>
  <div class="box">

    <h4 class="title is-4">Assign Unit</h4>

    <form @submit.prevent="save()">

      <b-field label="Unit">
        <div class="unit-autocomplete">
          <unit-autocomplete
            v-model="unit"
            :depot="order.depot"
            :product_code="order.product_code"
            :fleet_status="order.order_type === 'sales' ? 'unassigned' : null"
            status="available,modification_ready"
            expanded />
        </div>
      </b-field>

      <b-field label="Notes">
        <b-input v-if="unit" v-model="unit.notes" type="textarea"></b-input>
        <b-input v-else type="textarea" disabled></b-input>
      </b-field>

      <div class="field">
        <button class="button is-primary is-fullwidth">Save</button>
      </div>

    </form>

  </div>
</template>

<script>
  import UnitAutocomplete from '../../shared/components/unit-autocomplete'
  export default {
    components: {
      UnitAutocomplete
    },
    props: {
      order: {
        type: Object,
        required: true
      },
      order_unit: {
        type: Object,
        default () {
          return {}
        }
      },
      onSave: {
        type: Function
      }
    },
    data () {
      return {
        unit: null,
        record: this.order_unit
      }
    },
    methods: {
      save () {
        let unitToSave = {
          id: this.unit.id,
          notes: this.unit.notes
        }
        this.record.order = this.order
        this.$store.dispatch('saveRecord', [ 'unit', unitToSave ]).then(unit => {
          this.record.unit = unit
          this.$store.dispatch('saveRecord', [ 'order_unit', this.record ]).then(record => {
            this.$buefy.toast.open({
              message: 'Unit Assigned',
              type: 'is-success'
            })
            this.$parent.close()
            if (this.onSave) this.onSave(record)
          })
        })
      }
    }
  }
</script>

<style lang="scss">
  .unit-autocomplete {
    .autocomplete .dropdown-menu.is-opened-top {
      top: 100% !important; // FORCE DROPDOWN DOWN FOR SMALL PHONES
    }
  }
</style>
