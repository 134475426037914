import { mapState } from 'vuex'
export default {
  computed: mapState({
    current_depot: state => state.depot
  }),
  created () {
    if (this.current_depot) {
      // this.$set(this.filters, this.depot_filter_column || 'depot_id', this.current_depot.id)
    }
  },
  watch: {
    current_depot (to) {
      // this.$set(this.filters, this.depot_filter_column || 'depot_id', to ? to.id : null)
    }
  }
}
