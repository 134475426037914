<template>
  <div class="columns is-tablet">
    <div class="column">
      <b-field label="Search by name">
        <b-input v-model="filter.query" @input="debounceQuery" placeholder="Product Name, Code..." icon="magnify"/>
      </b-field>
    </div>
    <div class="column is-1">
      <div class="field">
        <label class="label">&nbsp;</label>
        <button @click="resetFilter" class="button is-fullwidth">Reset</button>
      </div>
    </div>
  </div>
</template>

<script>
  import routeFilter from '../../mixins/route-filter'
  const DEFAULTS = {}
  const ALLOWED = [ 'query' ]
  export default {
    mixins: [ routeFilter ],
    created () {
      this.injectRouteFilter(DEFAULTS, ALLOWED)
    },
    methods: {
      debounceQuery () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.applyRouteFilter()
        }, 250)
      }
    }
  }
</script>
