<template>

  <div v-if="!order_unit" class="box">

    <h4 class="title is-4">Gate In</h4>

    <form @submit.prevent="validateAndSave">

      <b-field label="Preadvice Number"
        :type="{'is-danger': errors.has('preadvice number')}"
        :message="errors.first('preadvice number')">
        <b-input type="text" v-model="record.reference" name="preadvice number" v-validate="'required'"></b-input>
      </b-field>

      <b-field label="Supplier">
        <b-input type="text" v-model="record.data.supplier"></b-input>
      </b-field>

      <b-field label="Notes">
        <b-input type="textarea" v-model="record.notes"></b-input>
      </b-field>

      <div class="field is-grouped">
        <button class="button is-primary is-fullwidth">Save</button>
      </div>

    </form>
  </div>

  <gate-in-form v-else :order_unit="order_unit" :order="order_unit.order" :onSave="saved" />

</template>

<script>
  import { mapState } from 'vuex'
  import moment from 'moment'
  import api from 'shared/json-api'
  import Modal from 'shared/components/modal'
  import GateInForm from '../incoming-order-units/gate-in-form'
  export default {
    components: {
      Modal,
      GateInForm
    },
    props: {
      record: {
        type: Object,
        default: null
      }
    },
    computed: mapState([ 'depot' ]),
    data () {
      return {
        order_unit: null
      }
    },
    methods: {
      validateAndSave () {
        this.$validator.validateAll().then(result => {
          if (result) {
            return this.save()
          }
          // this.$store.commit('error.validation', this.errors)
        })
      },
      save () {
        api.findAll('order', {
          filter: {
            timing: 'all',
            status: 'all',
            order_type: 'incoming',
            reference: this.record.reference
          },
          include: 'depot'
        }).then(response => {
          console.log(response)
          if (response.data && response.data.length === 1) {
            this.initOrderUnit(response.data[0])
          } else {
            this.record.type = 'incoming'
            this.record.depot = this.depot
            this.record.unit_count = 1
            this.record.expected_at = new Date()
            this.record.expires_at = moment(new Date()).endOf('day').add(1, 'year')
            api.create('order', this.record, { include: 'depot' }).then(response => {
              this.initOrderUnit(response.data)
            })
          }
        })
      },
      initOrderUnit (order) {
        this.$store.commit('record.loaded', [ 'order', order ])
        this.order_unit = {
          order,
          arrived_at: new Date()
        }
      },
      saved() {
        this.$parent.close()
        this.$router.push({ name: 'incoming-order', params: { id: this.order_unit.order.id }})
      }
    }
  }
</script>
