<template>
  <div class="box">

    <h4 v-if="record.id" class="title is-4">Edit Modification Spec</h4>
    <h4 v-else class="title is-4">New Modification Spec</h4>

    <form @submit.prevent="validateAndSave()">

      <div class="columns is-multiline">

        <div class="column">
          <product-select v-model="product" @input="resetUnit()" />
        </div>

        <div class="column">
          <b-field label="Status" expanded>
            <b-select v-model="record.status" icon="flag-variant-outline" expanded>
              <option></option>
              <option v-for="(label, status) in statuses" :value="status">
                {{ label }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div v-if="productCode" class="column is-full">
          <b-field label="Unit">
            <div class="unit-autocomplete">
              <unit-autocomplete
                ref="autocomplete"
                v-model="record.unit"
                :depot="record.order.depot"
                :product_code="productCode"
                :fleet_status="'unassigned'"
                status="available"
                expanded />
            </div>
          </b-field>
        </div>

        <div class="column is-full">
          <b-field label="Notes">
            <b-input type="textarea" v-model="record.notes"></b-input>
          </b-field>
        </div>

        <div class="column is-full">
          <b-field label="Files">
            <dropzone v-model="record.uploads" />
          </b-field>
        </div>

        <div class="column is-full">
          <div class="field is-grouped">
            <button class="button is-primary is-fullwidth">Save</button>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
  import userHelper from 'shared/mixins/user-helper'
  import ProductSelect from '../../shared/components/product-select'
  import UnitAutocomplete from '../../shared/components/unit-autocomplete'
  import Dropzone from '../../shared/components/dropzone'
  import { modificationStatuses } from 'shared/statuses'
  export default {
    mixins: [ userHelper ],
    components: {
      ProductSelect,
      UnitAutocomplete,
      Dropzone
    },
    props: {
      record: {
        type: Object,
        default () {
          return {
            status: 'pending'
          }
        }
      },
      onSave: {
        type: Function
      }
    },
    data () {
      return {
        product: null,
        statuses: modificationStatuses
      }
    },
    computed: {
      productCode () {
        return this.product ? this.product.code : null
      }
    },
    created () {
      this.product = this.record.product
    },
    methods: {
      resetUnit () {
        setTimeout(() => {
          this.record.unit = null
          this.$refs.autocomplete.clear()
        }, 50)
      },
      validateAndSave () {
        this.$validator.validateAll().then(result => {
          if (result) {
            return this.save()
          }
          this.$store.commit('error.validation', this.errors)
        })
      },
      save () {
        this.record.product = this.product
        if (this.record.unit && !this.record.unit.id) {
          delete this.record.unit
        }
        // this.record.trace_data = { user_id: this.current_user.id }
        this.$store.dispatch('saveRecord', [ 'modification', this.record ]).then(record => {
          this.$buefy.toast.open({
            message: 'Modification Saved',
            type: 'is-success'
          })
          this.$parent.close()
          if (this.onSave) this.onSave(record)
        })
      }
    }
  }
</script>
