const pickKeys = (source, keys) => {
  let dest = {}
  keys.forEach(k => {
    if (source[k]) {
      dest[k] = source[k]
    }
  })
  return dest
}

export default {
  data () {
    return {
      filter: null
    }
  },
  watch: {
    '$route.query' () {
      this.setFilter()
    }
  },
  methods: {
    computedRouteFilter () {
      let obj = { ...this.filter }
      if (this.$route.meta.filter && this.$route.meta.filter.allowed) {
        obj = pickKeys(obj, this.$route.meta.filter.allowed)
      }
      // remove null or blank filters
      Object.keys(obj).forEach((key) => (obj[key] == null || obj[key] == '') && delete obj[key])
      return obj
    },
    injectRouteFilter (defaults, allowed) {
      if (this.$route.meta.filter) return
      this.$route.meta.filter = { defaults, allowed }
      this.setFilter()
      this.$route.meta.computedRouteFilter = this.computedRouteFilter
    },
    resetFilter () {
      this.filter = { ...this.$route.meta.filter.defaults }
      this.$emit('filter.reset')
      this.applyRouteFilter()
    },
    setFilter () {
      this.filter = {
        ...this.$route.meta.filter.defaults,
        ...this.$route.query
      }
    },
    applyRouteFilter () {
      this.$router.replace({
        name: this.$route.name,
        query: this.computedRouteFilter()
      })
    }
  }
}
