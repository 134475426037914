<template>
  <fieldset class="fieldset">

    <div class="columns is-multiline">

      <div class="column is-full">
        <b-field label="Address Line 1" label-for="field-address-line1">
          <b-input type="text" v-model="record.line1" id="field-address-line1"></b-input>
        </b-field>
      </div>

      <div class="column is-full">
        <b-field label="Address Line 2" label-for="field-address-line2">
          <b-input type="text" v-model="record.line2" id="field-address-line2"></b-input>
        </b-field>
      </div>

    </div>

    <div class="columns is-multiline">

      <div class="column is-half">
        <b-field label="City" label-for="field-address-locality">
          <b-input type="text" v-model="record.locality" id="field-address-locality"></b-input>
        </b-field>
      </div>

      <div class="column is-quarter">
        <b-field label="State" label-for="field-address-region">
          <b-input type="text" v-model="record.region" id="field-address-region"></b-input>
        </b-field>
      </div>

      <div class="column is-quarter">
        <b-field label="Zip" label-for="field-address-postal-code">
          <b-input type="text" v-model="record.postal_code" id="field-address-postal-code"></b-input>
        </b-field>
      </div>

    </div>

    <div class="columns is-multiline">
      <div class="column">
        <b-field label="Country" label-for="field-address-country">
          <b-select v-model="record.country_code" id="field-address-country" expanded>
            <option value="US">United States</option>
            <option v-for="(name, abbr) in countries" :value="abbr">
              {{ name }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>

  </fieldset>
</template>

<script>
  export default {
    props: {
      value: Object
    },
    data () {
      return {
        record: { ...this.value },
        // TODO remove countries from meta and make it an API endpoint..
        countries: JSON.parse(document.querySelector('meta[name="countries"]').getAttribute('content'))
      }
    },
    watch: {
      record: {
        handler () {
          this.$emit('input', this.record)
        },
        deep: true
      }
    }
  }
</script>
