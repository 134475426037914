<template>
  <div id="interchange" v-if="authenticated">

    <navigation />

    <div class="page">

      <error-messages v-if="error" />

      <!-- <request-error /> -->

      <div class="container">
        <router-view />
      </div>

    </div>

    <!-- TODO? -->
    <!-- <footer class="footer">
      <div class="content has-text-centered">
      </div>
    </footer> -->

  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import api from 'shared/json-api'
  import userHelper from 'shared/mixins/user-helper'
  import ErrorMessages from 'shared/components/error-messages'
  import Navigation from './shared/components/navigation'
  export default {
    mixins: [ userHelper ],
    components: {
      ErrorMessages,
      Navigation
    },
    computed: {
      ...mapState([ 'user', 'error' ]),
      ...mapGetters([ 'authenticated' ])
    },
    created () {
      return api.find('session').then(response => {
        this.$store.commit('authenticate', response.data)
      }).catch(error => {
        window.location.href = '/login'
      })
    }
  }
</script>
