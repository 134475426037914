import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      current_user: state => state.user,
      current_depot: state => state.depot
    }),
    user_role () {
      return this.current_user ? this.current_user.role : null
    },
    user_depot () {
      return this.current_user ? this.current_user.depot : null
    },
    depot_only_user () {
      return this.user_role === 'depot'
    },
    is_admin () {
      return this.user_role === 'admin'
    }
  },
  methods: {
    user_is_role (role) {
      return this.user_role === role
    }
  }
}
