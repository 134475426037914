<template>
  <b-field label="Year">
    <b-select
      v-model="localValue"
      data-vv-name="year made"
      v-validate="{ required: required, min_value: minYear, max_value: maxYear }"
      @input="update"
      expanded>
      <option></option>
      <option v-for="year in yearOptions">{{ year }}</option>
    </b-select>
  </b-field>
</template>

<script>
  export default {
    inject: [ '$validator' ],
    props: {
      value: {
        required: true
      },
      required: {
        type: Boolean,
        default: false
      },
      minYear: {
        type: Number,
        default: 1980
      },
      maxYear: {
        type: Number,
        default: new Date().getFullYear() + 1
      }
    },
    data () {
      return {
        localValue: this.value
      }
    },
    computed: {
      yearOptions () {
        return [...Array(1 + this.maxYear - this.minYear).keys()].map(v => this.minYear + v).reverse()
      }
    },
    methods: {
      update () {
        this.$emit('input', this.localValue)
      }
    }
  }
</script>
