<template>
  <div class="box">

    <h4 v-if="record.id" class="title is-4">Edit Dispatch</h4>
    <h4 v-else class="title is-4">New Dispatch</h4>

    <form @submit.prevent="validateAndSave()">

      <div class="columns is-multiline">

        <div class="column is-full">
          <div class="columns">

            <div class="column">
              <b-field label="Company Name">
                <b-input type="text" v-model="record.customer.company_name"></b-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="First Name">
                <b-input type="text" v-model="record.customer.first_name"></b-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Last Name">
                <b-input type="text" v-model="record.customer.last_name"></b-input>
              </b-field>
            </div>

          </div>
        </div>

        <div class="column is-full">
          <div class="columns">

            <div class="column">
              <b-field label="Email">
                <b-input type="text" v-model="record.customer.email"></b-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Phone">
                <b-input type="text" v-model="record.customer.phone"></b-input>
              </b-field>
            </div>

          </div>
        </div>

        <div class="column is-full">
          <div class="columns">

            <div class="column">
              <b-field label="Status">
                <b-select v-model="record.status" expanded>
                  <option></option>
                  <option v-for="(label, status) in deliveryStatuses" :value="status">
                    {{ label }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Order Number">
                <b-input type="text" v-model="record.reference" :disabled="depot_only_user" v-validate="'required'" data-vv-name="order number"></b-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Release">
                <b-input type="text" v-model="record.data.release_number" :disabled="depot_only_user"></b-input>
              </b-field>
            </div>

          </div>
        </div>

        <div class="column is-full">
          <div class="columns">

            <div class="column">
              <b-field label="Expected Date">
                <date-time-picker v-model="record.expected_at" :time="false" />
              </b-field>
            </div>

            <div class="column">
              <b-field label="Completion Date">
                <date-time-picker v-model="record.completed_at" :time="false" />
              </b-field>
            </div>

          </div>
        </div>

        <div class="column is-full">
          <b-field label="Notes">
            <b-input type="textarea" v-model="record.notes"></b-input>
          </b-field>
        </div>

        <div class="column is-full">
          <div class="field is-grouped">
            <button :disabled="loading" class="button is-primary is-fullwidth">Save</button>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
  import { phoneNumber } from 'shared/filters'
  import moment from 'moment'
  import userHelper from 'shared/mixins/user-helper'
  import hasDateFields from 'shared/mixins/has-date-fields'
  import { deliveryStatuses } from 'shared/statuses'
  export default {
    mixins: [
      userHelper,
      hasDateFields
    ],
    props: {
      record: {
        type: Object,
        default () {
          return {
            customer: {},
            data: {}
          }
        }
      },
      onSave: {
        type: Function
      }
    },
    data () {
      return {
        loading: false,
        deliveryStatuses
      }
    },
    created () {
      this.initDateFields([ 'expected_at', 'delivered_at' ])
    },
    methods: {
      validateAndSave () {
        this.$validator.validateAll().then(result => {
          if (result) {
            return this.save()
          }
          this.$store.commit('error.validation', this.errors)
        })
      },
      async save () {
        try {
          let record = this.record
          this.loading = true
          delete record.movements
          record.customer = await this.$store.dispatch('saveRecord', [ 'customer', record.customer ])
          if (record.driver && !record.driver.id) {
            delete record.driver
          }
          record = await this.$store.dispatch('saveRecord', [ 'delivery', record ])
          this.$buefy.toast.open({
            message: 'Delivery Saved',
            type: 'is-success'
          })
          this.$parent.close()
          if (this.onSave) this.onSave(record)
        } catch (e) {
          this.loading = false
          this.$buefy.toast.open({
            message: 'There was an issue while saving this delivery.',
            type: 'is-danger'
          })
        }
      }
    }
  }
</script>
