<template>
  <b-field label="Product Code">
    <b-select v-model="localValue" @input="update" icon="texture" expanded>
      <option :value="null"></option>
      <option v-if="products.length === 0" disabled>loading...</option>
      <option v-else v-for="code in productCodes" :value="code">
        {{ code }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
  export default {
    props: {
      value: String,
      fleetStatus: String
    },
    data () {
      return {
        localValue: this.value,
        products: []
      }
    },
    computed: {
      productCodes () {
        const products = this.products
        const attribute = this.fleetStatus && this.fleetStatus === 'rental' ? 'rental_code' : 'code'
        return [...new Set(products.map(i => i[attribute]))].sort()
      }
    },
    created () {
      this.$store.dispatch('fetchRecords', [ 'product', {
        sort: 'code',
        filter: {
          product_class: [ 'CONTAINERS' ]
        },
        page: {
          number: 1,
          size: 999
        }
      }]).then(products => {
        this.products = products
      })
    },
    methods: {
      update () {
        this.$emit('input', this.localValue)
      }
    }
  }
</script>
