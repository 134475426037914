<template>
  <div class="box">

    <h4 class="title is-4">Order {{ order.reference }}</h4>

    <form @submit.prevent="validateAndSave">

      <div class="columns is-multiline">

        <div class="column">
          <depot-select v-model="unit.depot" />
        </div>

        <div class="column">
          <b-field label="Gate In Date">
            <date-time-picker v-model="record.arrived_at"/>
          </b-field>
        </div>

        <template v-if="record.unit && record.unit.id">
          <div class="column">
            <b-field label="Unit">
              <b-input :value="`${record.unit.number} - ${record.unit.color}`" type="text" disabled></b-input>
            </b-field>
          </div>
          <div class="column">
            <unit-status-select v-model="unit.status" default="pending_inspection" />
          </div>
          <div class="column">
            <unit-color-select v-model="unit.color" />
          </div>
        </template>

        <template v-else>
          <div class="column is-full">
            <div class="columns">
              <div class="column is-half">
                <product-select v-model="unit.product" name="product" v-validate="'required'" />
              </div>

              <div class="column is-half">
                <b-field label="Serial Number">
                  <b-input type="text" v-model="unit.number" data-vv-name="serial number" v-validate="{ required: true, containerNumber: true }"></b-input>
                </b-field>
              </div>
            </div>
          </div>

          <div class="column is-full">
            <div class="columns">
              <!-- @input="debounceUnitSearch"
               <ul v-if="suggestions.length">
                <li v-for="suggestion in suggestions">
                  <a @click="setUnit(suggestion)">{{ suggestion.number }} - {{ suggestion.color }}</a>
                </li>
              </ul> -->

              <div class="column">
                <unit-status-select v-model="unit.status" default="pending_inspection" />
              </div>
              <div class="column">
                <unit-color-select v-model="unit.color" />
              </div>
              <div class="column">
                <year-select v-model="unit.year_of_manufacture" />
              </div>
            </div>
          </div>
        </template>

        <div class="column is-full">
          <div class="columns is-multiline">

            <div class="column is-one-third">
              <b-field label="Trucking Company">
                <b-input type="text" v-model="trace_data.trucking_company"></b-input>
              </b-field>
            </div>

            <div class="column is-one-third">
              <b-field label="License Plate">
                <b-input type="text" v-model="trace_data.license_plate"></b-input>
              </b-field>
            </div>

            <div class="column is-one-third">
              <b-field label="Driver Name">
                <b-input type="text" v-model="trace_data.driver_name"></b-input>
              </b-field>
            </div>

            <div class="column is-one-third">
              <b-field label="License #">
                <b-input type="text" v-model="trace_data.driver_license"></b-input>
              </b-field>
            </div>

            <div class="column is-one-third">
              <b-field label="Phone">
                <b-input type="text" v-model="trace_data.driver_phone"></b-input>
              </b-field>
            </div>

          </div>
        </div>

        <div class="column is-full">
          <label class="label">Photos</label>
          <dropzone v-model="unit.photos" />
        </div>

        <div class="column is-full">
          <b-field label="Notes">
            <b-input type="textarea" v-model="unit.notes"></b-input>
          </b-field>
        </div>

        <div class="column is-full">
          <div class="columns">
            <div class="column">
              <button @click="print = false" class="button is-primary is-fullwidth">Save</button>
            </div>
            <div class="column">
              <button @click="print = true" class="button is-success is-fullwidth">Save &amp; Print</button>
            </div>
          </div>
        </div>

      </div>

    </form>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import DepotSelect from '../../shared/components/depot-select'
  import ProductSelect from '../../shared/components/product-select'
  import UnitColorSelect from '../../shared/components/unit-color-select'
  import UnitStatusSelect from '../../shared/components/unit-status-select'
  import YearSelect from '../../shared/components/year-select'
  import Dropzone from '../../shared/components/dropzone'

  export default {
    components: {
      DepotSelect,
      ProductSelect,
      UnitStatusSelect,
      UnitColorSelect,
      YearSelect,
      Dropzone
    },
    props: {
      order_unit: {
        type: Object,
        required: true
      },
      order: {
        type: Object,
        required: true
      },
      onSave: {
        type: Function
      }
    },
    data () {
      return {
        print: false,
        record: {},
        suggestions: [],
        unit: {
          product: null
        },
        trace_data: {},
        config: {
          altInput: true,
          altFormat: 'F j, Y at h:i K',
          dateFormat: 'Z',
          enableTime: true
        }
      }
    },
    computed: mapState([ 'user' ]),
    created () {
      this.record = this.order_unit
      if (this.record.unit) {
        this.unit = this.record.unit
      }
      this.unit.depot = this.unit.depot || this.order.depot
      this.record.trace_data = this.trace_data
    },
    methods: {
      // setUnit (unit) {
      //   this.unit = unit
      //   this.unit.status = 'pending_inspection'
      // },
      // debounceUnitSearch () {
      //   if (this.searchTimeout) clearTimeout(this.searchTimeout)
      //   this.searchTimeout = setTimeout(this.lookupUnit, 250)
      // },
      // lookupUnit () {
      //   this.$store.dispatch('fetchRecords', [ 'unit', {
      //     number: this.unit.number,
      //     status: 'released'
      //   }]).then(records => {
      //     this.suggestions = records
      //   })
      // },
      validateAndSave () {
        this.$validator.validateAll().then(result => {
          if (result) {
            return this.save()
          }
          this.$store.commit('error.validation', this.errors)
        })
      },
      save () {
        let unitToSave = {
          id: this.unit.id,
          number: this.unit.number,
          depot: this.unit.depot,
          product: this.unit.product,
          status: this.unit.status,
          color: this.unit.color,
          year_of_manufacture: this.unit.year_of_manufacture,
          photos: this.unit.photos,
          notes: this.unit.notes
        }
        this.record.accepted_by_user = this.user
        this.$store.dispatch('saveRecord', [ 'unit', unitToSave ]).then(unit => {
          this.unit.id = unit.id
          this.record.unit = unit
          this.$store.dispatch('saveRecord', [ 'order_unit', this.record ]).then(record => {
            this.$buefy.toast.open({
              message: 'Unit Gated In',
              type: 'is-success'
            })
            if (typeof(this.$parent.close) === 'function') this.$parent.close()
            if (this.onSave) {
              this.onSave(record, {
                print: this.print
              })
            }
          })
        })
      }
    }
  }
</script>
