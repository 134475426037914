<template>
  <div class="trace">
    <slot name="name">
      <template v-if="trace.user">
        {{ trace.user.first_name }}
        {{ trace.user.last_name }}
      </template>
      <i v-else>
        Somebody
      </i>
    </slot>
    <slot>
      default content
    </slot>
    <slot name="timestamp">
      at {{ trace.timestamp | formattedDateTime }}.
    </slot>
  </div>
</template>

<script>
  import filters from 'shared/filters'
  export default {
    filters,
    props: {
      trace: {
        type: Object,
        required: true
      }
    }
  }
</script>
