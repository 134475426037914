<template>
  <div class="dropzone"></div>
</template>

<script>
  import S3 from 'shared/data-service/s3'
  import Dropzone from 'dropzone'
  Dropzone.autoDiscover = false
  export default {
    props: {
      value: [ String, Array ]
    },
    mounted () {
      let vm = this
      this.dropzone = new Dropzone(this.$el, {
        addRemoveLinks: true,
        resizeWidth: 1200,
        url: '#', // set with each file
        method: 'put',
        parallelUploads: 1,
        uploadMultiple: true,
        autoProcessQueue: false,
        // Hijack the xhr.send since Dropzone always upload file by using formData
        // ref: https://github.com/danialfarid/ng-file-upload/issues/743
        sending (file, xhr) {
          let _send = xhr.send
          xhr.send = () => {
            _send.call(xhr, file)
          }
        },
        accept (file, done) {
          S3.presign({
            filename: file.name,
            content_type: file.type
          }).then(url => {
            file.uploadURL = url
            done()
            vm.dropzone.processFile(file)
          }).catch(error => {
            console.warn(error)
            done('Failed to get an S3 signed upload URL', error)
          })
        }
      })
      this.dropzone.on('processing', (file) => {
        this.dropzone.options.url = file.uploadURL
      })
      this.dropzone.on('success', () => {
        let urls = this.value || []
        urls = urls.concat(this.dropzone.getAcceptedFiles().map(i => {
          return i.uploadURL.split('?')[0]
        }))
        this.$emit('input', urls)
      })
    }
  }
</script>

<style lang="scss">
  @import '~dropzone/dist/dropzone';
  .dropzone {
    transition: all 0.2s linear;
    border: 1px dashed #ddd;
    background-color: #fafafa;
    min-height: initial;
    padding: 8px 13px;
    &:hover {
      border-color: #bbb;
      background-color: white;
      .dz-message {
        .dropzone-title {
          color: #3498db;
        }
      }
    }

    .dz-preview .dz-success-mark,
    .dz-preview .dz-error-mark {
      margin-top: -35px;
    }

    .dz-preview.dz-image-preview,
    .dz-preview {
      background: #ecf5fc;
    }
    .dz-preview {
      margin: 8px 30px 8px 0;
      &:nth-child(5n) {
        margin-right: 0;
      }
      .dz-image {
        border-radius: 4px 4px 0 0 ;
      }
    }
    .dz-message {
      color: #666;
      span {
        line-height: 1.8;
        font-size: 13px;
        letter-spacing: 0.4px;
        span.dropzone-title {
          display: block;
          color: #888;
          font-size: 1.25em;
        }
        span.dropzone-info {
          display: block;
          color: #a8a8a8;
        }
      }
    }
  }
</style>
