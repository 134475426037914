<template>
  <section v-if="record" class="section unit-detail">

    <div v-if="record.deleted_at" class="notification is-danger">
      This unit has been deleted.
    </div>

    <!-- ACTIONS -->
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">

          <router-link to="/inventory" class="navbar-item">
            <span class="mdi mdi-chevron-left"></span>&nbsp; Inventory
          </router-link>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div class="field has-addons">
            <div class="control">
              <button class="button" @click="editRecord">
                <span class="icon icon-medium"><i class="mdi mdi-file-document-edit-outline mdi-18px"></i></span>
                <span>Edit</span>
              </button>
            </div>
            <div v-if="is_admin" class="control">
              <button v-if="record.deleted_at" class="button" @click="recoverRecord">
                <span class="icon icon-medium"><i class="mdi mdi-delete-sweep-outline mdi-18px"></i></span>
                <span>Recover</span>
              </button>
              <button v-else class="button" @click="deleteRecord">
                <span class="icon icon-medium"><i class="mdi mdi-delete-forever-outline mdi-18px"></i></span>
                <span>Delete</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- BOX -->
    <section class="box is-relative">

      <unit-status :record="record" />

      <div class="columns">
        <div class="column is-narrow">
          <p class="heading">Record Number</p>
          <h5 class="title is-5" data-attribute="number">{{ record.number }}</h5>
        </div>

        <div class="column is-narrow" v-if="record.product">
          <p class="heading">Depot</p>
          <h5 class="title is-5" data-attribute="depot">{{ record.depot ? record.depot.name : '' }}</h5>
        </div>

        <div class="column is-narrow" v-if="record.product">
          <p class="heading">Product Code</p>
          <h5 class="title is-5" v-if="record.product.code" data-attribute="product.code">{{ record.product.code }}</h5>
        </div>
        <div class="column is-narrow" v-if="record.product">
          <p class="heading">Condition</p>
          <h5 class="title is-5" v-if="record.product.condition" data-attribute="product.condition">{{ record.product.condition }}</h5>
        </div>
      </div>


      <!-- DETAILS -->

      <div class="b-table">
        <div class="table-wrapper">
          <table class="table is-striped is-narrow has-mobile-cards is-inbox">

            <tr>
              <th width="200" class="is-hidden-mobile">
                Fleet Status:
              </th>
              <td data-attribute="fleet-status" data-label="Fleed Status">
                <span class="tag" :class="record.fleet_status">{{ record.fleet_status | titleize }}</span>
              </td>
            </tr>
            <tr>
              <th width="200" class="is-hidden-mobile">
                Color:
              </th>
              <td data-label="Color">
                <div v-if="record.color" class="color-swatch-wrapper">
                  <span class="color-swatch" :class="record.color | colorClassName"></span>
                  <span data-attribute="color">{{ record.color }}</span>
                </div>
              </td>
            </tr>
            <template v-if="record.product">
              <tr>
                <th width="200" class="is-hidden-mobile">
                  Product Type:
                </th>
                <td data-attribute="product.classification" data-label="Product Type">{{ record.product.classification | titleize }}</td>
              </tr>
              <tr>
                <th width="200" class="is-hidden-mobile">
                  Product Description:
                </th>
                <td data-attribute="product.name" data-label="Product Description">{{ record.product.name | titleize }}</td>
              </tr>
              <tr>
                <th width="200" class="is-hidden-mobile">
                  Product Class:
                </th>
                <td data-attribute="product.class" data-label="Product Class">{{ record.product_class | titleize }}</td>
              </tr>
              <tr>
                <th width="200" class="is-hidden-mobile">
                  Product Family:
                </th>
                <td data-attribute="product.family" data-label="Product Family">{{ record.product.family | titleize }}</td>
              </tr>
              <tr>
                <th width="200" class="is-hidden-mobile">
                  Product Rental Code:
                </th>
                <td data-attribute="product.rental_code" data-label="Product Rental Code">{{ record.product.rental_code }}</td>
              </tr>
            </template>
            <tr>
              <th width="200" class="is-hidden-mobile">
                Year Made:
              </th>
              <td data-attribute="year-of-manufacture" data-label="Year Made">{{ record.year_of_manufacture }}</td>
            </tr>
            <tr>
              <th width="200" class="is-hidden-mobile">
                Open Side:
              </th>
              <td data-attribute="open-side" data-label="Open Side">{{ record.open_side | titleize }}</td>
            </tr>
            <tr>
              <th width="200" class="is-hidden-mobile">
                Cost:
              </th>
              <td data-attribute="cost" data-label="Cost">{{ record.cost | currency }}</td>
            </tr>
            <tr>
              <th width="200" class="is-hidden-mobile">
                Inventory Confirmed At:
              </th>
              <td data-attribute="confirmed-at" data-label="Confirmed at">{{ record.confirmed_at | formattedDateTime }}</td>
            </tr>
            <tr>
              <th width="200" class="is-hidden-mobile">
                Acquired At:
              </th>
              <td data-attribute="acquired-at" data-label="Acquired at">{{ record.acquired_at | formattedDate }}</td>
            </tr>
            <tr>
              <th width="200" class="is-hidden-mobile">
                Specialty Modification:
              </th>
              <td data-attribute="data.specialty_modification" data-label="Specialty Modification">{{ record.data.specialty_modification | yesOrNo }}</td>
            </tr>
            <tr>
              <th width="200" class="is-hidden-mobile">
                DVT:
              </th>
              <td data-attribute="data.dvt" data-label="DVT">{{ record.data.dvt | yesOrNo }}</td>
            </tr>
            <tr>
              <th width="200" class="is-hidden-mobile">
                Has Lockbox:
              </th>
              <td data-attribute="has-lockbox" data-label="Has Lockbox">
                <span v-if="record.has_lockbox !== null">
                  {{ record.has_lockbox | yesOrNo }}
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </section>

    <section class="box">
      <h6 class="title is-6">Notes</h6>
      <div class="pre-wrapper">
        <pre data-attribute="notes">{{ record.notes }}</pre>
      </div>
    </section>

    <section class="box">
      <div v-if="record.order_units.length">
        <h5 class="title is-5">Unit History</h5>
        <template v-if="record.traces.length > 0">
          <traces :traces="record.traces" :record="record" />
        </template>
        <template v-else>
          <article class="message is-warning">
            <div class="message-body">
              No History
            </div>
          </article>
        </template>
      </div>
    </section>

    <section  v-if="record.order_units.length" class="box">
      <h5 class="title is-5">Unit Assignment</h5>
      <div class="b-table">
        <div class="table-wrapper">
          <table class="table is-striped is-narrow has-mobile-cards">
            <thead>
              <tr>
                <th>Id</th>
                <th>Type</th>
                <th>Depot</th>
                <th>Arrived</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order_unit in record.order_units">
                <template v-if="order_unit.order">
                  <td data-label="ID">
                    <router-link :to="{ name: order_unit.order.order_type + '-order', params: { id: order_unit.order.id }}">{{ order_unit.order.reference }}</router-link>
                  </td>
                  <td data-label="Type">
                    {{ order_unit.order.order_type | titleize }}
                  </td>
                  <td data-label="Depot">
                    <span class="tag" v-if="order_unit.order.depot">{{ order_unit.order.depot.name }}</span>
                  </td>
                  <td data-label="Arrived">
                    {{ arrivedOrReleasedAt(order_unit) }}
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <unit-photo-gallery :unit="record" />

  </section>

  <loader v-else />

</template>

<script>
  import { mapState } from 'vuex'
  import filters from 'shared/filters'
  import UserHelper from 'shared/mixins/user-helper'
  import AsyncRecord from 'shared/mixins/async-record'
  import Loader from 'shared/components/loader'
  import UnitStatus from './status'
  import UnitPhotoGallery from './photo-gallery'
  import UnitForm from './form'
  import Traces from 'shared/components/traces'
  export default {
    filters,
    mixins: [ UserHelper, AsyncRecord ],
    components: {
      Loader,
      UnitStatus,
      UnitPhotoGallery,
      Traces
    },
    data () {
      return {
        asyncRecordOptions: {
          type: 'unit',
          options: { include: 'depot,product,order_units,order_units.order,order_units.order.depot,traces,traces.user,traces.order' }
        }
      }
    },
    methods: {
      arrivedOrReleasedAt (order_unit) {
        if (order_unit.order.order_type === 'incoming') {
          if (order_unit.arrived_at) return `Arrived ${filters.formattedDateTime(order_unit.arrived_at)}`
          else return 'Not yet arrived'
        } else {
          if (order_unit.released_at) return `Released ${filters.formattedDateTime(order_unit.released_at)}`
          else return 'Not yet released'
        }
      },
      editRecord () {
        this.$buefy.modal.open({
          parent: this,
          component: UnitForm,
          props: {
            record: { ...this.record },
            onSave: record => {
              this.fetchRecord()
            }
          }
        })
      },
      deleteRecord () {
        this.$buefy.dialog.confirm({
          title: 'Deleting Unit',
          message: 'Are you sure you want to <b>delete</b> this unit?',
          confirmText: 'Delete Unit',
          type: 'is-danger',
          onConfirm: () => {
            this.$store.dispatch('deleteRecord', [ 'unit', this.record ]).then(() => {
              this.$router.push('/inventory')
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .photo-column {
    position: relative;
  }
  .delete-x {
    display: block;
    position: absolute;
    right: 0;
    top: -7px;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background: #000;
    color: #fff;
    text-align: center;
    line-height: 16px;
  }
</style>
