<template>
  <div>

    <template v-if="records.length === 0">
      <article class="message is-warning">
        <div class="message-body">
          No modifications.
        </div>
      </article>
    </template>

    <b-table v-else
      detailed
      :data="records">

      <template slot-scope="props">

        <b-table-column field="product" label="Product">
          <span v-if="props.row.product" :title="props.row.product.name">
            {{ props.row.product.code }}
          </span>
        </b-table-column>

        <b-table-column field="product" label="Unit">
          <span v-if="props.row.unit" :title="props.row.unit.number">
            <router-link :to="{ name: 'unit', params: { id: props.row.unit.id }}">
              {{ props.row.unit.number }}
            </router-link>
          </span>
          <span v-else>
            Unassigned
          </span>
        </b-table-column>

        <b-table-column field="status" label="Status">
          <modification-status :record="props.row" />
        </b-table-column>

        <b-table-column field="uploads" label="Spec">
          <template v-for="url in props.row.uploads">
            <a :href="url" target="_blank" class="is-size-7">
              {{ url | basename }}
            </a>
            <a @click="deleteUpload(props.row, url)" class="is-danger">&times;</a>
          </br/>
          </template>
        </b-table-column>

        <b-table-column field="actions">

          <div class="field has-addons is-marginless is-pulled-right">
            <div class="control">
              <button @click="$emit('edit', props.row)" class="button">
                <span class="icon icon-medium"><i class="mdi mdi-file-document-edit-outline mdi-18px"></i></span>
                <span>Edit</span>
              </button>
            </div>
            <div class="control">
              <button @click="$emit('delete', props.row)" class="button">
                <span class="icon icon-medium"><i class="mdi mdi-delete-forever-outline mdi-18px"></i></span>
                <span>Remove</span>
              </button>
            </div>
          </div>

        </b-table-column>

      </template>

      <template slot="detail" slot-scope="props">
        <h6 class="title is-6">Notes</h6>
        <div class="pre-wrapper">
          <pre data-attribute="notes">{{ props.row.notes }}</pre>
        </div>

        <br/>

        <h4 class="title is-4">Preview PDFs</h4>
        <div v-for="url in props.row.uploads">
          <template v-if="isPdf(url)">
            <h6 class="title is-6">
              <a :href="url" target="_blank">
                {{ url | basename }}
              </a>
            </h6>
            <embed :src="url" type="application/pdf" width="100%" style="height: 600px; background-color: #efefef;" />
            <a :href="url" target="_blank" class="is-size-6">
              Download
            </a>
            <hr/>
          </template>
        </div>

      </template>

    </b-table>

  </div>
</template>

<script>
  import filters from 'shared/filters'
  import UserHelper from 'shared/mixins/user-helper'
  import ModificationStatus from './modification-status'
  export default {
    filters,
    components: {
      ModificationStatus
    },
    mixins: [ UserHelper ],
    props: {
      records: {
        type: Array,
        required: true
      }
    },
    methods: {
      isPdf (url) {
        return url.endsWith('.pdf')
      },
      deleteUpload (modification, url) {
        this.$buefy.dialog.confirm({
          title: 'Deleting Upload',
          message: 'Are you sure you want to <b>delete</b> this upload?',
          confirmText: 'Delete Upload',
          type: 'is-danger',
          onConfirm: () => {
            const index = modification.uploads.indexOf(url)
            modification.uploads.splice(index, 1)
            modification.order = this.record
            this.$store.dispatch('saveRecord', [ 'modification', modification, { include: 'order,product,unit' } ])
          }
        })
      }
    }
  }
</script>

<style>
  .order-detail .table-wrapper {
    overflow: visible;
  }
</style>
