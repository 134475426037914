<template>
  <transition name="modal">
    <div class="modal-mask" >
      <div class="modal-wrapper" @click.self="$emit('close')">
        <div class="modal-container" v-bind:style="modalContainerStyle">

          <a class="modal-close" @click="$emit('close')">&times;</a>

          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer" v-if="!hideFooter">
            <slot name="footer">
              <button class="modal-default-button button-outline" @click.prevent="$emit('close')">
                OK
              </button>
            </slot>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'modal',
    props: [ 'containerStyle', 'hideFooter' ],
    mounted () {
      document.body.classList.add('modal-active')
      window.addEventListener('keyup', this.handleKeyup)
      this.$emit('opened')
    },
    destroyed () {
      // document.body.style.overflow = 'scroll'
      document.body.classList.remove('modal-active')
      window.removeEventListener('keyup', this.handleKeyup)
      this.$emit('closed')
    },
    computed: {
      modalContainerStyle () {
        return this.containerStyle || {}
      }
    },
    methods: {
      handleKeyup (e) {
        if (e.keyCode === 27) {
          if (this.$store.state.error) return
          this.$emit('close')
        }
      }
    }
  }
</script>
