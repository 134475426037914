<template>
  <div>
    <draggable v-model="unit.photos" @end="save()" class="columns is-multiline sortable">
      <div class="column is-one-quarter" v-for="photo in unit.photos">
        <div class="card is-rounded">
          <div class="card-image">
            <figure class="image column photo-column">
              <a @click="preview(photo)">
                <img :src="photo | medium" width="100%" />
              </a>
            </figure>
          </div>
          <footer class="card-footer">
            <a @click="deletePhoto(photo)" class="card-footer-item">Delete</a>
            <a :href="photo" class="card-footer-item">Download</a>
          </footer>
        </div>
      </div>
    </draggable>
    <b-modal :active.sync="isPreviewVisible">
      <img :src="previewPhoto" width="100%">
    </b-modal>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  const photoSizeUrl = (url, size) => {
    return url.replace('/uploads/', `/uploads/resized/${size}/`).replace('.jpeg', '.jpg')
  }
  export default {
    props: [ 'unit' ],
    components: {
      draggable
    },
    filters: {
      medium (url) {
        return photoSizeUrl(url, 'medium')
      },
      large (url) {
        return photoSizeUrl(url, 'large')
      }
    },
    data () {
      return {
        isPreviewVisible: false,
        previewPhoto: null
      }
    },
    methods: {
      preview (photo) {
        this.previewPhoto = photoSizeUrl(photo, 'large')
        this.isPreviewVisible = true
      },
      deletePhoto (photo) {
        let idx = this.unit.photos.findIndex(i => i === photo)
        this.unit.photos.splice(idx, 1)
        this.save()
      },
      save () {
        this.$store.dispatch('saveRecord', [ 'unit', this.unit, { include: 'depot,product' } ])
      }
    }
  }
</script>

<style scoped>
  .sortable img {
    cursor: move;
  }
</style>
