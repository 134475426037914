<template>
  <b-field label="Product Select">
    <b-select v-model="localValue" @input="update" data-label="product-select" icon="texture" expanded>
      <option :value="null"></option>
      <option v-if="products.length === 0" disabled>loading...</option>
      <option v-else v-for="product in filteredProducts" :value="product">
        {{ product.code }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
  export default {
    props: {
      value: Object,
      id: String,
      rentalCode: String,
      fleetStatus: String
    },
    data () {
      return {
        localValue: this.value,
        products: []
      }
    },
    computed: {
      filteredProducts () {
        let products = this.products
        if (this.fleetStatus) {
          const classification = this.fleetStatus === 'rental' ? 'rental' : 'sales'
          products = products.filter(i => i.classification === classification)
        }
        return products
      }
    },
    created () {
      this.$store.dispatch('fetchRecords', [ 'product', {
        sort: 'code',
        filter: {
          product_class: [ 'CONTAINERS', 'CONTAINER RENTAL', 'FLAT RACK' ]
        },
        page: {
          number: 1,
          size: 999
        }
      }]).then(products => {
        this.products = products
        if (this.id) {
          this.localValue = this.products.find(i => i.id === this.id)
        }
      })
    },
    methods: {
      update () {
        this.$emit('input', this.localValue)
      }
    }
  }
</script>
