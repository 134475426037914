import DateTimePicker from './date-time-picker.vue'

const Plugin = (Vue, params) => {
  let name = 'date-time-picker'
  if (typeof params === 'string') name = params

  Vue.component(name, DateTimePicker)
};

DateTimePicker.install = Plugin

export default DateTimePicker
export {DateTimePicker, Plugin}
