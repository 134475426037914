<template>
  <div v-if="record" class="transfer-detail">
    <section class="section">

      <!-- ACTIONS -->
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <router-link to="/transfers" class="navbar-item">
              <span class="mdi mdi-chevron-left"></span>&nbsp; Transfers
            </router-link>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="field has-addons">
              <div class="control">
                <button class="button" @click="editRecord()">
                  <span class="icon icon-medium"><i class="mdi mdi-file-document-edit-outline mdi-18px"></i></span>
                  <span>Edit</span>
                </button>
              </div>

              <div class="control">
                <a class="button" v-if="is_admin" @click="deleteRecord()">
                  <span class="icon icon-medium"><i class="mdi mdi-delete-forever-outline mdi-18px"></i></span>
                  <span>Delete</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box is-relative">

        <transfer-status :record="record" />

        <div class="columns">
          <div class="column">
            <p class="heading">Transfer ID</p>
            <h4 class="title is-5">{{ record.reference }}</h4>
          </div>
          <div class="column">
            <p class="heading">Product Code</p>
            <h4 class="title is-5" data-attribute="product.code">
              <span v-if="record.product">{{ record.product.code }}</span>
              <span v-else>n/a</span>
            </h4>
          </div>
          <div class="column">
            <p class="heading">Unit Count</p>
            <h4 class="title is-5" data-attribute="unit-count">
              {{ record.unit_count }}
            </h4>
          </div>
        </div>
      </div>

      <div class="box">
        <h6 class="title is-6">Notes</h6>
        <div class="pre-wrapper">
          <pre data-attribute="notes">{{ record.notes }}</pre>
        </div>
      </div>

      <div class="box">
        <div class="b-table">
          <div class="table-wrapper">
            <table class="table is-striped is-narrow has-mobile-cards is-fullwidth">
              <thead>
                <tr>
                  <th>Source Depot</th>
                  <th>Release</th>
                  <th>Destination Depot</th>
                  <th>Incoming Order</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Source Depot"><span class="tag" data-attribute="source-depot.name">{{ record.source_depot.name }}</span></td>
                  <td data-label="Releases">
                    <router-link :to="{ name: 'outgoing-order', params: { id: record.outgoing_order.id }}" data-attribute="outgoing-order.reference">
                      {{ record.outgoing_order.reference }}
                    </router-link>
                  </td>
                  <td data-label="Destination"><span class="tag" data-attribute="destination-depot.name">{{ record.destination_depot.name }}</span></td>
                  <td data-label="Incoming Order">
                    <router-link :to="{ name: 'incoming-order', params: { id: record.incoming_order.id }}" data-attribute="incoming-order.reference">
                      {{ record.incoming_order.reference }}
                    </router-link>
                  </td>
                  <td data-attribute="created-at" data-label="Created">
                    {{ record.created_at | formattedDateTime }}
                    <div v-if="record.created_by_user" data-attribute="created-by-user.name">by {{ record.created_by_user.first_name }} {{ record.created_by_user.last_name }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </section>

  </div>

  <loader v-else />

</template>

<script>
  import filters from 'shared/filters'
  import UserHelper from 'shared/mixins/user-helper'
  import AsyncRecord from 'shared/mixins/async-record'
  import Loader from 'shared/components/loader'
  import TransferStatus from './status'
  import TransferForm from './form'
  export default {
    filters,
    mixins: [ UserHelper, AsyncRecord ],
    components: {
      Loader,
      TransferStatus
    },
    data () {
      return {
        asyncRecordOptions: {
          type: 'transfer',
          options: {
            include: [
              'created_by_user',
              'product',
              'source_depot',
              'destination_depot',
              'outgoing_order',
              'incoming_order'
            ].join(',')
          }
        },
        transfer: null
      }
    },
    methods: {
      editRecord () {
        this.$buefy.modal.open({
          parent: this,
          component: TransferForm,
          props: {
            record: Object.assign({}, this.record),
            onSave: record => {
              this.fetchRecord()
            }
          }
        })
      },
      deleteRecord () {
        this.$buefy.dialog.confirm({
          title: 'Deleting Transfer',
          message: 'Are you sure you want to <b>delete</b> this transfer?',
          confirmText: 'Delete Transfer',
          type: 'is-danger',
          onConfirm: () => {
            this.$store.dispatch('deleteRecord', [ 'transfer', this.record ]).then(() => {
              this.$router.push('/transfers')
            })
          }
        })
      }
    }
  }
</script>
