<template>
  <section class="section outgoing-orders">

    <div class="level is-mobile">
      <div class="level-item level-left">
        <h1 class="title is-size-4-mobile">Releases</h1>
      </div>
      <div v-if="!user_is_role('depot')" class="level-item level-right">
        <button @click="newRecord()" class="button is-primary is-outlined">
          <span class="icon icon-medium"><i class="mdi mdi-clipboard-arrow-up-outline mdi-18px"></i></span>
          <span>New Release</span>
        </button>
      </div>
    </div>

    <order-filters :statusOptions="statuses" />
    <order-table />

  </section>
</template>

<script>
  import userHelper from 'shared/mixins/user-helper'
  import OrderFilters from '../../shared/components/order-filter'
  import OrderTable from './table'
  import OrderForm from './form'
  import { outgoingOrderStatuses } from 'shared/statuses'
  export default {
    mixins: [ userHelper ],
    components: {
      OrderFilters,
      OrderTable
    },
    data () {
      return {
        statuses: outgoingOrderStatuses
      }
    },
    methods: {
      newRecord () {
        this.$buefy.modal.open({
          parent: this,
          component: OrderForm,
          props: {
            onSave: record => {
              this.$router.push({ name: 'outgoing-order', params: { id: record.id }})
            }
          }
        })
      }
    }
  }
</script>
