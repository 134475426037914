export const colors = {
  "Unspecified": 0,
  "RAL1015 - Light Ivory": 1,
  "RAL1001 - Desert Tan": 2,
  "RAL1027 - Harvest Gold": 11,
  "RAL9003 - White": 3,
  "RAL7005 - Mouse Grey": 14,
  "RAL7015 - Slate Grey": 5,
  "RAL7042 - Light Grey": 4,
  "RAL5010 - Blue": 6,
  "RAL2000 - Yellow Orange": 7,
  "RAL3000 - Flame Red": 8,
  "RAL3001 - Brick Red": 12,
  "RAL3002 - Maroon": 13,
  "RAL6025 - Fern Green": 9,
  "Other": 10
}
