<template>
  <section class="section transfers">

    <div class="level is-mobile">
      <div class="level-item level-left">
        <h1 class="title is-size-4-mobile">Transfers</h1>
      </div>
      <div class="level-item level-right">
        <button @click="newRecord()" class="button is-primary is-outlined">
          <span class="icon icon-medium"><i class="mdi mdi-clipboard-flow mdi-18px"></i></span>
          <span>New Transfer</span>
        </button>
      </div>

    </div>

    <transfer-filters />
    <transfer-table />

  </section>
</template>

<script>
  import userHelper from 'shared/mixins/user-helper'
  import depotFilter from 'shared/mixins/depot-filter'
  import TransferFilters from './filters'
  import TransferTable from './table'
  import TransferForm from './form'
  export default {
    mixins: [ userHelper, depotFilter ],
    components: {
      TransferFilters,
      TransferTable
    },
    methods: {
      newRecord () {
        this.$buefy.modal.open({
          parent: this,
          component: TransferForm,
          props: {
            onSave: record => {
              this.$router.push({ name: 'transfer', params: { id: record.id }})
            }
          }
        })
      }
    }
  }
</script>
