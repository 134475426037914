<template>
  <div>

    <b-message v-if="!records.length">
      No Results
    </b-message>

    <b-table v-else
      :data="records"
      :striped="true"
      :narrowed="true">

      <template slot-scope="props">

        <b-table-column field="number" label="Serial Number" sortable>
          <h6 class="title is-6 is-marginless is-whitespacenowrap">
            <router-link :to="{ name: 'unit', params: { id: props.row.id }}" data-attribute="number">{{ props.row.number }}</router-link>
          </h6>
          <!-- <h6 v-if="props.row.order_units.length === 0" class="has-text-grey is-size-7">Unassigned</h6> -->
          <!-- <router-link v-else :to="{ name: `${props.row.order_units[0].order.order_type}-order`, params: { id: props.row.order_units[0].order.id }}">
            <h6 class="has-text-grey is-size-7">
              {{ props.row.order_units[0].order.order_type | capitalize }}
              {{ props.row.order_units[0].order.reference }}
            </h6>
          </router-link> -->
        </b-table-column>

        <b-table-column field="status" label="Status" sortable>
          <span class="tag" :class="props.row.status" data-attribute="status">{{ props.row.status | titleize }}</span>
        </b-table-column>

        <b-table-column v-if="props.row.depot" field="depot_id" label="Depot" sortable>
          <span class="tag" data-attribute="depot.name">{{ props.row.depot.name }}</span>
        </b-table-column>

        <b-table-column field="fleet_status" label="Fleet Status" sortable>
          <span class="tag" :class="props.row.fleet_status" data-attribute="fleet-status">{{ props.row.fleet_status | titleize }}</span>
        </b-table-column>

        <b-table-column field="product.code" label="Product">
          <p class="is-whitespacenowrap" data-attribute="product-code">{{ props.row.product.code }}</p>
        </b-table-column>

        <b-table-column field="color" label="Color" sortable>
          <div v-if="props.row.color" class="color-swatch-wrapper">
            <span class="v-autocomplete-preview-status color-swatch" :class="props.row.color | colorClassName"></span>
            <span class="color" data-attribute="color">{{ props.row.color }}</span>
          </div>
        </b-table-column>

        <b-table-column field="confirmed_at" label="Confirmed" width="116" sortable>
          <p class="is-whitespacenowrap">
            <span v-if="props.row.confirmed_at">
              {{ props.row.confirmed_at | formattedDateTime }}
            </span>
            <span v-else>
              N/A
            </span>
          </p>
        </b-table-column>

      </template>

    </b-table>

  </div>
</template>

<script>
  import filters from 'shared/filters'
  export default {
    filters,
    props: {
      records: {
        type: Array,
        required: true
      }
    }
  }
</script>
