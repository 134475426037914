<template>
  <div>
    <b-table
      :data="records"
      :loading="loading"
      :current-page="meta.page"
      :total="meta.record_count"
      :per-page="50"
      :striped="true"
      :narrowed="true"
      paginated
      backend-pagination
      backend-sorting
      @sort="onSort"
      @page-change="onPageChange">

      <template slot-scope="props" slot="header">
        <b-tooltip v-if="props.column.meta" :active="!!props.column.meta" :label="props.column.meta" dashed>
          {{ props.column.label }}
        </b-tooltip>
        <template v-else>
          {{ props.column.label }}
        </template>
      </template>

      <template slot-scope="props">

        <b-table-column field="reference" label="Reference" sortable>
          <h6 class="title is-6 is-whitespacenowrap">
            <router-link :to="{ name: 'incoming-order', params: { id: props.row.id }}">{{ props.row.reference }}</router-link>
          </h6>
          <!-- <h6 v-if="props.row.notes" class="subtitle is-size-7 has-text-grey">{{ props.row.notes }}</h6> -->
        </b-table-column>

        <b-table-column field="status" label="Status" sortable>
          <order-status :record="props.row" />
        </b-table-column>

        <b-table-column field="depot_id" label="Depot">
          <span v-if="props.row.depot" class="tag" data-attribute="depot.name">{{ props.row.depot.name }}</span>
        </b-table-column>

        <b-table-column field="data" label="Supplier" sortable>
          <span v-if="props.row.data.supplier" class="tag" data-attribute="data.supplier">{{ props.row.data.supplier }}</span>
        </b-table-column>

        <b-table-column field="expected_at" label="Expected" sortable>
          <p class="is-whitespacenowrap" data-attribute="expected-at">
            {{ props.row.expected_at | formattedDate }}
          </p>
        </b-table-column>

        <b-table-column field="expires_at" label="Expires" sortable>
          <p class="is-whitespacenowrap" data-attribute="expires-at">
            {{ props.row.expires_at | formattedDate }}
          </p>
        </b-table-column>

        <b-table-column field="unit_count" label="Tot" meta="Total" sortable>
          <span class="count" data-attribute="total-count">{{ props.row.unit_count }}</span>
        </b-table-column>

        <b-table-column field="assigned" label="Asgn" meta="Assigned">
          <span class="count" data-attribute="assigned-count">{{ props.row.order_units.length }}</span>
        </b-table-column>

        <b-table-column field="delivered" label="Del" meta="Delivered">
          <span class="count" data-attribute="delivered-count">{{ deliveredCount(props.row) }}</span>
        </b-table-column>

        <b-table-column field="remaining" label="Rem" meta="Remaining">
          <span class="count" data-attribute="remaining-count">{{ remainingCount(props.row) }}</span>
        </b-table-column>

      </template>
    </b-table>
  </div>
</template>

<script>
  import api from 'shared/json-api'
  import asyncTable from '../../mixins/async-table'
  import OrderStatus from './status'
  export default {
    mixins: [ asyncTable ],
    components: {
      OrderStatus
    },
    data () {
      return {
        defaultFilter: {
          order_type: 'incoming'
        }
      }
    },
    methods: {
      deliveredCount (record) {
        return record.order_units.filter(i => i.arrived_at).length
      },
      remainingCount (record) {
        return parseInt(record.unit_count) - this.deliveredCount(record)
      },
      loadAsyncData () {
        this.loading = true
        api.findAll('order', {
          sort: this.sortParam,
          include: 'depot,product,order_units',
          page: {
            number: this.meta.page
          },
          filter: this.applyRouteFilter()
        }).then(response => {
          this.handleResponse(response)
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
</script>
