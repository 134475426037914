<template>
  <div>

    <b-message v-if="!records.length">
      No Results
    </b-message>

    <b-table v-else
      :data="records"
      :striped="true"
      :narrowed="true">

      <template slot-scope="props">

        <b-table-column field="reference" label="Reference">
          <h6 class="title is-6 is-whitespacenowrap">
            <router-link :to="{ name: 'incoming-order', params: { id: props.row.id }}">{{ props.row.reference }}</router-link>
          </h6>
          <!-- <h6 v-if="props.row.notes" class="subtitle is-size-7 has-text-grey">{{ props.row.notes }}</h6> -->
        </b-table-column>

        <b-table-column field="status" label="Status">
          <span class="tag" :class="props.row.status" data-attribute="depot.name">{{ props.row.status | titleize }}</span>
        </b-table-column>

        <b-table-column field="depot" label="Depot">
          <span v-if="props.row.depot" class="tag" data-attribute="depot.name">{{ props.row.depot.name }}</span>
          <span v-else>N/A</span>
        </b-table-column>

        <b-table-column field="expected_at" label="Expected">
          <p class="is-whitespacenowrap"><b data-attribute="expected-at">{{ props.row.expected_at | formattedDate }}</b></p>
        </b-table-column>

        <b-table-column field="expires_at" label="Expires">
          <p class="is-whitespacenowrap"><b data-attribute="expires-at">{{ props.row.expires_at | formattedDate }}</b></p>
        </b-table-column>

        <b-table-column field="unit_count" label="Unit Count">
          {{ props.row.unit_count }}
        </b-table-column>

      </template>
    </b-table>

  </div>
</template>

<script>
  import filters from 'shared/filters'
  export default {
    filters,
    props: {
      records: {
        type: Array,
        required: true
      }
    }
  }
</script>
