<template>
  <div @click.self="close()" class="error-messages">
    <div class="notification is-danger">
      <button @click="close()" class="delete" aria-label="delete"></button>
      <h4 class="title is-4">Oops!</h4>
      <ul v-if="error.type === 'request'">
        <li v-for="(error, attribute) in error.object">
          {{ formattedErrorMessage(attribute, error) }}
        </li>
      </ul>
      <ul v-else-if="error.type === 'validation'">
        <li v-for="item in error.object.items">
          {{ item.msg }}
        </li>
      </ul>
      <button @click="close()" class="button is-danger is-outlined is-inverted is-fullwidth">OK</button>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { humanize } from 'inflected'
  const INITIAL_CARET = /^\^/
  export default {
    computed: mapState([ 'error' ]),
    mounted() {
      document.body.style.overflow = 'hidden'
      window.addEventListener('keyup', this.close)
    },
    destroyed() {
      document.body.style.overflow = 'scroll'
      window.removeEventListener('keyup', this.close)
    },
    methods: {
      showErrorAttribute (error) {
        return INITIAL_CARET.test(error.title) === false
      },
      formattedErrorMessage (attribute, error) {
        let msg = ''
        if (this.showErrorAttribute(error)) {
          msg += humanize(attribute) + ' '
        }
        msg += error.title.replace(INITIAL_CARET, '')
        return msg.replace(/\.*$/, '.')
      },
      close () {
        this.$store.commit('error.dismiss')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .error-messages {
    content: "";
    z-index: 99999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px;
    background: rgba(0,0,0,0.25);
    .notification {
      position: relative;
      max-width: 500px;
      padding: 20px;
      margin: 3% auto;
      box-shadow: 0 1px 10px rgba(0,0,0,0.25);
      z-index: 99999;
      ul {
        margin: 20px 0;
        padding: 0;
      }
    }
  }
</style>
