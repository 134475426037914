import { mapState } from 'vuex'
import filters from 'shared/filters'
import UserHelper from 'shared/mixins/user-helper'
export default {
  mixins: [ UserHelper ],
  filters,
  props: {
    records: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: Boolean,
      default: true
    }
  },
  computed: mapState([ 'depot' ])
}
