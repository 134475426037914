<template>
  <!-- BREADCRUMBS -->
  <section v-if="record" class="section depot-detail">

    <div class="level">
      <div class="level-item level-left">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><router-link to="/depots"><i class="mdi mdi-chevron-left"></i> Depots </router-link></li>
            <li class="is-active"><a href="">{{ record.name }}</a></li>
          </ul>
        </nav>
      </div>
      <div class="level-item level-right" v-if="is_admin">
        <div class="field has-addons">
          <div class="control">
            <button @click="editRecord" class="button">
              <span class="icon"><i class="mdi mdi-square-edit-outline mdi-18px"></i></span>
              <span>Edit Details</span>
            </button>
          </div>
          <div class="control">
            <button @click="editAddress" class="button">
              <span class="icon"><i class="mdi mdi-home-city-outline mdi-18px"></i></span>
              <span>Edit Address</span>
            </button>
          </div>
          <div class="control">
            <button v-if="is_admin" @click="deleteRecord" class="button">
              <span class="icon"><i class="mdi mdi-delete-forever-outline mdi-18px"></i></span>
              <span>Delete</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <section class="section">
      <h1 class="title" data-attribute="name">{{ record.name }}</h1>
      <p class="subtitle"><formatted-address v-if="record.address" :record="record.address" /></p>
      <p class="subtitle" data-attribute="notes" v-if="record.notes">{{ record.notes }}</p>
      <br />

      <table class="table is-fullwidth">
        <tr>
          <th>Location</th>
          <th>Contact Name</th>
          <th>Phone</th>
          <th>Internal?</th>
        </tr>
        <tr>
          <td data-attribute="location">{{ record.location }}</td>
          <td data-attribute="contact">{{ record.contact }}</td>
          <td data-attribute="phone">{{ record.phone }}</td>
          <td data-attribute="internal">{{ record.internal ? 'Yes' : 'No' }}</td>
        </tr>
      </table>
    </section>
  </section>

  <loader v-else />

</template>

<script>
  import { mapState } from 'vuex'
  import filters from 'shared/filters'
  import FormattedAddress from 'shared/components/formatted-address'
  import UserHelper from 'shared/mixins/user-helper'
  import AsyncRecord from 'shared/mixins/async-record'
  import Loader from 'shared/components/loader'
  import DepotForm from './form'
  import AddressForm from '../../shared/components/address-form'
  export default {
    filters,
    mixins: [ UserHelper, AsyncRecord ],
    components: {
      Loader,
      FormattedAddress
    },
    data () {
      return {
        asyncRecordOptions: {
          type: 'depot',
          options: { include: 'address' }
        },
        address: null
      }
    },
    methods: {
      editRecord () {
        this.$buefy.modal.open({
          parent: this,
          component: DepotForm,
          props: {
            record: { ...this.record },
            onSave: record => {
              this.record = record
            }
          }
        })
      },
      editAddress () {
        this.$buefy.modal.open({
          parent: this,
          component: AddressForm,
          props: {
            record: Object.assign({}, this.record.address, { addressable: this.record }),
            onSave: record => {
              this.record.address = record
            }
          }
        })
      },
      deleteRecord () {
        this.$buefy.dialog.confirm({
          title: 'Deleting Depot',
          message: 'Are you sure you want to <b>delete</b> this depot?',
          confirmText: 'Delete Depot',
          type: 'is-danger',
          onConfirm: () => {
            this.$store.dispatch('deleteRecord', [ 'depot', this.record ]).then(() => {
              this.$router.push('/depots')
            })
          }
        })
      }
    }
  }
</script>
