<template>
  <div>

    <template v-if="records.length === 0">
      <article class="message is-warning">
        <div class="message-body">
          No Units
        </div>
      </article>
    </template>

    <b-table v-else :data="records">

      <template slot-scope="props">

        <template v-if="props.row.unit">

          <b-table-column field="reference_id" label="Reference" width="250">
            <h6 class="title is-6 is-whitespacenowrap" data-attribute="reference">
              <router-link :to="{ name: 'unit', params: { id: props.row.unit.id }}">{{ props.row.unit.number }}</router-link>
            </h6>
            <h6 class="subtitle is-size-7 has-text-grey" v-if="props.row.released_at">Released {{ props.row.released_at | formattedDateTime }}</h6>
            <h6 v-if="props.row.notes" data-attribute="notes" class="subtitle is-size-7 has-text-grey">{{ props.row.notes }}</h6>
          </b-table-column>

          <b-table-column field="status" label="Status">
            <span v-if="props.row.unit" class="tag" :class="props.row.unit.status">{{ props.row.unit.status | titleize }}</span>
          </b-table-column>

          <b-table-column field="purchase_order" label="Product">
            <p class="is-whitespacenowrap" data-attribute="product.code">{{ props.row.unit ? props.row.unit.product.code : '' }}</p>
          </b-table-column>

          <b-table-column field="actions" label="">

            <div v-if="props.row.released_at" class="field has-addons is-marginless is-pulled-right">
              <div class="control">
                <a class="button" :href="`/print/order-units/${props.row.id}`" target="_blank">
                  <span class="icon icon-medium"><i class="mdi mdi-file-pdf mdi-18px"></i></span>
                  <span>Print <span class="is-hidden-mobile">Receipt</span></span>
                </a>
              </div>
              <div class="control">
                <router-link class="button" :to="{ name: 'unit', params: { id: props.row.unit.id }}">
                  <span class="icon icon-medium"><i class="mdi mdi-file-eye-outline mdi-18px"></i></span>
                  <span>View</span>
                </router-link>
              </div>

              <div class="control">
                <button v-if="is_admin" @click="$emit('delete', props.row)" class="button">
                  <span class="icon icon-medium"><i class="mdi mdi-delete-forever-outline mdi-18px"></i></span>
                  <span>Remove</span>
                </button>
              </div>

            </div>

            <div v-else class="field has-addons is-marginless is-pulled-right">
              <div class="control">
                <a @click="$emit('gateOut', props.row)" aria-role="button" class="button">
                  <span class="icon icon-medium"><i class="mdi mdi-clipboard-arrow-down-outline mdi-18px"></i></span>
                  <span>Gate Out</span>
                </a>
              </div>
              <div class="control">
                <b-dropdown hoverable position="is-bottom-left" aria-role="menu">
                  <a class="button" slot="trigger" role="button">
                    <span>Edit</span>
                    <span class="icon"><i class="mdi mdi-chevron-down mdi-18px"></i></span>
                  </a>

                  <b-dropdown-item v-if="!props.row.arrived_at" aria-role="listitem" @click="$emit('edit', props.row)">
                    <span class="icon icon-medium"><i class="mdi mdi-file-replace-outline mdi-18px"></i></span>
                    <span>Edit Assignment</span>
                  </b-dropdown-item>

                  <b-dropdown-item v-if="is_admin" @click="$emit('delete', props.row)">
                    <span class="icon icon-medium"><i class="mdi mdi-delete-forever-outline mdi-18px"></i></span>
                    <span>Remove</span>
                  </b-dropdown-item>

                </b-dropdown>
              </div>
            </div>

          </b-table-column>

        </template>

        <div v-else class="has-text-danger">
          No unit for some reason.
          {{ props.row }}
        </div>

      </template>
    </b-table>
  </div>
</template>

<script>
  import UserHelper from 'shared/mixins/user-helper'
  import filters from 'shared/filters'
  export default {
    filters,
    mixins: [ UserHelper ],
    props: {
      records: {
        type: Array,
        required: true
      }
    }
  }
</script>
