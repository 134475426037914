import axios from 'axios'
import store from 'shared/store'

const csrfMeta = document.querySelector('meta[name="csrf-token"]')

const http = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/vnd.api+json',
    'Accept': 'application/vnd.api+json'
  },
  data: {
    authenticity_token: csrfMeta ? csrfMeta.getAttribute('content') : null
  }
})

http.interceptors.request.use(config => {
  config.headers['Authorization'] = `Bearer ${store.getters.authToken || 'unauthorized'}`
  return config
}, function (error) {
  return Promise.reject(error)
})

http.interceptors.response.use(null, (error) => {
  // console.warn('Error status', error.response.status)
  let message
  switch (error.response.status) {
    case 401:
      message = 'Oops! You don\'t have access here.'
      break
    case 404:
      message = 'Oops! That record couldn\'t be found.'
      break
    case 422:
      message = 'There was something wrong with the info you sent the server.'
      break
    default:
      message = 'Oops! Something went wrong...'
  }
  return Promise.reject(error)
})

export default http
