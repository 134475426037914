<template>
  <div v-if="photos && photos.length" class="thumbnails">
    <div class="columns">
      <template v-for="photo in photos">
        <div class="column is-one-quarter">
          <div class="card image is-square">
            <a @click="preview(photo)">
              <img :src="photo | small" />
            </a>
          </div>
        </div>
      </template>
    </div>

    <b-modal :active.sync="isPreviewVisible">
      <img :src="previewPhoto" width="100%">
    </b-modal>
  </div>
</template>

<script>
  const photoSizeUrl = (url, size) => {
    return url.replace('/uploads/', `/uploads/resized/${size}/`).replace('.jpeg', '.jpg')
  }
  export default {
    props: [ 'photos' ],
    filters: {
      small (url) {
        return photoSizeUrl(url, 'small')
      }
    },
    data () {
      return {
        isPreviewVisible: false,
        previewPhoto: null
      }
    },
    methods: {
      preview (photo) {
        this.previewPhoto = photoSizeUrl(photo, 'large')
        this.isPreviewVisible = true
      }
    }
  }
</script>
