<template>
  <span v-click-outside="hide" class="status-select-wrapper" :class="{ loading }">
    <div class="tags has-addons">
      <span class="tag" :class="record.status" data-attribute="status" @click.prevent="visible = !visible">
        {{ record.status | titleize }}
        <span class="icon"><i class="mdi mdi-chevron-down"></i></span>
      </span>
    </div>
    <div v-if="visible" class="status-dropdown-wrapper">
      <div class="status-select">
        <a v-for="(label, status) in options" class="tag" :class="confirm === status ? 'confirm' : status" @click="update(status)">
          <template v-if="confirm === status">
            <i class="mdi mdi-alert-circle-outline"></i> &nbsp; Click to Confirm
          </template>
          <template v-else>
            {{ label }}
          </template>
        </a>
      </div>
    </div>
  </span>
</template>

<script>
  import api from 'shared/json-api'
  import ClickOutside from 'vue-click-outside'
  import filters from 'shared/filters'
  export default {
    filters,
    directives: {
      ClickOutside
    },
    props: {
      options: {
        type: Object,
        required: true
      },
      record_type: {
        type: String,
        required: true
      },
      record: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        timeout: null,
        confirm: null,
        loading: false,
        visible: false
      }
    },
    methods: {
      hide () {
        this.visible = false
      },
      update (status) {
        if (this.timeout) clearTimeout(this.timeout)
        if (this.confirm === status) {
          this.save(status)
        } else {
          this.confirm = status
          this.timeout = setTimeout(() => {
            this.confirm = null
          }, 10000)
        }
      },
      save (status) {
        this.confirm = null
        this.record.status = status
        this.loading = true
        api.update(this.record_type, {
          id: this.record.id,
          status: this.record.status
        }).then(response => {
          this.loading = false
          this.visible = false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .status-select-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;
    white-space: nowrap;
    &.loading {
      pointer-events: none;
      cursor: wait;
    }
    .tags, .tag {
      margin: 0;
    }
  }
  .is-inline .status-select-wrapper {
    margin: 0;
  }
  .status-dropdown-wrapper {
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 10px;
    width: 220px;
    z-index: 9997;
    &:before {
      content: "";
      pointer-events: none;
      position: fixed;
      top: 0; right: 0; bottom: 0; left: 0;
      width: 100%;
      height: 100%;
      border: 0;
      background: rgba(0,0,0,0.25);
      z-index: 1;
    }
    @media (max-width: 768px) {
      position: fixed;
      top: 15%;
      left: 50%;
      margin-left: -110px;
    }
  }
  .status-select {
    position: relative;
    margin: 0;
    padding: 3px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    z-index: 2;
    box-shadow: 0 50px 100px rgba(50, 50, 93, .1), 0 15px 35px rgba(50, 50, 93, .15), 0 5px 15px rgba(0, 0, 0, .1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:after {
      content: "";
      position: absolute;
      top: -16px;
      right: 8px;
      border-top: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #fff;
      border-left: 8px solid transparent;
    }
    @media (max-width: 768px) {
      &:after {
        display: none;
      }
      &:before {
        display: none;
      }
    }
    .tag {
      display: block;
      padding: 5px 10px;
      height: auto;
      width: 100%;
      margin-bottom: 5px;
      text-decoration: none;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  // Status Global Action
  .box.is-relative {
    .status-select-wrapper {
      @media (max-width: 768px), (min-width: 769px) {
        position: absolute;
        top: 5px;
        right: 5px;
        margin: 0;
      }
      @media (min-width: 580px) and (max-width: 768px) {
        position: relative;
        top: -9px;
      }
    }
  }

</style>
