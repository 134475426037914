<template>
  <nav class="navigation" role="navigation" aria-label="main navigation">

    <div class="navbar-burger burger" @click="active = !active" :class="{ 'is-active': active }">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <div class="main-navigation-header">
      <div class="global-navigation-items">
        <div class="global-navigation-item"><depot-select /></div>
        <div class="global-navigation-item"><user-toolbar /></div>
      </div>
    </div>

    <div class="main-navigation" :class="{ 'is-active': active }">
      <router-link to="/" @click.native="active = !active"><span class="is-size-4-mobile">Summary</span></router-link>
      <router-link to="/incoming" @click.native="active = !active"><span class="is-size-4-mobile">Incoming</span></router-link>
      <router-link to="/releases" @click.native="active = !active"><span class="is-size-4-mobile">Releases</span></router-link>
      <router-link to="/rentals" @click.native="active = !active"><span class="is-size-4-mobile">Rentals</span></router-link>
      <router-link to="/modifications" @click.native="active = !active"><span class="is-size-4-mobile">Modifications</span></router-link>
      <router-link to="/support-tickets" @click.native="active = !active"><span class="is-size-4-mobile">Support Tickets</span></router-link>
      <!-- <router-link to="/dispatches" @click.native="active = !active"><span class="is-size-4-mobile">Dispatches</span></router-link> -->
      <router-link to="/transfers" @click.native="active = !active"><span class="is-size-4-mobile">Transfers</span></router-link>
      <router-link to="/inventory" @click.native="active = !active"><span class="is-size-4-mobile">Inventory</span></router-link>
    </div>

    <search-bar @result="handleResult" />

  </nav>
</template>

<script>
  import SearchBar from './search-bar'
  import UserToolbar from './user-toolbar'
  import DepotSelect from './global-depot-select'
  export default {
    components: {
      UserToolbar,
      SearchBar,
      DepotSelect
    },
    data () {
      return {
        active: false
      }
    },
    methods: {
      handleResult (result) {
        this.searchResult = result
      }
    }
  }
</script>

<style lang="scss">
  // Desktop to Mobile Breakpoints
  $navbreakpoint: 999px;
  $mobilebreakpoint: $navbreakpoint - 1px;
  $searchbreakpoint: 1025px;
  $mobilesearchbreakpoint: $searchbreakpoint - 1px;


  body {
    padding-top: 40px;
    @media (max-width: $mobilesearchbreakpoint) {
      padding-top: 72px;
    }
  }

  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 30;
    box-shadow: 0 1px 5px rgba(0,0,0,0.25);
  }
  .navbar-burger {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    width: 40px;
    height: 40px;
    span {
      height: 2px;
    }
  }
  .main-navigation-header {
    position: relative;
    width: 100%;
    height: 40px;
  }
  .global-navigation-items {
    display: flex;
    position: absolute;
    top: 7px;
    right: 7px;
    width: 220px;
    align-items: center;
    justify-content: flex-end;
    align-items: stretch;
  }
  .global-navigation-item {
    flex: 1 0 auto;
    margin-left: 7px;
  }
  @media (min-width: $navbreakpoint) {
    .main-navigation {
      position: absolute;
      top: 0px;
      left: 0px;
      display: flex;
      a {
        display: block;
        padding: 8px 10px;
        text-align: center;
      }
    }
  }
  @media (min-width: $searchbreakpoint) {
    .search-bar {
      position: absolute;
      top: 7px;
      right: 230px;
    }
  }
  @media (max-width: $mobilesearchbreakpoint) {
    .search-bar {
      padding: 5px;
      background: #f3f5f9;
      border-bottom: 1px solid #e6e8ec;
      box-shadow:0 2px 3px rgba(0, 0, 0, .05);
    }
  }
  @media (max-width: $mobilebreakpoint) {
    .main-navigation {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      bottom: auto;
      background: #4c4b55;
      padding-top: 40px;
      z-index: 1000;
      transition: opacity .25s ease-out;
      a {
        display: block;
        padding: 7px 15px;
      }
      &.is-active {
        opacity: 1;
        visibility: visible;
      }
    }
    .navbar-burger {
      display: block;
      z-index: 10;
      &.is-active {
        z-index: 2000;
      }
    }
  }
</style>
<style lang="scss" scoped>
  nav {
    background-color: #4c4b55;
    a {
      color: #fff;
    }
    @media (min-width: 580px) {
      a:hover {
        background: #5d5f61;
      }
    }
    a.router-link-exact-active {
      color: #fff;
      background-color: #75777a;
    }
  }
  h1 {
    font-weight: bold;
    padding: 0.5rem 0.75rem;
    line-height: 2.2rem;
  }
</style>
