<template>
  <div class="box">

    <h4 v-if="record.id" class="title is-4">Edit Product</h4>
    <h4 v-else class="title is-4">New Product</h4>

    <form @submit.prevent="validateAndSave()">

      <div class="columns is-multiline">
        <div class="column is-one-third">
          <b-field label="Type">
            <b-select v-model="record.classification" expanded>
              <option value="sales">Sales</option>
              <option value="rental">Rental</option>
            </b-select>
          </b-field>
        </div>

        <div class="column is-one-third">
          <b-field label="Product Code">
            <b-input type="text" v-model="record.code"></b-input>
          </b-field>
        </div>

        <div class="column is-one-third">
          <b-field label="Rental Code">
            <b-input type="text" v-model="record.rental_code" /></b-input>
          </b-field>
        </div>

        <div class="column is-one-third">
          <b-field label="Name">
            <b-input type="text" v-model="record.name" /></b-input>
          </b-field>
        </div>

        <div class="column is-one-third">
          <b-field label="Size">
            <b-input type="text" v-model="record.size" /></b-input>
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="Condition">
            <b-input type="text" v-model="record.condition" /></b-input>
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="Family">
            <b-input type="text" v-model="record.family" /></b-input>
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="Classification">
            <b-input type="text" v-model="record.product_class" /></b-input>
          </b-field>
        </div>
      </div>
      <div class="field is-grouped">
        <button class="button is-primary is-fullwidth">Save</button>
      </div>

    </form>
  </div>
</template>

<script>
  export default {
    props: {
      record: {
        type: Object,
        default () {
          return {}
        }
      },
      onSave: {
        type: Function
      }
    },
    methods: {
      validateAndSave () {
        this.$validator.validateAll().then(result => {
          if (result) {
            return this.save()
          }
          this.$store.commit('error.validation', this.errors)
        })
      },
      save () {
        this.$store.dispatch('saveRecord', [ 'product', this.record ]).then(record => {
          this.$buefy.toast.open({
            message: 'Product Saved',
            type: 'is-success'
          })
          this.$parent.close()
          if (this.onSave) this.onSave(record)
        })
      }
    }
  }
</script>
