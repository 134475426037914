<template>
  <address>
    <span data-attribute="address.line1">{{ record.line1 }}</span><span v-if="record.line2" data-attribute="address.line2"> {{ record.line2 }}</span>,
    <span data-attribute="address.locality">{{ record.locality }}</span>
    <span data-attribute="address.region">{{ record.region }}</span>,
    <span data-attribute="address.postal-code">{{ record.postal_code }}</span>
  </address>
</template>

<script>
  export default {
    props: [ 'record' ]
  }
</script>
