<template>

  <div>

    <template v-if="records.length === 0">
      <article class="message is-warning">
        <div class="message-body">
          No Returned Units
        </div>
      </article>
    </template>

    <b-table v-else
      :data="records"
      :striped="true"
      :narrowed="true">

      <template slot-scope="props">

        <b-table-column field="reference_id" label="Serial Number" width="220">
          <h6 class="title is-6 is-whitespacenowrap">
            <router-link :to="{ name: 'unit', params: { id: props.row.unit.id }}">{{ props.row.unit.number }}</router-link>
          </h6>
          <h6 v-if="props.row.unit.notes" class="subtitle is-size-7 has-text-grey">{{ props.row.unit.notes }}</h6>
          <h6 v-if="props.row.arrived_at" class="subtitle is-size-7 has-text-grey">Arrived {{ props.row.arrived_at | formattedDateTime }}</h6>

        </b-table-column>

        <b-table-column field="product" label="Product">
          <span class="is-whitespacenowrap" v-if="props.row.unit.product" :title="props.row.unit.product.name">
            {{ props.row.unit.product.code }}
          </span>
        </b-table-column>

        <b-table-column field="color" label="Color">
          <div v-if="props.row.unit.color" class="color-swatch-wrapper">
            <span class="color-swatch" :class="props.row.unit.color | colorClassName"></span>
            <span class="color" data-attribute="color">{{ props.row.unit.color }}</span>
          </div>
        </b-table-column>

        <b-table-column field="status" label="Status">
          <span :class="props.row.unit.status" class="tag">
            {{ props.row.unit.status | titleize }}
          </span>
        </b-table-column>

        <b-table-column field="actions" label="">

          <a class="button" :href="`/print/order-units/${props.row.id}`" target="_blank">
            <span class="icon icon-medium"><i class="mdi mdi-file-pdf mdi-18px"></i></span>
            <span>Print Receipt</span>
          </a>
          <router-link class="button" :to="{ name: 'unit', params: { id: props.row.unit.id }}">
            <span class="icon icon-medium"><i class="mdi mdi-file-eye-outline mdi-18px"></i></span>
            <span>View</span>
          </router-link>

        </b-table-column>
      </template>

    </b-table>
  </div>
</template>

<script>
  import CollectionTable from 'shared/mixins/collection-table'
  export default {
    mixins: [ CollectionTable ],
    props: [ 'transfer' ]
  }
</script>
