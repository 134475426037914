<template>
  <div class="box">

    <h4 v-if="record.id" class="title is-4">Edit Depot</h4>
    <h4 v-else class="title is-4">New Depot</h4>

    <form @submit.prevent="save" @keypress.enter.prevent="">

      <b-field label="Name" label-for="field-name">
        <b-input type="text" v-model="record.name" id="field-name" /></b-input>
      </b-field>

      <b-field label="Location" label-for="field-location">
        <b-input type="text" v-model="record.location" id="field-location" /></b-input>
      </b-field>

      <b-field label="Contact Name" label-for="field-contact">
        <b-input type="text" v-model="record.contact" id="field-contact" /></b-input>
      </b-field>

      <b-field label="Phone" label-for="field-phone">
        <b-input type="text" v-model="record.phone" id="field-phone" /></b-input>
      </b-field>

      <b-field label="Notes" label-for="field-notes">
        <b-input v-model="record.notes" type="textarea" id="field-notes"></b-input>
      </b-field>

      <div class="field">
        <b-radio v-model="record.internal" :native-value="true" name="internal">
          Internal
        </b-radio>
        <b-radio v-model="record.internal" :native-value="false" name="internal">
           External
        </b-radio>
      </div>

      <div class="field is-grouped">
        <button class="button is-primary is-fullwidth">Save</button>
      </div>

    </form>
  </div>
</template>

<script>
  export default {
    props: {
      record: {
        type: Object,
        required: true
      },
      onSave: {
        type: Function
      }
    },
    methods: {
      save () {
        delete this.record.address
        this.$store.dispatch('saveRecord', [ 'depot', this.record, {
          include: 'address'
        }]).then(record => {
          this.$buefy.toast.open({
            message: 'Depot Saved',
            type: 'is-success'
          })
          this.$parent.close()
          if (this.onSave) this.onSave(record)
        })
      }
    }
  }
</script>
