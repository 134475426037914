<template>
  <div>
    <b-table
      :data="records"
      :loading="loading"
      :current-page="meta.page"
      :total="meta.record_count"
      :per-page="50"
      :striped="true"
      :narrowed="true"
      detailed
      paginated
      backend-pagination
      backend-sorting
      default-sort="reference"
      default-sort-direction="asc"
      @sort="onSort"
      @page-change="onPageChange">

      <template slot-scope="props" slot="header">
        <b-tooltip v-if="props.column.meta" :active="!!props.column.meta" :label="props.column.meta" dashed>
          {{ props.column.label }}
        </b-tooltip>
        <template v-else>
          {{ props.column.label }}
        </template>
      </template>

      <template slot-scope="props">

        <b-table-column field="reference" label="Order #" sortable>
          <template v-if="props.row.deleted_at">
            <h6 class="title is-6 is-whitespacenowrap is-marginless">
              {{ props.row.reference }}
            </h6>
            <p class="has-text-danger is-size-7">
              Deleted <b>{{ props.row.deleted_at | formattedDateTime }}</b>
            </p>
          </template>
          <h6 v-else class="title is-6 is-whitespacenowrap is-marginless">
            <router-link :to="{ name: 'dispatch', params: { id: props.row.id }}">{{ props.row.reference }}</router-link>
          </h6>
        </b-table-column>

        <b-table-column label="Release #">
          <span v-if="props.row.data">
            {{ props.row.data.release_number }}
          </span>
        </b-table-column>

        <b-table-column field="status" label="Status" sortable>
          <status-select :record="props.row" record_type="delivery" />
        </b-table-column>

        <b-table-column field="driver" label="Driver">
          <span v-if="props.row.driver">
            {{ props.row.driver.first_name }}
            {{ props.row.driver.last_name }}
          </span>
        </b-table-column>

        <b-table-column field="created_at" label="Created" sortable>
          <p class="is-whitespacenowrap" data-attribute="created-at">
            {{ props.row.created_at | formattedDate }}
          </p>
        </b-table-column>

        <b-table-column field="expected_at" label="Due" sortable>
          <p class="is-whitespacenowrap" data-attribute="expected-at">
            {{ props.row.expected_at | formattedDate }}
          </p>
        </b-table-column>

        <b-table-column field="delivered_at" label="Completed At" sortable>
          <p class="is-whitespacenowrap" data-attribute="completed-at">
            {{ props.row.completed_at | formattedDate }}
          </p>
        </b-table-column>

      </template>

      <template slot="detail" slot-scope="props">
        <delivery-detail :delivery="props.row" />
      </template>


    </b-table>
  </div>
</template>

<script>
  import asyncTable from '../../mixins/async-table'
  import api from 'shared/json-api'
  import StatusSelect from './status'
  import DeliveryDetail from './delivery-detail'
  export default {
    mixins: [ asyncTable ],
    components: {
      StatusSelect,
      DeliveryDetail
    },
    data () {
      return {
        sort: {
          field: 'reference',
          order: 'asc'
        },
      }
    },
    methods: {
      loadAsyncData () {
        this.loading = true
        api.findAll('delivery', {
          sort: this.sortParam,
          include: 'customer,order',
          page: {
            number: this.meta.page
          },
          filter: this.$route.meta.computedRouteFilter()
        }).then(response => {
          this.handleResponse(response)
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
</script>
