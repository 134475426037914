<template>
  <div>

    <article v-if="!loading && records.length === 0" class="message is-warning">
      <div class="message-body">
        No Products
      </div>
    </article>

    <b-table v-else
      :data="records"
      :loading="loading"
      :current-page="meta.page"
      :total="meta.record_count"
      :per-page="50"
      :striped="true"
      :narrowed="true"
      paginated
      backend-pagination
      backend-sorting
      @sort="onSort"
      @page-change="onPageChange">


      <template slot-scope="props" slot="header">
        <b-tooltip v-if="props.column.meta" :active="!!props.column.meta" :label="props.column.meta" dashed>
          {{ props.column.label }}
        </b-tooltip>
        <template v-else>
          {{ props.column.label }}
        </template>
      </template>


      <template slot-scope="props">

        <b-table-column field="code" label="Product Code" sortable>
          <h6 class="title is-6 is-marginless">
            <a v-if="is_admin" href="#" @click.prevent="$emit('edit', props.row)">
              {{ props.row.code }}
            </a>
            <span v-else>
              {{ props.row.code }}
            </span>
          </h6>
        </b-table-column>

        <b-table-column field="rental_code" label="Rental Code" sortable>
          <span class="is-whitespacenowrap">{{ props.row.rental_code }}</span>
        </b-table-column>

        <b-table-column field="name" label="Description" sortable>
          <span class="is-size-7">{{ props.row.name }}</span>
        </b-table-column>

        <b-table-column field="size" label="Size" sortable>
          {{ props.row.size }}
        </b-table-column>

        <b-table-column field="condition" label="Condition" sortable>
          {{ props.row.condition }}
        </b-table-column>

        <b-table-column field="family" label="Family" sortable>
          <span class="tag">{{ props.row.family }}</span>
        </b-table-column>

        <b-table-column v-if="is_admin" field="actions" label="">
          <span class="is-whitespacenowrap">
            <a href="#" @click.prevent="$emit('edit', props.row)"><span class="icon icon-medium"><i class="mdi mdi-settings-outline mdi-24px"></i></span></a>
            <a href="#" @click.prevent="$emit('delete', props.row)"><span class="icon icon-medium"><i class="mdi mdi-delete-forever-outline mdi-24px"></i></span></a>
          </span>
        </b-table-column>
      </template>

    </b-table>

  </div>
</template>

<script>
  import api from 'shared/json-api'
  import asyncTable from '../../mixins/async-table'
  export default {
    mixins: [ asyncTable ],
    data () {
      return {
        sort: {
          field: 'code',
          order: 'asc'
        }
      }
    },
    methods: {
      loadAsyncData () {
        this.loading = true
        api.findAll('product', {
          sort: this.sortParam,
          page: {
            number: this.meta.page
          },
          filter: this.$route.meta.computedRouteFilter()
        }).then(response => {
          this.handleResponse(response)
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
</script>
