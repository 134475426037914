<template>
  <div class="box release-summary is-summary-table">
    <loader v-if="!data" />
    <template v-else>

      <div class="level is-mobile">
        <div class="level-item level-left">
          <h5 class="title is-5">Release Summary</h5>
        </div>
        <div class="level-item level-right">
          <depot-select v-model="depot" @input="handleChange" />
        </div>
      </div>

      <div class="b-table">
        <div class="table-wrapper">
          <table class="table is-striped is-narrow">
            <thead>
              <tr>
                <th>Product Code</th>
                <th class="has-text-right">Total</th>
                <th class="has-text-right">Rel<span class="is-hidden-mobile">eased</span></th>
                <th class="has-text-right">Rem<span class="is-hidden-mobile">aining</span></th>
              </tr>
            </thead>
            <tbody v-if="loading">
              <tr>
                <td colspan="4">
                  <i>Loading...</i>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="(counts, product) in data.units_by_product_code">
                <td class="has-text-left">
                  {{ product }}
                </td>
                <td class="has-text-right">
                  {{ counts.total }}
                </td>
                <td class="has-text-right">
                  {{ counts.released }}
                </td>
                <td class="has-text-right">
                  {{ counts.remaining }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>
                  Totals
                </th>
                <th class="has-text-right">
                  {{ total.total }}
                </th>
                <th class="has-text-right">
                  {{ total.released }}
                </th>
                <th class="has-text-right">
                  {{ total.remaining }}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import api from 'shared/json-api'
  import Loader from 'shared/components/loader'
  import DepotSelect from '../../shared/components/depot-select'
  export default {
    components: {
      Loader,
      DepotSelect
    },
    data () {
      return {
        data: null,
        loading: false,
        depot: null
      }
    },
    computed: {
      total () {
        let total = {
          total: 0,
          released: 0,
          remaining: 0
        }
        if (!this.data) return total
        Object.values(this.data.units_by_product_code).forEach(row => {
          total.total += row.total
          total.released += row.released
          total.remaining += row.remaining
        })
        return total
      }
    },
    created () {
      this.fetchReport()
    },
    methods: {
      handleChange () {
        this.fetchReport()
      },
      fetchReport () {
        let id = new Date().getTime() / 1000
        this.loading = true
        api.find('release_summary', id, {
          depot_id: this.depot ? this.depot.id : null
        }).then(response => {
          this.data = response.data
          this.loading = false
        })
      }
    }
  }
</script>
