<template>
  <b-autocomplete
    ref="autocomplete"
    :data="units"
    :loading="loading"
    :open-on-focus="true"
    placeholder="Serial Number"
    field="number"
    type="search"
    @typing="handleTyping"
    @select="update">

    <template slot="empty">
      No matching units found.
      <dl>
        <template v-if="depot">
          <dt>Depot:</dt>
          <dd>{{ depot.name }}</dd>
        </template>
        <template v-if="product_code">
          <dt>Product:</dt>
          <dd>{{ product_code }}</dd>
        </template>
        <template v-if="status">
          <dt>Status:</dt>
          <dd>{{ status | titleize }}</dd>
        </template>
        <template v-if="fleet_status">
          <dt>Fleet Status:</dt>
          <dd>{{ fleet_status | titleize }}</dd>
        </template>
      </dl>
    </template>

    <template slot-scope="props">
      <div class="media">
        <div class="media-content">
          <b>{{ props.option.number }}</b>
          <span class="tag" :class="props.option.status">
            {{ props.option.status | titleize }}
          </span>
          <span class="tag" :class="props.option.fleet_status">
            {{ props.option.fleet_status | titleize }}
          </span>
          <span class="tag" :class="props.option.depot.name">
            {{ props.option.depot.name }}
          </span>
          <br>
          <small>
            <span v-if="props.option.product">
              {{ props.option.product.code }}
              <span v-if="props.option.product.rental_code">
                - {{ props.option.product.rental_code }}
              </span>
            </span>
            <span v-if="props.option.color">
              <span class="v-autocomplete-preview-status color-swatch" :class="props.option.color | colorClassName"></span>
              <span>{{ props.option.color }}</span>
            </span>
          </small>
        </div>
      </div>
    </template>
  </b-autocomplete>
</template>

<script>
  import debounce from 'lodash/debounce'
  import api from 'shared/json-api'
  import filters from 'shared/filters'
  import UserHelper from 'shared/mixins/user-helper'
  export default {
    filters,
    mixins: [ UserHelper ],
    props: {
      value: {
        type: Object,
        default: null
      },
      depot: {
        type: Object,
        default: null
      },
      product_code: {
        type: String,
        default: null
      },
      fleet_status: {
        type: String,
        default: null
      },
      status: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        units: [],
        loading: false,
        localValue: this.value
      }
    },
    mounted () {
      if (this.value) {
        this.$refs.autocomplete.setSelected(this.value)
      } else {
        this.fetchUnits('')
      }
    },
    methods: {
      clear () {
        this.localValue = {}
        this.$refs.autocomplete.setSelected({})
        this.fetchUnits('')
      },
      handleTyping: debounce(function (query) {
        this.fetchUnits(query)
      }, 250),
      fetchUnits (query) {
        this.loading = true
        api.findAll('unit', {
          page: {
            number: 1,
            size: 999
          },
          include: 'depot,product',
          filter: api.cleanFilters({
            query,
            depot_id: this.depot ? this.depot.id : null,
            status: this.status,
            fleet_status: this.fleet_status,
            product_code: this.product_code
          })
        }).then(response => {
          this.units = response.data
          this.loading = false
        })
      },
      update (value) {
        this.localValue = value
        this.$emit('input', this.localValue)
      }
    }
  }
</script>

<style scoped>
  dl {
    padding-top: 0.5em;
  }
  dt {
    font-weight: bold;
  }
  @media (min-width: 768px) {
    dl {
      display: flex;
    }
    dt, dd {
      flex: 1;
    }
    dt {
      flex-grow: 0;
      margin-right: 0.5em;
    }
    dd {
      flex-grow: 2;
    }
  }
</style>
