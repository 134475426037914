<template>
  <div class="box">

    <h4 v-if="record.id" class="title is-4">Edit Address</h4>
    <h4 v-else class="title is-4">New Address</h4>

    <form slot="body" @submit.prevent="save" @keypress.enter="save">

      <div class="block">
        <address-fields v-model="record" />
      </div>

      <div class="field is-grouped">
        <div class="control is-expanded">
          <button class="button is-fullwidth" @click.prevent="$parent.close()">Cancel</button>
        </div>
        <div class="control is-expanded">
          <button class="button is-primary is-fullwidth">Save</button>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
  import AddressFields from './address-fields'
  export default {
    components: {
      AddressFields
    },
    props: {
      record: {
        type: Object,
        default: null
      },
      onSave: {
        type: Function
      }
    },
    methods: {
      save () {
        this.$store.dispatch('saveRecord', [ 'address', this.record ]).then(record => {
          this.$buefy.toast.open({
            message: 'Address Saved',
            type: 'is-success'
          })
          this.$parent.close()
          if (this.onSave) this.onSave(record)
        }).catch(() => {
          this.$buefy.toast.open({
            message: 'Address could not be saved',
            type: 'is-danger'
          })
        })
      }
    }
  }
</script>
