<template>
  <div class="box">

    <h4 v-if="record.id" class="title is-4">Edit Movement</h4>
    <h4 v-else class="title is-4">New Movement</h4>

    <form @submit.prevent="validateAndSave()">

      <div class="columns is-multiline">
        <div class="column">
          <b-field label="Momement Type" expanded>
            <b-select v-model="record.movement_type" icon="truck-delivery" expanded>
              <option></option>
              <option value="sale_delivery">Sale Delivery</option>
              <option value="rental_delivery">Rental Delivery</option>
              <option value="rental_pickup">Rental Pickup</option>
              <option value="relocation">Relocation / Reposition</option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Status" expanded>
            <b-select v-model="record.status" icon="flag-variant-outline" expanded>
              <option></option>
              <option v-for="(label, status) in statuses" :value="status">
                {{ label }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div v-if="!record.id" class="column">
          <b-field label="Quantity">
            <b-input v-model="quantity" />
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-field label="Door Direction">
            <b-select v-model="record.door_direction" expanded>
              <option></option>
              <option value="tbd">To Be Determined</option>
              <option value="cab">Cab</option>
              <option value="rear">Rear</option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Driver">
            <b-select v-model="record.driver" expanded>
              <option></option>
              <option v-for="driver in drivers" :value="driver">
                {{ driver.first_name }}
                {{ driver.last_name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="Big Truck OK">
            <b-checkbox v-model="record.data.big_truck_ok" class="inline-label">
              Yes
            </b-checkbox>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="Site Ready">
            <b-checkbox v-model="record.data.site_ready" class="inline-label">
              Yes
            </b-checkbox>
          </b-field>
        </div>
      </div>

      <address-or-depot label="Source Type" v-model="record.source" />

      <address-or-depot label="Destination Type" v-model="record.destination" />

      <template v-if="record.source">
        <div class="columns is-multiline">

          <div class="column">
            <product-code-select
              v-model="record.product_code"
              :fleet-status="fleetStatus"
              @input="resetUnit()"
            />
          </div>

          <div v-if="record.product_code && quantity == 1" class="column">
            <b-field label="Unit">
              <div class="unit-autocomplete">
                <unit-autocomplete
                  ref="autocomplete"
                  v-model="record.unit"
                  :product_code="record.product_code"
                  :status="unitStatus"
                  expanded />
              </div>
            </b-field>
          </div>

        </div>
      </template>

      <div class="columns">
        <div class="column">
          <b-field label="Scheduled Time">
            <date-time-picker v-model="record.scheduled_at" />
          </b-field>
        </div>
        <div class="column">
          <b-field label="Start Time">
            <date-time-picker v-model="record.started_at" />
          </b-field>
        </div>
        <div class="column">
          <b-field label="Completion Time">
            <date-time-picker v-model="record.completed_at" />
          </b-field>
        </div>
      </div>

      <div class="columns is-multiline">

        <div class="column is-full">
          <b-field label="Notes">
            <b-input type="textarea" v-model="record.notes" />
          </b-field>
        </div>

        <div class="column is-full">
          <b-field label="Photos">
            <dropzone v-model="record.photos" />
          </b-field>
        </div>

        <div class="column is-full">

          <b-progress v-show="progress > 0" :value="progress" type="is-success" show-value format="percent"></b-progress>

          <div class="field">
            <button :disabled="loading" class="button is-primary is-fullwidth">
              Save
            </button>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
  import userHelper from 'shared/mixins/user-helper'
  import hasDateFields from 'shared/mixins/has-date-fields'
  import AddressOrDepot from './helpers/address-or-depot'
  import ProductCodeSelect from '../../shared/components/product-code-select'
  import UnitAutocomplete from '../../shared/components/unit-autocomplete'
  import Dropzone from '../../shared/components/dropzone'
  import { deliveryStatuses } from 'shared/statuses'
  export default {
    mixins: [
      userHelper,
      hasDateFields
    ],
    components: {
      AddressOrDepot,
      ProductCodeSelect,
      UnitAutocomplete,
      Dropzone
    },
    props: {
      movement: {
        type: Object,
        default () {
          return {
            status: 'pending'
          }
        }
      },
      onSave: {
        type: Function
      }
    },
    data () {
      return {
        record: this.movement,
        loading: false,
        quantity: 1,
        savedCount: 0,
        statuses: deliveryStatuses,
        drivers: []
      }
    },
    computed: {
      progress () {
        return Math.round((this.savedCount / this.quantity) * 100)
      },
      fleetStatus () {
        switch (this.record.movement_type) {
          case 'sale_delivery':
            return 'unassigned'
          case 'rental_delivery':
          case 'rental_pickup':
            return 'rental'
          default:
            return null
        }
      },
      unitStatus () {
        switch (this.record.movement_type) {
          case 'sale_delivery':
          case 'rental_delivery':
            return 'available'
          case 'rental_pickup':
            return 'on_rent'
          default:
            return null
        }
      }
    },
    created () {
      this.record.data = this.record.data || {}
      this.record.source = this.record.source || { type: 'depots' }
      this.record.destination = this.record.destination || { type: 'depots' } // || { type: 'addresses', country_code: 'US' }
      this.initDateFields([ 'scheduled_at', 'started_at', 'completed_at' ])
      this.$store.dispatch('fetchRecords', [ 'user', {
        filter: { role: 'driver' }
      }]).then(users => {
        this.drivers = users
      })
    },
    methods: {
      resetUnit () {
        setTimeout(() => {
          this.record.unit = null
          this.$refs.autocomplete.clear()
        }, 50)
      },
      validateAndSave () {
        this.$validator.validateAll().then(result => {
          if (result) {
            return this.save()
          }
          this.$store.commit('error.validation', this.errors)
        })
      },
      async save () {

        if (this.quantity < 1 || this.quantity > 100) {
          this.$buefy.dialog.alert({
            title: 'Invalid Quantity',
            message: 'Quantity must be between 1 and 100.',
            type: 'is-danger'
          })
          return
        }

        this.loading = true

        let record, quantity = this.quantity

        try {

          while (quantity--) {

            record = this.record

            let source = record.source,
                destination = record.destination

            if (record.unit && !record.unit.id) {
              delete record.unit
            }

            // If record is a new record.. wait until it saves first
            if (!record.id) {
              let recordToSave = { ...record }
              delete recordToSave.source
              delete recordToSave.destination
              record = await this.saveRecord(recordToSave)
            }
            // Save addresses TODO optimize..
            if (source && source.type === 'addresses') {
              record.source = await this.saveAddress(source, record)
            } else {
              record.source = source
            }
            if (destination && destination.type === 'addresses') {
              record.destination = await this.saveAddress(destination, record)
            } else {
              record.destination = destination
            }

            if (!record.source.id) delete record.source
            if (!record.destination.id) delete record.destination

            // Save record again now that assocations exist
            record = await this.saveRecord(record)

            this.savedCount++

          }

        } catch (e) {
          console.warn(e)
          this.loading = false
        }

        // Success!
        this.$buefy.toast.open({
          message: 'Movement Saved',
          type: 'is-success'
        })
        this.$parent.close()
        if (this.onSave) this.onSave(record)

      },

      async saveAddress (address, record) {
        address.addressable = record
        return await this.$store.dispatch('saveRecord', [ 'address', address ])
      },
      async saveRecord (record) {
        return await this.$store.dispatch('saveRecord', [ 'movement', record, { include: 'delivery,driver,unit,source,destination' }])
      }

    }
  }
</script>
