<template>
  <b-dropdown aria-role="list" position="is-bottom-left" class="is-fullwidth">
    <a class="button is-small is-fullwidth" slot="trigger">
      <b-icon icon="account-circle" size="is-small"></b-icon>
      <span>{{ current_user.first_name }}</span>
    </a>
    <template v-if="is_admin">
      <b-dropdown-item aria-role="listitem" has-link>
        <router-link to="/depots" class="navbar-item is-size-4-mobile">Depots</router-link>
      </b-dropdown-item>
      <b-dropdown-item aria-role="listitem" has-link>
        <router-link to="/products" class="navbar-item is-size-4-mobile">Products</router-link>
      </b-dropdown-item>
      <b-dropdown-item aria-role="listitem" has-link>
        <router-link to="/users" class="navbar-item is-size-4-mobile">Users</router-link>
      </b-dropdown-item>
    </template>
    <template else>
      <b-dropdown-item aria-role="listitem" has-link>
        <a @click.prevent="editUser()" class="navbar-item is-size-4-mobile">Edit Account</a>
      </b-dropdown-item>
    </template>
    <b-dropdown-item aria-role="listitem" has-link>
      <a href="#/logout" @click.prevent="logout()" class="navbar-item is-size-4-mobile">Logout</a>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
  import api from 'shared/json-api'
  import UserHelper from 'shared/mixins/user-helper'
  import UserForm from '../../components/users/form'
  export default {
    mixins: [ UserHelper ],
    data () {
      return {
        active: false
      }
    },
    methods: {
      editUser () {
        this.$buefy.modal.open({
          parent: this,
          component: UserForm,
          props: {
            record: { ...this.current_user }
          }
        })
      },
      logout () {
        api.destroy('session', '').then(() => {
          this.$store.commit('authenticate', null)
          window.location.href = '/login'
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .dropdown,
  .dropdown-trigger {
    width: 100%;
  }
  // Fixes double padding of buefy nested router link bug
  .dropdown.is-mobile-modal .dropdown-menu .has-link a.navbar-item {
    padding: 0.5rem 1rem;
  }
</style>
