import Vue from 'vue'

export default {

  'authenticate': (state, data) => {
    if (data) {
      state.session = { id: data.id, created_at: data.created_at }
      state.user = data.user
    } else {
      state.session = state.user = null
    }
  },

  'error.dismiss': (state) => {
    state.error = null
  },

  'error.request': (state, error) => {
    state.error = { type: 'request', object: error }
  },

  'error.validation': (state, error) => {
    state.error = { type: 'validation', object: error }
  },

  'depot.select': (state, depot) => {
    state.depot = depot
  },

  'depots.loaded': (state, depots) => {
    state.depots = depots
  }

}
