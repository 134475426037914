<template>
  <b-field :label="label" :label-for="id">
    <b-select v-model="localValue" @input="update" icon="map-marker-multiple" :id="id" :disabled="disabled" expanded>
      <option :value="null"></option>
      <option v-if="depots.length === 0" disabled>loading...</option>
      <option v-else v-for="depot in depots" :value="depot" :disabled="depot === disable">
        {{ depot.name }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
  export default {
    props: {
      value: {
        type: Object
      },
      disable: {
        type: Object
      },
      disabled: {
        type: Boolean
      },
      defaultValue: {
        type: String
      },
      label: {
        type: String,
        default: 'Depot'
      }
    },
    data () {
      return {
        localValue: this.value,
        depots: []
      }
    },
    computed: {
      id () {
        return `field-${this.label.replace(/\s/g, '-').toLowerCase()}`
      }
    },
    created () {
      this.$store.dispatch('fetchRecords', [ 'depot' ]).then(records => {
        this.depots = records
        if (!this.localValue && this.defaultValue) {
          this.localValue = records.find(i => i.name === this.defaultValue)
        }
        this.update()
      })
    },
    methods: {
      update () {
        this.$emit('input', this.localValue)
      }
    }
  }
</script>
