import { Validator } from 'vee-validate';
// import ContainerValidator from 'container-validator'
// const containerValidator = new ContainerValidator()

const SERIAL_NUMBER_REGEX = /^[a-zA-Z0-9\-]+$/

Validator.extend('containerNumber', {
  getMessage(field) {
    // return containerValidator.getErrorMessages().join(', ')
    return 'Serial number is invalid.'
  },
  validate(value) {
    // value = value.replace(/-/g, '')
    // return containerValidator.isValid(value)
    return SERIAL_NUMBER_REGEX.test(value)
  }
})
