<template>
  <section class="section support-tickets">

    <div class="level is-mobile">
      <div class="level-item level-left">
        <h1 class="title is-size-4-mobile">Support Tickets</h1>
      </div>
      <div v-if="!user_is_role('depot')" class="level-item level-right">
        <button @click="newRecord()" class="button is-primary is-outlined">
          <span class="icon icon-medium"><i class="mdi mdi-clipboard-arrow-up-outline mdi-18px"></i></span>
          <span>New <span class="is-hidden-mobile">Support Ticket</span></span>
        </button>
      </div>
    </div>

    <order-filters
      :statusOptions="supportTicketStatuses"
      :show-timing-filter="false"
      :show-transfer-filter="false">

      <template v-slot:default="slotProps">
        <div class="column is-2">
          <b-field label="Service Type" expanded>
            <b-select v-model="slotProps.filter.service_type" @input="slotProps.action" expanded>
              <option></option>
              <option v-for="t in serviceTypes" :key="t">
                {{ t }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="Sales Rep" expanded>
            <b-select v-model="slotProps.filter.sales_rep" @input="slotProps.action" expanded>
              <option></option>
              <option v-for="rep in salesReps">{{ rep }}</option>
            </b-select>
          </b-field>
        </div>
      </template>

    </order-filters>

    <order-table />

  </section>
</template>

<script>
  import userHelper from 'shared/mixins/user-helper'
  import { supportTicketStatuses } from 'shared/statuses'
  import salesReps from 'shared/sales-reps'
  import { serviceTypes } from 'shared/support-tickets'
  import OrderFilters from '../../shared/components/order-filter'
  import OrderTable from './table'
  import OrderForm from './form'
  export default {
    mixins: [ userHelper ],
    components: {
      OrderTable,
      OrderFilters
    },
    data () {
      return {
        supportTicketStatuses,
        salesReps,
        serviceTypes
      }
    },
    methods: {
      newRecord () {
        this.$buefy.modal.open({
          parent: this,
          component: OrderForm,
          props: {
            onSave: record => {
              this.$router.push({ name: 'support-ticket', params: { id: record.id }})
            }
          }
        })
      }
    }
  }
</script>
