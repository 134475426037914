export const outgoingOrderStatuses = {
  awaiting_status: 'Awaiting Status',
  not_ready: 'Not Ready',
  ready: 'Ready',
  partial_ready: 'Partial Ready',
  picked_up: 'Picked Up',
  closed_out: 'Closed Out',
  modification_needed: 'Modification Needed',
  modification_ready: 'Modification Ready',
  survey_needed: 'Survey Needed',
  survey_ready: 'Survey Ready'
}

export const incomingOrderStatuses = {
  awaiting_status: 'Awaiting Status',
  ready: 'Ready',
  arrived: 'Arrived',
  closed_out: 'Closed Out'
}

export const rentalOrderStatuses = {
  awaiting_status: 'Awaiting Status',
  ready: 'Ready',
  active: 'Active',
  inactive: 'Inactive',
  closed_out: 'Closed Out'
}

export const modificationOrderStatuses = {
  quick_modification: 'Quick Mod',
  awaiting_approval: 'Awaiting Customer Approval',
  pending_start: 'Approved Pending Start',
  in_progress: 'In Progress',
  complete: 'Complete',
  complete_scheduled: 'Complete - Scheduled',
  complete_hold: 'Complete - Hold',
  delivered: 'Delivered'
}

export const supportTicketStatuses = {
  need_more_information: 'Need More Information',
  pending_start: 'Pending Start',
  scheduled: 'Scheduled',
  complete: 'Complete'
}

export const orderStatuses = {
  outgoing: outgoingOrderStatuses,
  incoming: incomingOrderStatuses,
  rental: rentalOrderStatuses,
  modification: modificationOrderStatuses,
  support_ticket: supportTicketStatuses
}

export const modificationStatuses = {
  spec_sent: 'Spec Sent',
  spec_approved: 'Spec Approved',
  hold: 'Hold',
  in_progress: 'In Progress',
  complete: 'Complete'
}

export const deliveryStatuses = {
  hold: 'Hold',
  pending: 'Pending',
  pending_modification: 'Pending Modification',
  scheduled: 'Scheduled',
  delivered: 'Delivered'
}

export const transferStatuses = {
  unassigned: 'Unassigned',
  not_ready: 'Not Ready',
  ready: 'Ready',
  closed_out: 'Closed Out',
  partial_ready: 'Partial Ready'
}

export const unitStatuses = {
  available: 'Available',
  pending_inspection: 'Pending Inspection',
  surveyed: 'Surveyed',
  damaged: 'Damaged',
  pending_modification: 'Pending Modification',
  modification_ready: 'Modification Ready',
  reserved: 'Reserved',
  released: 'Released',
  on_rent: 'On Rent',
  missing: 'Missing',
  expected: 'Expected'
}
