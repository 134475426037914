<template>
  <trace-helper :trace="trace">
    <span class="tag"><b>assigned</b></span> unit <span class="tag"><b>{{ record.number }}</b></span>
    <template v-if="trace.order">
      to
      {{ trace.order.order_type | humanize }}
      order
      <span class="tag"><b>{{ trace.order.reference }}</b></span>
    </template>
  </trace-helper>
</template>

<script>
  import TraceMixin from 'shared/mixins/trace'
  export default {
    mixins: [ TraceMixin ]
  }
</script>
