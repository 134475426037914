export default {
  authenticated: state => {
    return state.session !== null
  },
  authToken: state => {
    if (state.user) {
      return state.user.auth_token
    }
  },
  depot_id: state => {
    if (state.user && state.user.depot) {
      return parseInt(state.user.depot.id)
    } else if (state.depot) {
      return parseInt(state.depot.id)
    }
  }
}
