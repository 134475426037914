<template>
  <div class="box unit-summary is-summary-table">
    <loader v-if="!units_by_product_code" />
    <template v-else>

      <div class="level is-mobile">
        <div class="level-item level-left">
          <h5 class="title is-5">Unit Summary</h5>
        </div>
        <div class="level-item level-right">
          <fleet-status-select v-model="fleet_status" @input="handleChange" />
          <depot-select v-model="depot" @input="handleChange" />
          <button @click="download('csv')" class="button">
            <span class="icon is-small">
              <i class="mdi mdi-download"></i>
            </span>
            <span>CSV</span>
          </button>
          <button @click="download('html')" class="button has-icon-left">
            <span class="icon is-small">
              <i class="mdi mdi-download"></i>
            </span>
            <span>HTML</span>
          </button>
        </div>
      </div>

      <div class="b-table">
        <div class="table-wrapper">
          <table class="table is-striped is-narrow">
            <thead>
              <tr>
                <th>Product Code</th>
                <th class="has-text-right">Avail<span class="is-hidden-mobile">able</span></th>
                <th class="has-text-right">Exp<span class="is-hidden-mobile">ected</span></th>
                <th class="has-text-right">Res<span class="is-hidden-mobile">erved</span></th>
              </tr>
            </thead>
            <tbody v-if="loading">
              <tr>
                <td colspan="4">
                  <i>Loading...</i>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="(counts, product) in units_by_product_code">
                <td class="has-text-left">
                  {{ product }}
                </td>
                <td class="has-text-right">
                  {{ counts.available }}
                </td>
                <td class="has-text-right">
                  {{ counts.expected }}
                </td>
                <td class="has-text-right">
                  {{ counts.reserved }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>
                  Total
                </th>
                <th class="has-text-right">
                  {{ total.available }}
                </th>
                <th class="has-text-right">
                  {{ total.expected }}
                </th>
                <th class="has-text-right">
                  {{ total.reserved }}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import moment from 'moment'
  import api from 'shared/json-api'
  import http from 'shared/http'
  import downloadData from 'shared/services/download'
  import Loader from 'shared/components/loader'
  import FleetStatusSelect from '../../shared/components/fleet-status-select'
  import DepotSelect from '../../shared/components/depot-select'
  export default {
    components: {
      Loader,
      FleetStatusSelect,
      DepotSelect
    },
    data () {
      return {
        units_by_product_code: null,
        total: null,
        loading: false,
        fleet_status: null,
        depot: null
      }
    },
    computed: {
      filters () {
        return {
          fleet_status: this.fleet_status,
          depot_id: this.depot ? this.depot.id : null
        }
      }
    },
    created () {
      this.fetchReport()
    },
    methods: {
      handleChange () {
        this.fetchReport()
      },
      fetchReport () {
        let id = new Date().getTime() / 1000
        this.loading = true
        api.find('unit_summary', id, this.filters).then(response => {
          this.units_by_product_code = response.data.units_by_product_code
          this.total = this.units_by_product_code['Total']
          delete this.units_by_product_code['Total']
          this.loading = false
        })
      },
      download (format) {
        http.get(`units/summary.${format}`, {
          params: this.filters,
          headers: {
            'Content-Type': `text/${format}`,
            'Accept': `text/${format}`,
          }
        }).then(response => {
          let filename = `unit-summary-${moment().unix()}.${format}`
          downloadData(filename, response.data)
        })
      }
    }
  }
</script>
