<template>
  <div class="columns is-multiline">
    <div class="column is-full">
      <label class="label">{{ label }}</label>
      <b-radio v-model="location.type" native-value="depots" @input="update">
        Depot
      </b-radio>
      <b-radio v-model="location.type" native-value="addresses" @input="update">
        Address
      </b-radio>
    </div>
    <div class="column">
      <depot-select v-if="location.type === 'depots'" v-model="location" @input="update" />
      <address-fields v-else v-model="location" @input="update" />
    </div>
  </div>
</template>

<script>
  import DepotSelect from '../../../shared/components/depot-select'
  import AddressFields from '../../../shared/components/address-fields'
  export default {
    components: {
      DepotSelect,
      AddressFields
    },
    props: {
      label: String,
      value: Object
    },
    data () {
      return {
        location: this.value
      }
    },
    methods: {
      update () {
        if (!this.location) {
          this.location = { type: 'depots' }
        } else if (this.location.type === 'addresses') {
          this.location.country_code = this.location.country_code || 'US'
        }
        this.$emit('input', this.location)
      }
    }
  }
</script>
