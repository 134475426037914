<template>
  <b-field label="Color">
    <b-select v-model="localValue" @input="update" placeholder="Select Color" icon="format-color-fill" expanded>
      <option value="" v-if="allowBlank">All</option>
      <option
        v-for="(index, option) in options"
        :value="option"
        :key="option">
        {{ option }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
  import { colors } from 'shared/colors'
  export default {
    props: [ 'value', 'allowBlank' ],
    data () {
      return {
        localValue: this.value,
        options: colors
      }
    },
    methods: {
      update () {
        this.$emit('input', this.localValue)
      }
    }
  }
</script>
