<template>
  <trace-helper :trace="trace">
    <span class="tag"><b>{{ action }}</b></span>
    modification for
    <span class="tag">
      <router-link v-if="trace.unit" :to="{ name: 'unit', params: { id: trace.unit.id }}">
        {{ trace.unit.number }}
        ({{trace.data.product_code}})
      </router-link>
      <b v-else>{{ trace.data.product_code }}</b>
    </span>
    <template v-if="trace.order">
      to modification order
      <span class="tag"><b>{{ trace.order.reference }}</b></span>
    </template>
  </trace-helper>
</template>

<script>
  import TraceMixin from 'shared/mixins/trace'
  export default {
    mixins: [ TraceMixin ],
    data () {
      return {
        action: 'updated'
      }
    }
  }
</script>
