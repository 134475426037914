<template>
  <div class="box">

    <h4 v-if="record.id" class="title is-4">Edit Unit</h4>
    <h4 v-else class="title is-4">New Unit</h4>

    <form @submit.prevent="validateAndSave()">

      <div class="columns is-multiline">

        <div class="column is-one-third">
          <depot-select v-model="record.depot" :disabled="!!user_depot" />
        </div>

        <div class="column is-one-third">
          <b-field label="Inventory Confirmed At">
            <date-time-picker v-model="record.confirmed_at" />
          </b-field>
        </div>

        <div class="column is-one-third">
          <fleet-status-select v-model="record.fleet_status" />
        </div>


        <div class="column is-one-quarter">
          <product-select v-model="record.product" />
        </div>

        <div class="column is-one-quarter">
          <b-field label="Serial Number">
            <b-input type="text" v-model="record.number" data-vv-name="serial number" v-validate="{ required: true, containerNumber: true }" /></b-input>
          </b-field>
        </div>

        <div class="column is-one-quarter">
          <unit-status-select v-model="record.status" />
        </div>

        <div class="column is-one-quarter">
          <unit-color-select v-model="record.color" />
        </div>

        <div class="column is-one-quarter">
          <b-field label="Open Side">
            <b-select
              v-model="record.open_side"
              expanded>
              <option value="none">None</option>
              <option value="left">Left</option>
              <option value="right">Right</option>
              <option value="double">Double</option>
            </b-select>
          </b-field>
        </div>

        <div class="column is-one-quarter">
          <year-select v-model="record.year_of_manufacture" />
        </div>

        <div class="column is-one-quarter">
          <b-field label="Date Aquired">
            <date-time-picker v-model="record.acquired_at" :time="false" />
          </b-field>
        </div>

        <div class="column is-one-quarter">
          <b-field label="Cost">
            <b-input type="text" v-model="record.cost" /></b-input>
          </b-field>
        </div>

        <div class="column is-one-fourth">
          <b-field>
            <b-checkbox v-model="record.data.specialty_modification" class="inline-label">
              Specialty Modification
            </b-checkbox>
          </b-field>
        </div>

        <div class="column is-one-fourth">
          <b-field>
            <b-checkbox v-model="record.data.dvt" class="inline-label">
              DVT
            </b-checkbox>
          </b-field>
        </div>

        <div class="column is-one-fourth">
          <b-field>
            <b-checkbox v-model="record.has_lockbox" class="inline-label">
              Has Lockbox
            </b-checkbox>
          </b-field>
        </div>

        <div class="column is-full">
          <b-field label="Photos">
            <dropzone v-model="record.photos" />
          </b-field>
        </div>

        <div class="column is-full">
          <b-field label="Notes">
            <b-input type="textarea" v-model="record.notes"></b-input>
          </b-field>
        </div>

        <div class="column is-full">
          <p class="control">
            <slot name="buttons">
              <button class="button is-primary is-fullwidth">Save</button>
            </slot>
          </p>
        </div>

      </div>
    </form>
  </div>
</template>

<script>
  import { currency } from 'shared/filters'
  import UserHelper from 'shared/mixins/user-helper'
  import DepotSelect from '../../shared/components/depot-select'
  import ProductSelect from '../../shared/components/product-select'
  import UnitStatusSelect from '../../shared/components/unit-status-select'
  import UnitColorSelect from '../../shared/components/unit-color-select'
  import YearSelect from '../../shared/components/year-select'
  import FleetStatusSelect from '../../shared/components/fleet-status-select'
  import Dropzone from '../../shared/components/dropzone'
  export default {
    mixins: [ UserHelper ],
    components: {
      DepotSelect,
      ProductSelect,
      UnitStatusSelect,
      UnitColorSelect,
      YearSelect,
      FleetStatusSelect,
      Dropzone
    },
    props: {
      record: {
        type: Object,
        default () {
          return {
            data: {}
          }
        }
      },
      onSave: {
        type: Function
      }
    },
    data () {
      return {
        type: 'sales',
      }
    },
    created () {
      if (this.user_depot) {
        this.record.depot = this.user_depot
      }
      if (this.record.cost) {
        this.record.cost = currency(this.record.cost)
      }
      this.type = this.record.product ? this.record.product.classification : 'sales'
    },
    methods: {
      validateAndSave () {
        this.$validator.validateAll().then(result => {
          if (result) {
            return this.save()
          }
          this.$store.commit('error.validation', this.errors)
        })
      },
      save () {
        this.$store.dispatch('saveRecord', [ 'unit', this.record, { include: 'depot,product' } ]).then(record => {
          this.$buefy.toast.open({
            message: 'Unit Saved',
            type: 'is-success'
          })
          this.$parent.close()
          if (this.onSave) this.onSave(record)
        })
      }
    }
  }
</script>
