import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

Vue.use(Vuex)


let plugins = []

if (process.env.NODE_ENV !== 'test') {
  const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: state => {
      return {
        user: state.user,
        session: state.session,
        depot: state.depot
      }
    }
  })
  plugins.push(vuexLocal.plugin)
}

export default new Vuex.Store({
  plugins,
  actions,
  mutations,
  getters,
  state: {
    user: null,
    depot: null,
    depots: [],
    error: null,
    data: {}
  }
})
