<template>
  <section>

    <article v-if="!loading && records.length === 0" class="message is-warning">
      <div class="message-body">
        No Transfers
      </div>
    </article>

    <b-table v-else
      :data="records"
      :loading="loading"
      :current-page="meta.page"
      :total="meta.record_count"
      :per-page="50"
      :striped="true"
      :narrowed="true"
      paginated
      backend-pagination
      backend-sorting
      @sort="onSort"
      @page-change="onPageChange">


      <template slot-scope="props" slot="header">
        <b-tooltip v-if="props.column.meta" :active="!!props.column.meta" :label="props.column.meta" dashed>
          {{ props.column.label }}
        </b-tooltip>
        <template v-else>
          {{ props.column.label }}
        </template>
      </template>

      <template slot-scope="props">
        <b-table-column field="id" label="Reference" sortable>
          <h6 class="title is-6 is-whitespacenowrap">
            <router-link :to="{ name: 'transfer', params: { id: props.row.id }}" data-attribute="reference">{{ props.row.reference }}</router-link>
          </h6>
          <!-- <h6 v-if="props.row.notes" class="subtitle is-size-7 has-text-grey">{{ props.row.notes }}</h6> -->
        </b-table-column>

        <b-table-column field="status" label="Status" sortable>
          <transfer-status :record="props.row" />
        </b-table-column>

        <b-table-column field="product_code" label="Products" meta="Product Code">
          <p v-if="props.row.product" class="is-whitespacenowrap">{{ props.row.product.code }}</p>
        </b-table-column>

        <b-table-column field="unit_count" label="Qty" sortable>
          <span data-attribute="unit-count">{{ props.row.unit_count }}</span>
        </b-table-column>

        <b-table-column field="source_depot" label="Source">
          <span data-attribute="source-depot.name" class="tag">{{ props.row.source_depot.name }}</span>
        </b-table-column>


        <b-table-column field="release" label="Release">
          <router-link v-if="props.row.outgoing_order" :to="{ name: 'outgoing-order', params: { id: props.row.outgoing_order.id }}" data-attribute="outgoing-order.reference" class="is-whitespacenowrap">
            <h6>{{ props.row.outgoing_order.reference }}</h6>
          </router-link>
        </b-table-column>


        <b-table-column field="destination_depot" label="Destination">
          <span data-attribute="destination-depot.name" class="tag">{{ props.row.destination_depot.name }}</span>
        </b-table-column>

        <b-table-column field="incoming" label="Incoming">
          <router-link v-if="props.row.incoming_order" :to="{ name: 'incoming-order', params: { id: props.row.incoming_order.id }}" data-attribute="incoming-order.reference" class="is-whitespacenowrap">
            <h6>{{ props.row.incoming_order.reference }}</h6>
          </router-link>
        </b-table-column>

      </template>
    </b-table>

  </section>
</template>

<script>
  import asyncTable from '../../mixins/async-table'
  import TransferStatus from './status'
  import api from 'shared/json-api'
  export default {
    mixins: [ asyncTable ],
    components: {
      TransferStatus
    },
    methods: {
      applyRouteFilter () {
        let filter = {
          ...this.$route.meta.computedRouteFilter()
        }
        if (this.depot) filter.source_depot_id = this.depot.id
        return filter
      },
      loadAsyncData () {
        this.loading = true
        api.findAll('transfer', {
          sort: this.sortParam,
          include: 'created_by_user,product,source_depot,destination_depot,incoming_order,outgoing_order',
          page: {
            number: this.meta.page
          },
          filter: this.applyRouteFilter()
        }).then(response => {
          this.handleResponse(response)
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
</script>
