<template>
  <section>

    <span v-if="!loading && records.length === 0">
      <article class="message is-warning">
        <div class="message-body">
          No Records
        </div>
      </article>
    </span>

    <b-table v-else
      :data="records"
      ref="table"
      :loading="loading"
      :current-page="meta.page"
      :total="meta.record_count"
      :per-page="50"
      :striped="true"
      :narrowed="true"
      :showDetailIcon="false"
      detailed
      detail-key="id"
      paginated
      backend-pagination
      backend-sorting
      @sort="onSort"
      @page-change="onPageChange">

      <template slot-scope="props" slot="header">
        <b-tooltip v-if="props.column.meta" :active="!!props.column.meta" :label="props.column.meta" dashed>
          {{ props.column.label }}
        </b-tooltip>
        <template v-else>
          {{ props.column.label }}
        </template>
      </template>

      <template slot-scope="props">

        <b-table-column field="number" label="Serial Number" sortable>

          <div class="media">
            <div class="media-left">
              <a v-if="props.row.photos.length" @click="toggle(props.row)" class="image is-32x32">
                <img :src="props.row.photos[0] | small" :alt="props.row.number" />
              </a>
              <figure v-else class="image is-32x32">
                <img src="https://bulma.io/images/placeholders/128x128.png" />
              </figure>
            </div>
            <div class="media-content">
              <span class="is-marginless is-whitespacenowrap">
                <router-link :to="{ name: 'unit', params: { id: props.row.id }}" data-attribute="number">{{ props.row.number }}</router-link>
              </span>
            </div>
          </div>

        </b-table-column>

        <b-table-column field="status" label="Status" sortable>
          <unit-status :record="props.row" />
        </b-table-column>

        <b-table-column field="depot_id" label="Depot">
          <span v-if="props.row.depot" class="tag" data-attribute="depot.name">{{ props.row.depot.name }}</span>
          <span v-else>N/A</span>
        </b-table-column>

        <b-table-column field="fleet_status" label="Fleet Status" sortable>
          <span class="tag" :class="props.row.fleet_status" data-attribute="fleet-status">{{ props.row.fleet_status | titleize }}</span>
        </b-table-column>

        <b-table-column field="product.code" label="Product">
          <p data-attribute="product-code">{{ props.row.product.code }}</p>
        </b-table-column>

        <b-table-column field="color" label="Color" sortable>
          <div v-if="props.row.color" class="color-swatch-wrapper">
            <span class="v-autocomplete-preview-status color-swatch" :class="props.row.color | colorClassName" :title="props.row.color"></span>
            <!-- <span class="color" data-attribute="color">{{ props.row.color }}</span> -->
          </div>
        </b-table-column>

        <b-table-column field="arrived_at" label="Gate In Date" width="116">
          <p>
            <span v-if="props.row.arrived_at">
              {{ props.row.arrived_at | simpleDateTime }}
            </span>
            <span v-else>
              N/A
            </span>
          </p>
        </b-table-column>

        <b-table-column field="confirmed_at" label="Confirmed" width="116" sortable>
          <p>
            <span v-if="props.row.confirmed_at">
              {{ props.row.confirmed_at | simpleDateTime }}
            </span>
            <span v-else>
              N/A
            </span>
          </p>
        </b-table-column>

      </template>

      <template slot="detail" slot-scope="props">
        <thumbnail-gallery :photos="props.row.photos" />

        <!-- TODO Opportunity to Add more details here -->
        <!-- <div class="content">
          <h6 v-if="props.row.order_units.length === 0" class="title is-6">Unassigned</h6>
          <router-link v-else :to="{ name: `${props.row.order_units[0].order.order_type}-order`, params: { id: props.row.order_units[0].order.id }}">
            <h6 class="title is-6">
              {{ props.row.order_units[0].order.order_type | capitalize }}
              {{ props.row.order_units[0].order.reference }}
            </h6>
          </router-link>
        </div> -->

      </template>

    </b-table>

  </section>
</template>

<script>
  import asyncTable from '../../mixins/async-table'
  import api from 'shared/json-api'
  import UnitStatus from './status'
  import ThumbnailGallery from './thumbnail-gallery'
  const FITLER_KEYS = [ 'depot_id', 'query', 'product_id', 'status', 'fleet_status', 'color' ]
  const photoSizeUrl = (url, size) => {
    return url.replace('/uploads/', `/uploads/resized/${size}/`).replace('.jpeg', '.jpg')
  }
  export default {
    mixins: [ asyncTable ],
    filters: {
      small (url) {
        return photoSizeUrl(url, 'small')
      }
    },
    components: {
      UnitStatus,
      ThumbnailGallery
    },
    props: {
      isRental: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      toggle(row) {
        this.$refs.table.toggleDetails(row)
      },
      loadAsyncData () {
        this.loading = true
        api.findAll('unit', {
          sort: this.sortParam,
          include: 'depot,product,order_units.order',
          page: {
            number: this.meta.page
          },
          filter: this.applyRouteFilter()
        }).then(response => {
          this.handleResponse(response)
        })
      }
    }
  }
</script>
