<template>
  <div v-if="record" class="order-detail">

    <section class="section">

      <div v-if="record.deleted_at" class="notification is-danger">
        This dispatch has been deleted.
      </div>

      <!-- ACTIONS -->
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <router-link to="/dispatches" class="navbar-item">
              <span class="mdi mdi-chevron-left"></span>&nbsp; All Dispatches
            </router-link>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="field has-addons">
              <div class="control">
                <button class="button" @click="editRecord()">
                  <span class="icon icon-medium"><i class="mdi mdi-file-document-edit-outline mdi-18px"></i></span>
                  <span>Edit</span>
                </button>
              </div>
              <div class="control">
                <button class="button" @click="addMovement()">
                  <span class="icon icon-medium"><i class="mdi mdi-note-plus-outline mdi-18px"></i></span>
                  <span>Add Movement</span>
                </button>
              </div>
              <div class="control">
                <button class="button" @click="deleteRecord">
                  <span class="icon icon-medium"><i class="mdi mdi-delete-forever-outline mdi-18px"></i></span>
                  <span>Delete</span>
                </button>
              </div>

              <!-- <div v-if="is_admin && !record.transfer" class="control">
                <button v-if="record.deleted_at" class="button" @click="recoverRecord">
                  <span class="icon icon-medium"><i class="mdi mdi-delete-sweep-outline mdi-18px"></i></span>
                  <span>Recover</span>
                </button>
                <button v-else class="button" @click="deleteRecord">
                  <span class="icon icon-medium"><i class="mdi mdi-delete-forever-outline mdi-18px"></i></span>
                  <span>Delete</span>
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="box is-relative">
        <status-select :record="record" record_type="delivery" />
        <div class="columns">

          <div class="column is-narrow">
            <p class="heading">Order Number</p>
            <h4 class="title is-4">{{ record.reference }}</h4>
          </div>

          <div v-if="record.data.release_number" class="column is-narrow">
            <p class="heading">Release Number</p>
            <h5 class="title is-5">{{ record.data.release_number }}</h5>
          </div>

          <div class="column is-narrow">
            <p class="heading">Company Name</p>
            <h5 class="title is-5">{{ record.customer.company_name }}</h5>
          </div>

          <!-- <div class="column is-narrow" v-if="record.driver">
            <p class="heading">Driver</p>
            <h5 class="title is-5" data-attribute="driver">
              {{ record.driver.first_name }}
              {{ record.driver.last_name }}
            </h5>
          </div> -->

        </div>

        <div class="b-table">
          <div class="table-wrapper">
            <table class="table is-striped is-narrow has-mobile-cards is-inbox">
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Customer Name:
                </th>
                <td data-label="Customer Name:">
                  <span data-attribute="customer.first-name">{{ record.customer.first_name }}</span>
                  <span data-attribute="customer.last-name">{{ record.customer.last_name }}</span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Email:
                </th>
                <td data-label="Created:">
                  <span data-attribute="customer.email">
                    <a :href="`mailto:${record.customer.email}`">{{ record.customer.email }}</a>
                  </span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Phone:
                </th>
                <td data-label="Phone:">
                  <span data-attribute="customer.phone">{{ record.customer.phone | phoneNumber }}</span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Created Date:
                </th>
                <td data-label="Created:">
                  <span v-if="record.created_at" data-attribute="created-at">{{ record.created_at | formattedDate }}</span>
                  <span v-else>N/A</span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Expected Date:
                </th>
                <td data-label="Due:">
                  <span v-if="record.expected_at" data-attribute="expected-at">{{ record.expected_at | formattedDate }}</span>
                  <span v-else>N/A</span>
                </td>
              </tr>
              <tr>
                <th class="is-hidden-mobile" style="width: 240px;">
                  Completion Date:
                </th>
                <td data-label="Completion:">
                  <span v-if="record.completed_at" data-attribute="completed-at">{{ record.completed_at | formattedDate }}</span>
                  <span v-else>N/A</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="box">
        <h6 class="title is-6">Notes</h6>
        <div class="pre-wrapper">
          <pre data-attribute="notes">{{ record.notes }}</pre>
        </div>
      </div>

      <div v-if="movements" class="box">
        <movement-table
          :records="movements"
          @edit="editMovement"
          @delete="deleteMovement" />
      </div>

    </section>

    <!-- <section class="section">
      <div class="box">
        <h5 class="title is-5">Order History</h5>
        <template v-if="record.traces.length > 0">
          <traces :traces="record.traces" :record="record" />
        </template>
        <template v-else>
          <article class="message is-warning">
            <div class="message-body">
              No History
            </div>
          </article>
        </template>
      </div>
    </section> -->

  </div>

  <loader v-else />

</template>

<script>
  import { mapState } from 'vuex'
  import api from 'shared/json-api'
  import filters from 'shared/filters'

  import UserHelper from 'shared/mixins/user-helper'
  import AsyncRecord from 'shared/mixins/async-record'

  import Loader from 'shared/components/loader'
  import StatusSelect from './status'
  import DeliveryForm from './form'

  import MovementForm from './movement-form'
  import MovementTable from './movement-table'
  // import Traces from 'shared/components/traces'

  export default {
    filters,
    mixins: [
      UserHelper,
      AsyncRecord
    ],
    components: {
      Loader,
      StatusSelect,
      MovementTable
    },
    data () {
      return {
        movements: null,
        asyncRecordOptions: {
          type: 'delivery',
          options: {
            include: 'customer,order'
          }
        }
      }
    },
    methods: {
      async fetchMovements () {
        const response = await api.findAll('movement', {
          filter: {
            delivery_id: this.record.id
          },
          sort: 'scheduled_at,created_at',
          include: 'driver,unit,source,destination'
        })
        this.movements = response.data
      },
      onRecordLoaded () {
        if (!this.movements) {
          this.fetchMovements()
        }
      },
      addMovement () {
        this.$buefy.modal.open({
          parent: this,
          component: MovementForm,
          props: {
            movement: {
              movement_type: 'sale_delivery',
              status: 'hold',
              delivery: this.record
            },
            onSave: () => {
              this.fetchMovements()
            }
          }
        })
      },
      editMovement (record) {
        this.$buefy.modal.open({
          parent: this,
          component: MovementForm,
          props: {
            movement: {
              ...record,
              delivery: this.record
            },
            onSave: record => {
              this.fetchMovements()
            }
          }
        })
      },
      deleteMovement (record) {
        this.$buefy.dialog.confirm({
          title: 'Remove Movement',
          message: 'Are you sure you want to <b>remove</b> this movement?',
          confirmText: 'Remove Movement',
          type: 'is-danger',
          onConfirm: () => {
            this.$store.dispatch('deleteRecord', [ 'movement', record ]).then(() => {
              this.fetchMovements()
            })
          }
        })
      },
      editRecord () {
        this.$buefy.modal.open({
          parent: this,
          component: DeliveryForm,
          props: {
            record: { ...this.record },
            onSave: record => {
              this.fetchRecord()
            }
          }
        })
      },
      deleteRecord () {
        this.$buefy.dialog.confirm({
          title: 'Deleting Dispatch',
          message: 'Are you sure you want to <b>delete</b> this dispatch?',
          confirmText: 'Delete Dispatch',
          type: 'is-danger',
          onConfirm: () => {
            this.$store.dispatch('deleteRecord', [ 'delivery', this.record ]).then(() => {
              this.$router.push('/dispatches')
            })
          }
        })
      }
    }
  }
</script>
